import React, {useState} from 'react';
import {Button} from "@mui/material";
import ModalComponent from "../../Common/Modal/ModalComponent";
import DeposerDocumentClient from "../Documents/DeposerDocumentClient";
import UploadFileIcon from '@mui/icons-material/UploadFile';

const ModalDeposerDocumentClient = ({clientId, useIcon, variant, callback}) => {
    const [openModal, setOpenModal] = useState(false);

    const btnVariant = variant ? variant : "contained";

    const callbackModal = () => {
        setOpenModal(false);
        if (typeof callback === 'function') {
            callback();
        }
    }

    return (
        <>
            <Button variant={btnVariant}
                    color="secondary"
                    disabled={!clientId}
                    onClick={() => setOpenModal(true)}>
                {useIcon && <UploadFileIcon sx={{marginRight: "5px"}}/>}Déposer un document
            </Button>

            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}>

                <DeposerDocumentClient clientId={clientId}
                                       callback={callbackModal}/>

            </ModalComponent>
        </>
    );
};

export default ModalDeposerDocumentClient;

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { NavLink } from "react-router-dom";

const ProgrammeCard = ({ programme }) => {
	return (
		<Card>
			<NavLink to={`/clients/programmes/${programme.id}`}>
				<CardMedia component="img" height="200" image={programme.image} />
			</NavLink>
			<CardContent>
				<Typography variant="h4">{programme.nom}</Typography>
				<Typography>{programme.ville}</Typography>
				<Box sx={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
					<NavLink to={`/clients/programmes/${programme.id}`}>
						<Button variant="contained">Accéder</Button>
					</NavLink>
				</Box>
			</CardContent>
		</Card>
  	);
};

export default ProgrammeCard;

import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import { useAuth } from "../../../Contexts/useAuth";

const Maquette = ({ maquetteUrl, programmeId }) => {
  const { state: stateAuth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(maquetteUrl || "");
  const [maquette, setMaquette] = useState(maquetteUrl || "");

  const handleSubmit = async () => {
    setIsLoading(true);
    const dataProgramme = await fetchApiImmodesk(
      `/private/programmes/${programmeId}`,
      {
        method: "PUT",
        body: JSON.stringify({ "maquette-iframe": maquette }),
      },
      stateAuth.token
    );
    setIsLoading(false);

    if (dataProgramme.error) {
      // TODO error
      return;
    }

	setIframeSrc(maquette);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            width: ["100%", "150px"],
          }}
        >
          <Typography>Url maquette 3D</Typography>
        </Grid>
        <Grid item sx={{ flex: "1" }}>
          <TextField
            id="maquette-iframe"
            label="Renseignez l'url de votre maquette 3D"
            type="text"
            variant="outlined"
            size="small"
            value={maquette}
            fullWidth
            onChange={(e) => setMaquette(e.target.value)}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: ["100%", "150px"],
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading && (
              <CircularProgress
                size="20px"
                color="secondary"
                sx={{ marginRight: "10px" }}
              />
            )}
            <Box component="span">Enregistrer</Box>
          </Button>
        </Grid>
      </Grid>

      {iframeSrc && (
        <iframe
          title="maquette 3D"
          src={iframeSrc}
          frameBorder={0}
          style={{
            width: "100%",
            aspectRatio: "16/9",
            marginTop: "50px",
            border: "1px solid #E1E1E1",
          }}
        />
      )}
    </>
  );
};

export default Maquette;

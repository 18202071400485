import React, {useEffect, useState} from 'react';
import {useAuth} from "../../../Contexts/useAuth";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import Loading from "../../Common/Loading";
import {Box, Button, TextField, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import {Alert} from "@mui/lab";

const FormLightClient = ({clientId, callback}) => {
    const {state: stateAuth} = useAuth();

    const [client, setClient] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const {handleSubmit, register, formState: {errors}, reset} = useForm({});

    useEffect(() => {
        if (!clientId) {
            return;
        }

        const fetchData = async () => {
            setIsLoading(true);
            const dataClient = await fetchApiImmodesk(`/private/contacts/${clientId}`, {}, stateAuth.token);
            setIsLoading(false);
            if (dataClient.error) {
                return;
            }
            setClient(dataClient);
            reset();
        }

        fetchData();
    }, [clientId, reset, stateAuth])

    const onSubmit = async () => {
        setIsLoadingSubmit(true);

        const dataClient = await fetchApiImmodesk(`/private/contacts/${client.id}`, {
            method: 'PUT',
            body: JSON.stringify(client)
        }, stateAuth.token);

        setIsLoadingSubmit(false);

        if(dataClient.error) {
            // TODO error
            return;
        }

        if(typeof callback === 'function') {
            callback('update-client', dataClient);
        }
    }

    return (
        <>
            {isLoading && <Loading/>}
            {!isLoading &&
                <>
                    <Typography variant="h6"
                                component="h2"
                                sx={{marginBottom: "20px"}}>Fiche client</Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{display: "grid", gap: "15px", gridTemplateColumns: "1fr 1fr", marginBottom: "15px"}}>

                            <TextField
                                id="client-nom"
                                label="Nom"
                                name="nom"
                                required
                                value={client?.nom || ""}
                                {...register("client.nom", {
                                    required: "Le nom est obligatoire.",
                                })}
                                InputLabelProps={{
                                    shrink: client?.nom ? true : false
                                }}
                                onChange={(event) => setClient({...client, nom: event.target.value})}
                            />

                            <TextField
                                id="client-prenom"
                                label="Prénom"
                                name="prenom"
                                required
                                value={client?.prenom || ""}
                                {...register("client.prenom", {
                                    required: "Le prénom est obligatoire.",
                                })}
                                InputLabelProps={{
                                    shrink: client?.prenom ? true : false
                                }}
                                onChange={(event) => setClient({...client, prenom: event.target.value})}
                            />

                            <TextField
                                id="client-tel"
                                label="Téléphone"
                                name="tel"
                                value={client?.tel || ""}
                                InputLabelProps={{
                                    shrink: client?.tel ? true : false
                                }}
                                onChange={(event) => setClient({...client, tel: event.target.value})}
                            />

                            <TextField
                                id="client-email"
                                label="Email"
                                name="email"
                                required
                                value={client?.email || ""}
                                {...register("client.email", {
                                    required: "L'email est obligatoire.",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Entrer une adresse email valide."
                                    }
                                })}
                                InputLabelProps={{
                                    shrink: client?.email ? true : false
                                }}
                                onChange={(event) => setClient({...client, email: event.target.value})}
                            />
                        </Box>

                        <TextField
                            id="client-complement-situation-note"
                            fullWidth
                            multiline
                            rows={5}
                            label="Note"
                            name="complement_situation_note"
                            value={client?.complement_situation_note || ""}
                            InputLabelProps={{
                                shrink: client?.complement_situation_note ? true : false
                            }}
                            onChange={(event) => setClient({...client, complement_situation_note: event.target.value})}
                        />

                        <Typography variant="caption">* champ obligatoire</Typography>

                        {errors && Object.keys(errors).length > 0 &&
                            <Box sx={{display: "grid", gap: "5px", gridTemplateColumns: "1fr", marginTop: "10px"}}>
                                {errors?.client?.nom && <Alert severity="warning">{errors.client.nom.message}</Alert>}
                                {errors?.client?.prenom && <Alert severity="warning">{errors.client.prenom.message}</Alert>}
                                {errors?.client?.email && <Alert severity="warning">{errors.client.email.message}</Alert>}
                            </Box>
                        }

                        <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                            <Button variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isLoadingSubmit}>
                                {isLoadingSubmit &&
                                    <CircularProgress size="20px"
                                                      color="secondary"
                                                      sx={{marginRight: '10px'}}/>}
                                <Box component="span">Valider</Box>
                            </Button>
                        </Box>

                    </form>
                </>
            }
        </>
    );
};

export default FormLightClient;

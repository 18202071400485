import React, {useCallback, useEffect, useState} from 'react';
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {Pagination} from "@mui/lab";
import ModalDeposerDocumentProgramme from "../Modal/ModalDeposerDocumentProgramme";
import ActionsDocumentsProgramme from './ActionsDocumentsProgramme';

const DocumentsProgramme = ({programme}) => {
    const {state: stateAuth} = useAuth();

    const limit = 10;

    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        const dataDocuments = await fetchApiImmodesk(`/private/documents?programme_id=${programme.id}&limit=${limit}&page=${page}`, {}, stateAuth.token);

        setIsLoading(false);

        if (dataDocuments.error) {
            // TODO error
            return;
        }

        const totalPages = (dataDocuments.total / limit) < 1 ? 1 : Math.ceil(dataDocuments.total / limit);
        setDocuments({...dataDocuments, totalPages});
    }, [programme, limit, page, stateAuth]);

    useEffect(() => {
        if(!programme) {
            return;
        }
        fetchData();
        // eslint-disable-next-line
    }, [programme, page])

    const handleChangePage = (event, p) => {
        setPage(p);
    }

    const handleDocumentCallback = ({type, document}) => {
      if(type === "delete") {
        deleteDocument(document);
        return;
      }
      if(type === "update") {
        fetchData();
      }
    }

    const deleteDocument = (document) => {
      const newDocuments = {...documents};
      const index = newDocuments.data.findIndex(d => d.id === document.id);

      if(index === -1) {
        return;
      }

      newDocuments.data.splice(index, 1);
      setDocuments(newDocuments);
    }

    return (
        <>
            <Box sx={{marginTop: "10px", marginBottom: "40px"}}>
                <ModalDeposerDocumentProgramme programmeId={programme.id}
                                               callback={fetchData} />
            </Box>
            <TableContainer>
                <Table aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Titre</TableCell>
                            <TableCell>Catégorie</TableCell>
                            <TableCell sx={{width: "64px"}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            <TableRow>
                                <TableCell colSpan={3} sx={{paddingBlock: "40px"}}><Loading/></TableCell>
                            </TableRow>
                        }
                        {!isLoading && documents && documents.data && documents.data.length === 0 &&
                            <TableRow hover>
                                <TableCell colSpan={3} sx={{paddingBlock: "20px"}}>Aucun document</TableCell>
                            </TableRow>
                        }
                        {!isLoading && documents && documents.data && documents.data.length > 0 && documents.data.map(document => (
                            <TableRow hover key={document.id}>
                                <TableCell>
                                    {document.titre ? document.titre : document.nom}
                                </TableCell>
                                <TableCell>
                                    {document.categorie_label}
                                </TableCell>
                                <TableCell>
                                  <ActionsDocumentsProgramme document={document}
                                                             callback={handleDocumentCallback} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{marginTop: "40px", textAlign: "center"}}>
                <Pagination count={documents.totalPages}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            disabled={isLoading}
                            onChange={handleChangePage}
                            sx={{display: "inline-flex"}}/>
            </Box>
        </>
    );
};

export default DocumentsProgramme;

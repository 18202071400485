import React, { useCallback, useState } from "react";
import InputIcon from "@mui/icons-material/Input";
import { useAuth } from "../../../Contexts/useAuth";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import Loading from "../../Common/Loading";
import { Box, Button } from "@mui/material";

const IconEspaceClient = ({ clientId, activation }) => {
  const { state: stateAuth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const getToken = useCallback(async () => {
    setIsLoading(true);

    const response = await fetchApiImmodesk(
      `/private/contacts/${clientId}/token`,
      {},
      stateAuth.token
    );

    setIsLoading(false);

    if (!response) {
      // TODO error
      return;
    }

    return response.token;
  }, [stateAuth, clientId]);

  const hideButton =
    !activation || activation === "INACTIF" || activation === "DESACTIVER";

  const handleEspaceClient = async () => {
    const urlEspaceClient =
      stateAuth.configsCommercialisateur &&
      stateAuth.configsCommercialisateur.use_userspace &&
      stateAuth.configsCommercialisateur.userspace_url;

    if (!urlEspaceClient) {
      console.log("Aucune url pour l'espace client");
      return;
    }

    const token = await getToken();

    if (!token) {
      console.log(
        "Erreur lors de la récupération du token pour le client ",
        clientId
      );
      return;
    }

    window.open(`${urlEspaceClient}?token=${token}`, "_blank");
  };

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "25px",
            width: "50px",
          }}
        >
          <Loading sizeCircularProgress="20px" />
        </Box>
      )}
      {!isLoading && !hideButton && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "25px",
            width: "50px",
          }}
        >
          <Button
            variant="text"
            color="success"
            size="small"
            onClick={handleEspaceClient}
            sx={{ padding: 0, minWidth: "auto" }}
          >
            <InputIcon />
          </Button>
        </Box>
      )}
    </>
  );
};

export default IconEspaceClient;

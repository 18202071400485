import React, {useState} from 'react';
import {Box, Button, Chip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchApiConfigurateur} from "../../../Api/Api_configurateur";
import {useAuth} from "../../../Contexts/useAuth";

const FormUploadTma = ({configurationId, callback}) => {
    const {state: stateAuth} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState();

    const handleChange = (event) => {
        if(!event || !event.target.files || !event.target.files[0]) {
            return;
        };

        setSelectedFile(event.target.files[0]);
    }

    const handleSubmit = async(event) => {
        event.preventDefault();

        if(!selectedFile) {
            return;
        }

        const formData = new FormData();

        formData.append("file", selectedFile, "fileName");

        setIsLoading(true);
        const dataDocument = await fetchApiConfigurateur(`/private/documents/tma/configurations/${configurationId}/promoteur`,
            {
                method: 'POST',
                type: 'file',
                body: formData
            }, stateAuth.token)
        setIsLoading(false);

        if(!dataDocument) {
            // TODO error
            return;
        }

        if(typeof callback === 'function') {
            callback();
        }
     }

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{textAlign: "center"}}>
                <label htmlFor="contained-button-file">
                    <input id="contained-button-file"
                           type="file"
                           accept=".pdf"
                           onChange={handleChange}
                           style={{display: "none"}}/>
                    <Button variant="contained"
                            color="secondary"
                            disabled={isLoading}
                            component="span">
                        Choisir un fichier
                    </Button>
                </label>
            </Box>

            {selectedFile &&
                <>
                <Box sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "5px",
                    width: "100%",
                    marginTop: "30px"
                }}>
                    <Chip label={`${selectedFile.name}`}
                        onDelete={() => setSelectedFile(null)}
                        sx={{width: "140px", justifyContent: "space-between"}}/>
                </Box>
                </>
            }

            <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                <Button variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading || !selectedFile}>
                    {isLoading &&
                        <CircularProgress size="20px"
                                          color="secondary"
                                          sx={{marginRight: '10px'}}/>}
                    <Box component="span">Valider</Box>
                </Button>
            </Box>

        </form>
    );
};

export default FormUploadTma;

import React, {useCallback, useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";
import TableActualites from "./TableActualites";
import ModalAjouterActualiteProgramme from "../Modal/ModalAjouterActualiteProgramme";

const ActualitesProgramme = ({programme, categories}) => {
    const {state: stateAuth} = useAuth();

    const [actualites, setActualites] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        const dataActualites = await fetchApiImmodesk(`/private/programmes/${programme.id}/actualites?categories=${categories}`, {}, stateAuth.token);
        setActualites(sortActualitesByDate(dataActualites));
        setIsLoading(false);
    }, [programme, stateAuth, categories])

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    const callback = (type, actualite) => {
        switch (type) {
            case 'delete':
                deleteActualite(actualite);
                break;
            case 'update':
                updateActualite(actualite);
                break;
            default:
                return;
        }
    }

    const deleteActualite = (actualite) => {
        const newActualites = [...actualites];
        const indexActualite = newActualites.findIndex(a => a.id === actualite.id);

        if(indexActualite === -1) {
            return;
        }

        newActualites.splice(indexActualite, 1);
        setActualites(sortActualitesByDate(newActualites));
    }

    const updateActualite = (actualite) => {
        const newActualites = [...actualites];
        const indexActualite = newActualites.findIndex(a => a.id === actualite.id);

        if(indexActualite === -1) {
            return;
        }

        newActualites[indexActualite] = actualite;
        setActualites(sortActualitesByDate(newActualites));
    }

    const addActualite = (actualite) => {
        setActualites(sortActualitesByDate([...actualites, actualite]));
    }

    const sortActualitesByDate = (dataActualites) => {
        const actualitesWithDate = dataActualites.filter(a => a.date && a.date.date);
        const actualitesWithoutDate = dataActualites.filter(a => !a.date);

        const actualitesWithDateSort = actualitesWithDate.sort(function(a,b) {
            return new Date(b.date.date) - new Date(a.date.date);
        });
        
        return actualitesWithoutDate.concat(actualitesWithDateSort);
    }

    return (
        <Box>
            <>
                <Box sx={{marginTop: "10px", marginBottom: "40px"}}>
                    <ModalAjouterActualiteProgramme programme={programme}
                                                    callback={addActualite}/>
                </Box>
            </>

            {isLoading && <Loading/>}
            {!isLoading && <TableActualites actualites={actualites}
                                            programme={programme}
                                            callback={callback}/>}
        </Box>
    );
};

export default ActualitesProgramme;

import React, { useEffect, useState } from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { ShowDate } from "../../Common/showDate";

const TimelineProgramme = ({ steps }) => {
  const [activeStep, setActiveStep] = useState(undefined);

  useEffect(() => {
    steps.forEach((step, index) => {
      const dateNow = new Date();
      if (step.date && step.date.date && new Date(step.date.date) <= dateNow) {
        setActiveStep(index);
      }
    });
  }, [steps]);

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ShowDate date={step.date.date} /> <Box>{step.titre}</Box>
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default TimelineProgramme;

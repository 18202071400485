import React from 'react';
import {Box} from "@mui/material";

const EmbedDocumentPdf = ({url}) => {

    const sx = {
        boxDocument: {
            overflow: 'auto',
            embed: {
                width: '100%',
                height: '600px',
            },
        }
    };

    return (
        <Box sx={sx.boxDocument}>
            <embed src={url} type="application/pdf"/>
        </Box>
    );
};

export default EmbedDocumentPdf;

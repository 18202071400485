import { createContext, useContext, useEffect, useReducer } from "react";
import { tokenIsValid } from "../Utils/token";

const nameStorage = "config-manager-token";

const AuthContext = createContext();

function authReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "initAuth": {
      const urlToken = new URLSearchParams(window.location.search).get("token");
      if (urlToken) {
        localStorage.setItem("config-manager-token", urlToken);
      }
      const token = localStorage.getItem("config-manager-token");

      // Check validity
      const isValidToken = tokenIsValid(token);

      if (!isValidToken) {
        localStorage.removeItem(nameStorage);
        return {
          init: true,
          isAuth: false,
          profil: null,
          token: null,
          configsCommercialisateur: null,
          configAwsIot: null,
        };
      }

      return {
        init: true,
        isAuth: true,
        profil: null,
        token: token,
        configsCommercialisateur: null,
      };
    }
    case "setProfil":
      return { ...state, profil: payload.profil };
    case "setConfigsCommercialisateur":
      return {
        ...state,
        configsCommercialisateur: payload.configsCommercialisateur,
      };
    case "setToken":
      localStorage.setItem(nameStorage, payload.token);
      return { ...state, token: payload.token };
    case "setIsAuth":
      return { ...state, isAuth: payload.isAuth };
    case "setConfigAwsIot":
      return { ...state, configAwsIot: payload };
    case "logout":
      localStorage.removeItem(nameStorage);
      return {
        init: true,
        isAuth: false,
        profil: null,
        token: null,
        configsCommercialisateur: null,
        configAwsIot: null,
      };
    default:
      return state;
  }
}

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, {
    init: false,
    isAuth: false,
    profil: null,
    token: null,
    configsCommercialisateur: null,
    configAwsIot: null,
  });
  useEffect(() => {
    dispatch({ type: "initAuth" });
  }, []);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

function AuthConsumer({ children }) {
  return (
    <AuthContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error("AuthConsumer must be used within a AuthProvider");
        }
        return children(context);
      }}
    </AuthContext.Consumer>
  );
}

export { useAuth, AuthProvider, AuthConsumer };

import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../../Contexts/useAuth";
import AjouterFichier from "../Files/AjouterFichier";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import CustomMuiSelect from "../../CustomMui/CustomMuiSelect";

const DeposerDocumentClientCategories = ({
  categories,
  clientId,
  callback,
}) => {
  const { state: stateAuth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [categorieSelected, setCategorieSelected] = useState({});
  const [files, setFiles] = useState([]);
  const [titre, setTitre] = useState("");
  const [message, setMessage] = useState("");

  const handleCategories = event => {
    const categorie = categories.find(c => c.id === event.target.value);

    if (!categorie) {
      return;
    }

    setCategorieSelected(categorie);
  };

  const submit = async () => {
    setIsLoading(true);
    await Promise.all(
      files.map(async file => {
        const formData = new FormData();

        formData.append("contact_id", clientId);
        formData.append("categorie_id", categorieSelected.id);
        formData.append("file", file, file.name);
        formData.append("titre", titre);
        formData.append("message", message);

        await fetchApiImmodesk(
          `/private/documents/deposer-document`,
          {
            method: "POST",
            type: "file",
            body: formData,
          },
          stateAuth.token
        );
      })
    );

    setIsLoading(false);

    if (typeof callback === "function") {
      callback();
    }
  };

  return (
    <>
      <TextField
        label="Titre"
        fullWidth
        value={titre}
        onChange={e => setTitre(e.target.value)}
        variant="outlined"
        sx={{ marginBottom: "20px" }}
      />

      <FormControl fullWidth>
        <InputLabel id="select-categorie-label">
          Sélectionner une catégorie
        </InputLabel>
        <CustomMuiSelect
          labelId="select-categorie-label"
          id="select-categorie"
          value={categorieSelected?.id}
          label="Sélectionner une catégorie"
          onChange={handleCategories}
        >
          {categories &&
            categories.map((categorie, index) => (
              <MenuItem value={categorie.id} key={index}>
                {categorie.label}
              </MenuItem>
            ))}
        </CustomMuiSelect>
      </FormControl>

      <TextField
        placeholder="Message"
        multiline
        rows={4}
        maxRows={4}
        onChange={e => setMessage(e.target.value)}
        sx={{ width: "100%", marginTop: "20px" }}
      />

      <Box sx={{ marginTop: "40px", textAlign: "center" }}>
        <AjouterFichier files={files} setFiles={setFiles} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          disabled={
            isLoading || !categorieSelected.id || files.length === 0 || !titre
          }
        >
          {isLoading && (
            <CircularProgress
              size="20px"
              color="secondary"
              sx={{ marginRight: "10px" }}
            />
          )}
          <Box component="span">Envoyer</Box>
        </Button>
      </Box>
    </>
  );
};

export default DeposerDocumentClientCategories;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTheme } from "@emotion/react";
import Loading from "../../Common/Loading.js";
import { ShowDateWithHours } from "../../Common/showDate";
import ActionsRendezVous from "./ActionsRendezVous.js";

const RendezVousItems = ({ isLoading, label, items, callback }) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{ marginBottom: "16px", color: theme.palette.grey["700"] }}
      >
        {label}
      </Typography>

      <TableContainer>
        <Table aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "175px" }}>Date</TableCell>
              <TableCell sx={{ width: "230px" }}>Type</TableCell>
              <TableCell sx={{ width: "230px" }}>Réalisé</TableCell>
              <TableCell>Note</TableCell>
              <TableCell sx={{ width: "80px" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          {items.length > 0 && (
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={5} sx={{ paddingBlock: "40px" }}>
                    <Loading />
                  </TableCell>
                </TableRow>
              )}

              {!isLoading && items && items.length === 0 && (
                <TableRow hover>
                  <TableCell colSpan={5} sx={{ paddingBlock: "20px" }}>
                    Aucun rendez-vous
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                items &&
                items.length > 0 &&
                items.map((item) => (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <ShowDateWithHours date={item.date_echeance.date} />
                    </TableCell>
                    <TableCell>{item.action_label}</TableCell>
                    <TableCell>{item.done ? "Oui" : "Non"}</TableCell>
                    <TableCell
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                      }}
                    >
                      {item.texte}
                    </TableCell>
                    <TableCell>
                      <ActionsRendezVous item={item} callback={callback} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {items.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5} sx={{ padding: "24px" }}>
                  Aucun rendez-vous
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RendezVousItems;

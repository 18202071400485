import React, {useState} from 'react';
import {Box, Button} from "@mui/material";
import AjouterFichier from "../Files/AjouterFichier";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";

const DeposerDocumentClientDemande = ({document, clientId, callback}) => {
    const {state: stateAuth} = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);

    const submit = async(event) => {
        event.preventDefault();

        setIsLoading(true);

        await Promise.all(files.map(async (file) => {
            const formData = new FormData();

            formData.append('contact_id', clientId);
            formData.append('demande_id', document.id);
            formData.append('file', file, file.name);

            await fetchApiImmodesk(`/private/documents`, {
                method: 'POST',
                type: 'file',
                body: formData
            }, stateAuth.token);
        }));

        setIsLoading(false);

        if(typeof callback === 'function') {
            callback();
        }
    }

    return (
        <form onSubmit={submit}>
            <Box sx={{textAlign: "center"}}>
                <AjouterFichier files={files} setFiles={setFiles} />
            </Box>

            <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                <Button variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading || files.length === 0 || !document}>
                    {isLoading && <CircularProgress size="20px" color="secondary" sx={{marginRight: '10px'}}/>}
                    <Box component="span">Envoyer</Box>
                </Button>
            </Box>
        </form>
    );
};

export default DeposerDocumentClientDemande;

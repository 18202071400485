export const fetchApiImmodesk = async (url, init = {}, token = false) => {

    let headers = new Headers();

    if (token) {
        headers = {Authorization:`Bearer ${token}`};
    }

    if(init.body && init.type !== 'file') {
        headers = {...headers, 'Content-Length': init.body.length, 'Content-Type': 'application/json'};
    }

    init = {...init, headers}

    const response = await fetch(process.env.REACT_APP_API_IMMODESK + url, init)

    if (!response.ok) {
        console.error(`fetchApi error ${url}: `, response);
        return {
            error: true,
            response: await response.json()
        };
    }
    if(response.statusText === "No Content" || response.status===204) {
        return true;
    }

    return await response.json();

}

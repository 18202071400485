export const LOGO_URL = "https://static.aws.immodesk.fr/images/arka/logo/officiel2021-mini_xxs.png";

export const mediasCategories = [
    {
        id: 12,
        label: "Illustration"
    },
    {
        id: 160,
        label: "Photographie des travaux"
    }
]

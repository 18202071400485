export const ShowDate = ({ date, format }) => {
  const d = new Date(date);
  const f = format
    ? format
    : { day: "numeric", month: "numeric", year: "numeric" };
  return d.toLocaleTimeString("fr-FR", f);
};

export const ShowDateWithHours = ({ date, format }) => {
  const d = new Date(date);
  const f = format
    ? format
    : {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
  return d.toLocaleTimeString("fr-FR", f);
};

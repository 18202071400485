import React from "react";
import { useState } from "react";
import { useAuth } from "../../../Contexts/useAuth.js";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/lab";
import { Box } from "@mui/system";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk.js";

const FormRendezVousClient = ({ clientId, initAction, callback }) => {
  const { state: stateAuth } = useAuth();

  const [action, setAction] = useState(
    initAction ? initAction : { date_echeance: { date: null }, action_id: 2 }
  );
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, reset } = useForm({});

  useEffect(() => {
    if (!initAction) {
      setAction({ date_echeance: { date: null }, action_id: 2 });
      reset();
      return;
    }
    setAction({ ...initAction });
    reset();
  }, [initAction, reset]);

  const getDate = (d) => {
    const date = new Date(d);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${year}-${month}-${day} ${hours}:${minutes}:00`;
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const urlImmodesk =
      action && action.id
        ? `/private/actions/${action.id}`
        : `/private/actions`;
    const fetchApiMethod = action && action.id ? "PUT" : "POST";

    const dateEcheance = action.date_echeance.date
      ? getDate(action.date_echeance.date)
      : null;

    const newAction = {
      ...action,
      contact_id: clientId,
      date_echeance: dateEcheance,
    };

    const response = await fetchApiImmodesk(
      urlImmodesk,
      {
        method: fetchApiMethod,
        body: JSON.stringify(newAction),
      },
      stateAuth.token
    );

    setIsLoading(false);

    if (typeof callback === "function" && response && response.id) {
      const nameAction =
        action && action.id ? "update-action" : "create-action";
      callback(nameAction, response);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            value="end"
            control={
              <Switch
                checked={action.done}
                onChange={(event) => {
                  setAction({
                    ...action,
                    done: event.target.checked,
                  });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Réalisé"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MobileDateTimePicker
            value={action.date_echeance.date}
            onChange={(newValue) => {
              setAction({
                ...action,
                date_echeance: {
                  date: newValue,
                },
              });
            }}
            label="Date"
            inputFormat="dd/mm/yyyy HH:mm"
            mask="__/__/____ __:__"
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="type-action-select-label">Type</InputLabel>
            <Select
              labelId="type-action-select-label"
              id="type-action-select"
              value={action.action_id}
              label="Type"
              onChange={(event) =>
                setAction({
                  ...action,
                  action_id: event.target.value,
                })
              }
            >
              <MenuItem value={2}>Rendez-vous physique</MenuItem>
              <MenuItem value={3}>Rendez-vous téléphonique</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="note"
            fullWidth
            multiline
            rows={5}
            label="Note"
            name="note"
            value={action?.texte || ""}
            InputLabelProps={{
              shrink: action?.texte ? true : false,
            }}
            onChange={(event) =>
              setAction({
                ...action,
                texte: event.target.value,
              })
            }
          />
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading && (
            <CircularProgress
              size="20px"
              color="secondary"
              sx={{ marginRight: "10px" }}
            />
          )}
          <Box component="span">Valider</Box>
        </Button>
      </Box>
    </form>
  );
};

export default FormRendezVousClient;

// components
import SvgIconStyle from '../SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
    user: getIcon('ic_user'),
    dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
    // Clients
    // ----------------------------------------------------------------------
    {
        subheader: '',
        auth: 'clients',
        items: [
            { title: 'Espace client', path: '/clients', icon: ICONS.user },
        ],
    },

    // Configurateurs
    // ----------------------------------------------------------------------
    {
        subheader: '',
        auth: 'configurateurs',
        items: [
            { title: 'Configurateur', path: '/configurateurs', icon: ICONS.dashboard },
        ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    // {
    //     subheader: 'Configurateur',
    //     items: [
    //         {
    //             title: 'user',
    //             path: '/dashboard/user',
    //             icon: ICONS.user,
    //             children: [
    //                 { title: 'Four', path: '/dashboard/user/four' },
    //                 { title: 'Five', path: '/dashboard/user/five' },
    //                 { title: 'Six', path: '/dashboard/user/six' },
    //             ],
    //         },
    //     ],
    // },
];

export default navConfig;

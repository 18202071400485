import React from 'react';
import {LOGO_URL} from "../../const/const";
import { Link } from 'react-router-dom';
import {Box} from "@mui/material";

const Logo = ({sx}) => {
    return (
        <Link to="/">
            <Box sx={{ width: 40, height: 40, ...sx}}>
                <img src={LOGO_URL} alt="homepage" title="logo" height="40"/>
            </Box>
        </Link>
    );
};

export default Logo;

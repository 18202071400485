import React from 'react';
import {Box, Button, Card, CardContent, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import ProgrammeDateTma from "./ProgrammeDateTma";
import ProgrammeDateChoix from "./ProgrammeDateChoix";
import ProgrammeImage from "../../Common/ProgrammeImage";

const ProgrammeCard = ({programme, updateProgramme}) => {
    return (
        <Card>
            <CardContent>
                <Box sx={{ display: 'flex', marginBottom: '20px' }}>
                    <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>
                        {programme.name} {programme.ville && <>- { programme.ville }</>}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: ['column', 'column', 'column', 'row'] }}>
                    <ProgrammeImage imageUrl={programme.illustrationUrl}
                                csx={{ minHeight: '170px', maxWidth: ['100%', '100%', '100%', '300px'], marginBottom: ['20px', '20px', '20px', '0px'] }}/>
                    <Box sx={{ display: 'flex', flex: 1, flexDirection: ['column', 'column', 'row', 'row'], paddingInline: ['0px', '0px',  '0px', '20px'], alignItems: 'center' }}>
                        {programme.tmaEnabled &&
                            <ProgrammeDateTma programme={programme}
                                            callback={updateProgramme}
                                            csx={{ marginRight: ['0px', '0px', '80px', '80px'], marginBottom: ['40px', '40px', '0px', '0px'] }}/>
                        }
                        <ProgrammeDateChoix programme={programme}
                                            callback={updateProgramme}/>
                        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignSelf: 'flex-end', marginTop: ['40px', '40px', '0px', '0px'] }}>
                            <NavLink to={`/configurateurs/programmes/${programme.id}`}>
                                <Button variant="contained">Accéder</Button>
                            </NavLink>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ProgrammeCard;

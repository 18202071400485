import React, {useState} from 'react';
import {Typography} from "@mui/material";
import FormRechercherClient from "../Forms/FormRechercherClient";
import FormClientTransaction from "../Forms/FormClientTransaction";

const AjouterAcquereur = ({lot, callback}) => {
    const [client, setClient] = useState({});

    return (
        <>
            <Typography variant="h6"
                        component="h2"
                        sx={{marginBottom: "20px"}}>Réservation du lot {lot.nom}</Typography>

            <FormRechercherClient handleClientSelected={(c) => setClient(c)}/>

            <Typography sx={{marginTop: "40px", marginBottom: "20px"}}>Ajouter ou modifier un contact</Typography>

            <FormClientTransaction initClient={client}
                                   lot={lot}
                                   callback={callback}/>
        </>
    );
};

export default AjouterAcquereur;

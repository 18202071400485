import React, {useState} from 'react';
import {Button} from "@mui/material";
import ModalComponent from "../../Common/Modal/ModalComponent";
import DemandeDocumentsClient from "../Documents/DemandeDocumentsClient";

const ModalDemandeDocumentsClient = ({client, callback}) => {
    const [openModal, setOpenModal] = useState(false);

    const callbackModal = () => {
        setOpenModal(false);
        if(typeof callback === 'function') {
            callback();
        }
    }

    return (
        <>
            <Button variant="outlined"
                    color="secondary"
                    onClick={() => setOpenModal(true)}>
                Demander un document
            </Button>

            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}>

                <DemandeDocumentsClient client={client}
                                        callback={callbackModal}/>

            </ModalComponent>
        </>
    );
};

export default ModalDemandeDocumentsClient;

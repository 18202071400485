import React from "react";
import NotificationMessage from "./NotificationMessage";
import { Box, Divider } from "@mui/material";
import NotificationSignatureRefused from "./NotificationSignatureRefused";
import NotificationNewDocumentCustomerAvailable from "./NotificationNewDocumentCustomerAvailable.js";

const NotificationsClients = ({
  notifications,
  handleChangeCallback,
  handleClickCallback,
}) => {
  const getItem = (notification) => {
    switch (notification.ressource) {
      case "messages":
        if (
          notification.params &&
          notification.params.type &&
          notification.params.type === "signature-refused"
        ) {
          return (
            <NotificationSignatureRefused
              notification={notification}
              handleChangeCallback={handleChangeCallback}
            />
          );
        }

        return (
          <NotificationMessage
            notification={notification}
            handleChangeCallback={handleChangeCallback}
            handleClickCallback={handleClickCallback}
          />
        );
      case "new-document-customer-available":
        return (
          <NotificationNewDocumentCustomerAvailable
            notification={notification}
            handleClickCallback={handleClickCallback}
            handleChangeCallback={handleChangeCallback}
          />
        );
      default:
        return "";
    }
  };

  return (
    <>
      {notifications &&
        notifications.map((notification) => (
          <Box key={notification.id}>
            {getItem(notification)}
            <Divider sx={{ margin: "0 !important" }} />
          </Box>
        ))}
    </>
  );
};

export default NotificationsClients;

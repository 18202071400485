import React, {useState} from 'react';
import {Box, Button, TextField} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const FormEnvoyerEmail = ({actionSubmit, isLoading, initSujet, initMessage}) => {
    const [sujet, setSujet] = useState(initSujet || "");
    const [message, setMessage] = useState(initMessage || "");

    const handleSubmit = () => {
        actionSubmit({sujet, message});
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <TextField label="Sujet"
                       size="small"
                       value={sujet}
                       onChange={(e) => setSujet(e.target.value)}
                       variant="outlined"/>

            <TextField label="Message"
                       multiline
                       rows={8}
                       value={message}
                       onChange={(e) => setMessage(e.target.value)}
                       maxRows={Infinity}
                       sx={{marginTop: "20px"}}
            />

            <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                <Button variant="contained"
                        color="primary"
                        disabled={isLoading || sujet.length === 0 || message.length === 0}
                        onClick={handleSubmit}>{isLoading && <CircularProgress size="20px" color="secondary" sx={{marginRight: '10px'}}/>} <Box component="span">Envoyer</Box></Button>
            </Box>
        </Box>
    );
};

export default FormEnvoyerEmail;

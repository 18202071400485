import React from 'react';
import {Navigate} from "react-router-dom";
import {useAuth} from "../Contexts/useAuth";

const GuardedRoute = ({component: Component, permission}) => {
    const {state: stateAuth} = useAuth();

    if ((!Component && stateAuth && stateAuth.isAuth && stateAuth.configsCommercialisateur) ||
        (!Component && permission && permission === 'clients' && stateAuth && stateAuth.configsCommercialisateur && !stateAuth.configsCommercialisateur.use_userspace) ||
        (!Component && permission && permission === 'configurateurs' && stateAuth && stateAuth.configsCommercialisateur && !stateAuth.configsCommercialisateur.use_configurateur)) {
        const routeForRedirect = stateAuth.configsCommercialisateur.use_userspace ? '/clients' : '/configurateurs';
        return <Navigate to={routeForRedirect}/>;
    }

    return (
        <>
            {stateAuth && stateAuth.isAuth && Component ? <Component/> : <Navigate to={"/login"}/>}
        </>
    )
};

export default GuardedRoute;

import React, { useCallback, useEffect, useState } from "react";
import { fetchApiConfigurateur } from "../../../Api/Api_configurateur";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Loading from "../../Common/Loading";
import { useParams } from "react-router-dom";
import Lots from "../Lots/Lots";
import { useAuth } from "../../../Contexts/useAuth";
import ButtonDownload from "../ButtonDownload";
import ProgrammeDateChoix from "./ProgrammeDateChoix";
import ProgrammeDateTma from "./ProgrammeDateTma";
import { fetchArchive } from "../../../Api/Api_archive";
import MainContainer from "../../Common/MainContainer";
import ProgrammeImage from "../../Common/ProgrammeImage";
import TabPanel from "../../Clients/TabPanel";
import ProgrammeProduitsStats from "../Stats/ProgrammeProduitsStats";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Programme = () => {
  const { state: stateAuth } = useAuth();

  const { id } = useParams();
  const [programme, setProgramme] = useState({});
  const [lots, setLots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lotsSelected, setLotsSelected] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const dataProgrammes = await fetchApiConfigurateur(
      `/private/programmes/${id}`,
      {},
      stateAuth.token
    );
    const dataLots = await fetchApiConfigurateur(
      `/private/programmes/${id}/lot`,
      {},
      stateAuth.token
    );
    const newLots = await Promise.all(
      dataLots.rows.map(async lot => {
        const configurationId =
          lot.configuration_tma_id ?? lot.configuration_deco_id;
        if (!configurationId) {
          return lot;
        }
        lot.configuration = await fetchApiConfigurateur(
          `/private/configurations/${configurationId}/`,
          {},
          stateAuth.token
        );
        return lot;
      })
    );

    setProgramme(dataProgrammes);
    setLots(newLots);
    setIsLoading(false);
  }, [id, stateAuth]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleDownload = async () => {
    const files = [];

    lotsSelected.forEach(lot => {
      if (lot.documents_deco && lot.documents_deco.ficheChoixUrl) {
        files.push(
          formatFile("choix", lot.name, lot.documents_deco.ficheChoixUrl)
        );
      }
      if (lot.documents_deco && lot.documents_deco.fichePoseUrl) {
        files.push(
          formatFile("pose", lot.name, lot.documents_deco.fichePoseUrl)
        );
      }
      if (lot.documents_deco && lot.documents_deco.signed) {
        files.push(
          formatFile("choix_signée", lot.name, lot.documents_deco.signed)
        );
      }
      if (lot.documents_tma && lot.documents_tma.ficheTmaUrl) {
        files.push(formatFile("tma", lot.name, lot.documents_tma.ficheTmaUrl));
      }
      if (lot.documents_tma && lot.documents_tma.ficheTmaArchitecte) {
        files.push(
          formatFile(
            "tma_architecte",
            lot.name,
            lot.documents_tma.ficheTmaArchitecte
          )
        );
      }
      if (lot.documents_tma && lot.documents_tma.signed) {
        files.push(
          formatFile(
            "tma_architecte_signée",
            lot.name,
            lot.documents_tma.signed
          )
        );
      }
    });

    const nameArchive = `Fiches_du_programme_${programme.name
      .replaceAll(" ", "_")
      .toUpperCase()}`;
    const archive = await fetchArchive(nameArchive, files);

    if (archive.url) {
      window.open(archive.url);
    }
  };

  const formatFile = (ficheType, lotNom, fileUrl) => {
    return {
      url: fileUrl,
      nom: `fiche_${ficheType}_${lotNom}.pdf`,
      folder: `${lotNom}/`,
    };
  };

  const handleLotsSelectedChange = lots => {
    setLotsSelected(lots);
  };

  return (
    <MainContainer>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <Typography variant="h3" sx={{ marginBottom: "40px" }}>
            {programme.name} {programme.ville && <>- {programme.ville}</>}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: ["column", "column", "column", "row"],
            }}
          >
            <ProgrammeImage
              imageUrl={programme.illustrationUrl}
              csx={{
                minHeight: "170px",
                maxWidth: ["100%", "100%", "100%", "300px"],
                marginBottom: ["20px", "20px", "20px", "0px"],
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: ["column", "column", "row", "row"],
                paddingInline: ["0px", "0px", "0px", "40px"],
                alignItems: ["center", "center", "flex-start", "flex-start"],
              }}
            >
              {programme.tmaEnabled && (
                <ProgrammeDateTma
                  programme={programme}
                  callback={newProgramme => setProgramme(newProgramme)}
                  csx={{
                    marginRight: ["0px", "0px", "80px", "80px"],
                    marginBottom: ["40px", "40px", "0px", "0px"],
                    alignItems: "flex-start",
                  }}
                />
              )}
              <ProgrammeDateChoix
                programme={programme}
                callback={newProgramme => setProgramme(newProgramme)}
              />
            </Box>
          </Box>

          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}
          >
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => setTabValue(newValue)}
            >
              <Tab label="Lots" {...a11yProps(0)} />
              {/* <Tab label="Statistiques" {...a11yProps(1)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Box>
                <ButtonDownload
                  disabled={lotsSelected.length === 0}
                  download={handleDownload}
                  label="Télécharger les fiches"
                />
              </Box>
            </Box>
            <Lots
              lots={lots}
              programme={programme}
              lotsSelectedChange={handleLotsSelectedChange}
              callback={fetchData}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ProgrammeProduitsStats programmeId={programme.id} />
          </TabPanel>
        </>
      )}
    </MainContainer>
  );
};

export default Programme;

import React, {useState} from 'react';
import {Box, Popover} from "@mui/material";
import {useTheme} from "@mui/material/styles";

const SignatureConfiguration = ({procedure}) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const getMemberTitle = member => {
        let label = "";
        let dateUpdate = "";

        if (member.updatedAt) {
            const d = new Date(member.updatedAt);
            dateUpdate = d.toLocaleDateString('fr-FR', {day: 'numeric', month: 'numeric', year: 'numeric'});
        }

        if (member.status === 'pending') {
            label = "en cours de signature";
        } 
        else if (member.status === 'done') {
            label = `- signée le ${dateUpdate}`
        }
        else if (member.status === 'refused') {
            const comment = member.comment && member.comment.length > 0 ? member.comment : 'aucun commentaire';
            label = ` - refusée le ${dateUpdate} <br /> ${comment}` ;
        }

        return `${member.firstname.toUpperCase()} ${member.lastname} ${label}`
    }
    
    const getLabelByProcedureStatus = () => {
        switch(procedure.status) {
            case 'active':{
                return "En cours de signature";
            }
            case 'refused': {
                return "Signature refusée";
            }
            case 'finished': {
                return "Signature validée";
            }
            default: {
                return procedure.status
            }
        }
    }

    const getBgColor = () => {
        switch(procedure.status) {
            case 'active':{
                return theme.palette.warning.main;
            }
            case 'refused': {
                return theme.palette.error.main;
            }
            case 'finished': {
                return theme.palette.success.main;
            }
            default: {
                return theme.palette.warning.main;
            }
        }
    }

    const getColor = () => {
        switch(procedure.status) {
            case 'active':{
                return theme.palette.warning.contrastText;
            }
            case 'refused': {
                return theme.palette.error.contrastText;
            }
            case 'finished': {
                return theme.palette.success.contrastText;
            }
            default: {
                return theme.palette.warning.contrastText;
            }
        }
    }

    return (
        procedure.members &&
        <>
            <Box onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{
                    backgroundColor: getBgColor(),
                    paddingBlock: '3px',
                    paddingInline: '9px',
                    color: getColor(),
                    fontSize: theme.typography.caption,
                    borderRadius: '5px'
                }}>
                {getLabelByProcedureStatus()}
            </Box>

            <Popover sx={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus>
                <Box sx={{
                    paddingBlock: '10px',
                    paddingInline: '15px',
                }}> 

                <>
                    {procedure && procedure.members.map(member => (
                        <Box fontSize="small" 
                                    sx={{ margin: '2px' }}
                                    dangerouslySetInnerHTML={{__html: getMemberTitle(member)}}
                                    key={member.id}/>
                    ))}
                </>
     
                </Box>
            </Popover>
        </>
    );
};

export default SignatureConfiguration;

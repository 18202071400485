import { Link } from '@mui/material'
import React, { useState } from 'react'
import { fetchApiImmodesk } from '../../../Api/Api_immodesk';
import { useAuth } from '../../../Contexts/useAuth';
import ModalConfirmation from '../../Common/Modal/ModalConfirmation';

const RelancerClients = ({clientsId}) => {
	const {state: stateAuth} = useAuth();

	const [openModal, setOpenModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleConfirmationModal = async () => {
        const promoteurId = stateAuth.profil.rattachment_id
            ? stateAuth.profil.rattachment_id
            : stateAuth.profil.employeur_id

        setIsLoading(true);

		const promises = clientsId.map( async (clientId) => (
			fetchApiImmodesk('/public/contacts/demand-activation', {
				method: 'POST',
				body: JSON.stringify({
					"contact_id": clientId,
					"promoteur_id": promoteurId
				})
			}
		)));

		await Promise.all(promises);

		setOpenModal(false);
		setIsLoading(false);
	}

  return (
	<>
		<Link
			component="button"
			sx={{fontSize: "1rem"}}
			onClick={() => setOpenModal(true)}>Relancer les clients de ce programme</Link>
		<ModalConfirmation
			openModal={openModal}
			handleConfirm={handleConfirmationModal}
			handleClose={() => setOpenModal(false)}
			loading={isLoading}
			message="Souhaitez-vous renvoyer le lien d'activation par mail aux clients qui n'ont pas encore activé leur espace client ?"/>
		</>
  )
}

export default RelancerClients

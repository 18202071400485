import React from 'react';
import {useAuth} from "./Contexts/useAuth";
import Loading from "./Components/Common/Loading";
import ThemeProvider from "./Theme/themeMui";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dateFnsLocaleFr from "date-fns/locale/fr";
import {CollapseDrawerProvider} from "./Contexts/CollapseDrawerContext";
import {SettingsProvider} from "./Contexts/SettingsContext";
import {BrowserRouter} from "react-router-dom";
import Router from "./Components/Router";
import {NotificationsProvider} from "./Contexts/useNotifications";

const App = () => {
    const {state: stateAuth} = useAuth();

    return (
        <NotificationsProvider>
            <ThemeProvider>
                <SettingsProvider>
                    <CollapseDrawerProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}
                                              locale={dateFnsLocaleFr}>
                            {!stateAuth.init
                                ? <Loading/>
                                : <BrowserRouter>
                                    <Router/>
                                </BrowserRouter>
                            }
                        </LocalizationProvider>
                    </CollapseDrawerProvider>
                </SettingsProvider>
            </ThemeProvider>
        </NotificationsProvider>
    );
}

export default App;

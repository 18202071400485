import { Select } from '@mui/material';
import React from 'react'

const CustomMuiSelect = ({label, labelId, id, value, defaultValue, disabled, onChange, size, children, ...othersProps}) => {

const SelectMenuProps = {
	PaperProps: {
		style: {
			maxHeight: 50 * 3.5,
			borderRadius: "6px"
		},
	},
};

  return (
	<Select
		labelId={labelId}
		id={id}
		value={value}
		defaultValue={defaultValue}
		label={label}
		disabled={disabled}
		size={size}
		MenuProps={SelectMenuProps}
		onChange={onChange}
		{...othersProps}
	>
		{children}
	</Select>
  )
}

export default CustomMuiSelect

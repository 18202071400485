import React, {useEffect, useState} from 'react';
import {useAuth} from "../../../Contexts/useAuth";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import TimelineProgramme from "../Timeline/TimelineProgramme";
import {Box} from "@mui/material";
import Loading from "../../Common/Loading";
import ModalAjouterEvenementProgramme from "../Modal/ModalAjouterEvenementProgramme";
import TableEvenements from "./TableEvenements";

const CalendrierProgramme = ({programme}) => {
    const {state: stateAuth} = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [evenements, setEvenements] = useState([]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    const fetchData = async () => {
        setIsLoading(true);
        const dataEvenements = await fetchApiImmodesk(`/private/programmes/${programme.id}/timeline`, {}, stateAuth.token);
        setIsLoading(false);

        setEvenements(sortActualitesByDate(dataEvenements));
    }

    const addEvenement = (evenement) => {
        setEvenements(sortActualitesByDate([...evenements, evenement]));
    }

    const deleteEvenement = (evenement) => {
        const newEvenements = [...evenements];
        const indexEvenement = newEvenements.findIndex(a => a.id === evenement.id);

        if(indexEvenement === -1) {
            return;
        }

        newEvenements.splice(indexEvenement, 1);
        setEvenements(sortActualitesByDate(newEvenements));
    }

    const updateEvenement = (evenement) => {
        const newEvenements = [...evenements];
        const indexEvenement = newEvenements.findIndex(a => a.id === evenement.id);

        if(indexEvenement === -1) {
            return;
        }

        newEvenements[indexEvenement] = evenement;
        setEvenements(sortActualitesByDate(newEvenements));
    }

    const callback = (type, evenement) => {
        switch (type) {
            case 'delete':
                deleteEvenement(evenement);
                break;
            case 'update':
                updateEvenement(evenement);
                break;
            default:
                return;
        }
    }

    const sortActualitesByDate = (dataActualites) => {
        const actualitesSort = dataActualites.sort(function (a, b) {
            return new Date(a.date.date) - new Date(b.date.date);
        });
        return actualitesSort;
    }

        return (
        <>
            {isLoading && <Loading/>}
            {!isLoading &&
                <>
                    <Box sx={{marginBottom: "40px", minWidth: ["unset", "300px"], overflowX: "auto"}}>
                        <TimelineProgramme steps={evenements}/>
                    </Box>

                    <ModalAjouterEvenementProgramme programme={programme}
                                                    callback={addEvenement}/>

                    <Box sx={{marginTop: "20px"}}>
                        <TableEvenements evenements={evenements}
                                         programme={programme}
                                         callback={callback}/>
                    </Box>
                </>
            }
        </>
    );
};

export default CalendrierProgramme;

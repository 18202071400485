import React, {useState} from 'react';
import {Button, Typography} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ModalComponent from "../../Common/Modal/ModalComponent";
import FormEnvoyerEmail from "./FormEnvoyerEmail";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";

const LotsEnvoyerEmail = ({lots}) => {
    const {state: stateAuth} = useAuth();

    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const envoyerEmail = async(form) => {
        const contactsId = lots.reduce((accumulator, lot) => {
            if(!lot.transaction) {
                return accumulator;
            }
            if(accumulator.length === 0) {
                return accumulator + lot.transaction.client_id;
            }
            return accumulator + "," + lot.transaction.client_id;
        }, "");

        setIsLoading(true);

        const data = await fetchApiImmodesk('/private/messages', {
            method: 'POST',
            body: JSON.stringify({
                sujet: form.sujet,
                message: form.message,
                contact_id: contactsId
            })
        }, stateAuth.token);

        setIsLoading(false);

        if(data && data.success) {
            setOpenModal(false);
        }
    }

    return (
        <>
            <Button variant="contained"
                    color="secondary"
                    onClick={() => setOpenModal(true)}
                    disabled={lots.length === 0}><EmailIcon sx={{width: "20px", marginRight: "10px"}}/> Envoyer un email</Button>

            <ModalComponent openModal={openModal} handleClose={() => setOpenModal(false)}>
                <Typography variant="h5" sx={{marginBottom: "25px"}}>Envoyer un email</Typography>
                <FormEnvoyerEmail actionSubmit={envoyerEmail} isLoading={isLoading}/>
            </ModalComponent>
        </>
    );
};

export default LotsEnvoyerEmail;

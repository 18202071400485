import React, {useCallback, useEffect, useState} from 'react';
import {Box, TextField} from "@mui/material";
import {DatePicker} from "@mui/lab";
import {fetchApiConfigurateur} from "../../../Api/Api_configurateur";
import {useAuth} from "../../../Contexts/useAuth";
import DisplayDateCalendarFormat from "../DisplayDateCalendarFormat";

const ProgrammeDateTma = ({programme, callback, csx}) => {
    const {state: stateAuth} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [tmaBeginningAt, setTmaBeginningAt] = useState(programme.tmaBeginningAt);
    const [tmaEndAt, setTmaEndAt] = useState(programme.tmaEndAt);

    const updateProgramme = useCallback(async () => {
        const newProgramme = {...programme, tmaBeginningAt, tmaEndAt};

        setIsLoading(true);

        const updateProgramme = await fetchApiConfigurateur(`/private/programmes/${programme.id}`, {
            'method': 'PUT',
            body: JSON.stringify(newProgramme)
        }, stateAuth.token);

        setIsLoading(false)

        if(typeof callback === "function") {
            callback(updateProgramme);
        }

    }, [stateAuth, tmaBeginningAt, tmaEndAt, programme, callback]);

    useEffect(() => {
      if(programme && (programme.tmaBeginningAt !== tmaBeginningAt || programme.tmaEndAt !== tmaEndAt)) {
        updateProgramme();
      }
        // eslint-disable-next-line
    }, [tmaBeginningAt, tmaEndAt])

    return (
        <Box sx={{display: 'flex', alignItems: 'center', paddingTop: '5px', ...csx}}>
            <Box sx={{marginRight: '40px'}}>
                {tmaEndAt &&
                    <DisplayDateCalendarFormat date={tmaEndAt} />
                }
            </Box>
            <Box sx={{width: '150px', display: 'flex', flexDirection: 'column'}}>
                <Box>
                    <DatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Ouverture des TMA"
                        value={tmaBeginningAt}
                        inputFormat="dd/MM/yyyy"
                        maxDate={tmaEndAt}
                        disabled={isLoading}
                        onChange={(newValue) => {
                            setTmaBeginningAt(newValue)
                        }}
                    />
                </Box>

                <Box sx={{marginTop: '1.5rem'}}>
                    <DatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Cloture des TMA"
                        value={tmaEndAt}
                        inputFormat="dd/MM/yyyy"
                        minDate={tmaBeginningAt}
                        disabled={isLoading}
                        onChange={(newValue) => {
                            setTmaEndAt(newValue)
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default ProgrammeDateTma;

import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { ShowDate } from "../../Common/showDate";
import DeposerDocumentClientDemande from "./DeposerDocumentClientDemande";
import CustomMuiSelect from "../../CustomMui/CustomMuiSelect";

const DeposerDocumentClientDemandeListe = ({
  documents,
  clientId,
  callback,
}) => {
  const [documentSelected, setDocumentsSelected] = useState();

  const handleChangeDocument = (event) => {
    const document = documents.find((d) => d.id === event.target.value);

    if (!document) {
      return;
    }

    setDocumentsSelected(document);
  };

  return (
    <>
      <FormControl sx={{ width: "100%", marginBottom: "40px" }}>
        <InputLabel id="select-documents-demandes-label">
          Sélectionner une demande dans la liste
        </InputLabel>
        <CustomMuiSelect
          labelId="select-documents-demandes-label"
          id="select-documents-demandes"
          value={documentSelected?.id}
          label="Sélectionner une demande dans la liste"
          onChange={handleChangeDocument}
        >
          {documents &&
            documents.map((document) => (
              <MenuItem value={document.id} key={document.id}>
                {document.media_categorie_label} -{" "}
                <ShowDate date={document.date_creation.date} />
              </MenuItem>
            ))}
        </CustomMuiSelect>
      </FormControl>

      <DeposerDocumentClientDemande
        clientId={clientId}
        document={documentSelected}
        callback={callback}
      />
    </>
  );
};

export default DeposerDocumentClientDemandeListe;

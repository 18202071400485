export const fetchArchive = async (key, files) => {
    const body = {
        input: files,
        output: {
            bucket: "medias-preprod",
            key: key,
            acl: "public-read"
        },
        options: {
            type_compression: "zip",
            level_compression: 9
        }
    }

    const bodyStringify = JSON.stringify(body);

    const response = await fetch(process.env.REACT_APP_ARCHIVE_URL, {
        'method': 'POST',
        'headers': new Headers({
            'Content-Length': bodyStringify.length,
            'Content-Type': 'application/json',
        }),
        'body': bodyStringify,
    })

    if (!response.ok) {
        console.error(`fetchArchive error`, response);
        return false;
    }

    return await response.json();
}

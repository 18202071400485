import React from 'react';
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";

const TchatMessage = ({message, title, position, backgroundColor, color}) => {
    const theme = useTheme();

    const sxBox = {
        container: {
            marginBottom: '40px',
            paddingRight: '5px'
        },
        title: {
            textAlign: 'center',
            marginBottom: '10px',
            fontSize: '0.9rem',
            color: theme.palette.grey["500"],
        },
        content: {
            display: 'flex',
            justifyContent: position,
        },
        message: {
            display: 'inline-flex',
            maxWidth: '300px',
            padding: '8px 10px',
            backgroundColor: backgroundColor,
            color: color,
            borderRadius: '8px',
            fontSize: '1rem'
        }
    };

    return (
        <Box sx={sxBox.container}>
            <Box sx={sxBox.title}>{title}</Box>
            <Box sx={sxBox.content}>
                <Box sx={sxBox.message} dangerouslySetInnerHTML={{
                    __html: message
                }}/>
            </Box>
        </Box>
    );
};

export default TchatMessage;

import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import { useAuth } from "../../../Contexts/useAuth";

const SwitchCompteClient = ({
  clientId,
  statut,
  disabledSwitch,
  callback,
  lot,
  programme,
}) => {
  const { state: stateAuth } = useAuth();

  const [openModal, setOpenModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setChecked(statut && statut !== "INACTIF" && statut !== "DESACTIVER");
    if (statut === "INACTIF" || !statut) {
      setMessage(
        "Souhaitez-vous activer l'espace client et envoyer le lien d'activation par mail au client ?"
      );
    } else if (statut === "DEMANDE_ACTIVATION" || statut === "ACTIVATION") {
      setMessage("Souhaitez-vous désactiver l'espace client ?");
    } else if (statut === "DESACTIVER") {
      setMessage("Souhaitez-vous réactiver l'espace client ?");
    }
  }, [statut]);

  const handleConfirm = () => {
    if (statut === "INACTIF" || !statut) {
      sendDemandeActivation();
    } else if (statut === "DEMANDE_ACTIVATION" || statut === "ACTIVATION") {
      desactiver();
    } else if (statut === "DESACTIVER") {
      reactiver();
    }
  };

  const sendDemandeActivation = async () => {
    setIsLoading(true);

    const promoteurId = stateAuth.profil.rattachment_id
      ? stateAuth.profil.rattachment_id
      : stateAuth.profil.employeur_id;

    const data = await fetchApiImmodesk("/public/contacts/demand-activation", {
      method: "POST",
      body: JSON.stringify({
        contact_id: clientId,
        promoteur_id: promoteurId,
        programme_id: lot.programme_id,
        lot_id: lot.id,
        date_debut_choix: programme.configurateurDatas
          ? new Date(programme.configurateurDatas.beginningAt)
              .toJSON()
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("-")
          : null,
        date_fin_choix: programme.configurateurDatas
          ? new Date(programme.configurateurDatas.endAt)
              .toJSON()
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("-")
          : null,
        admin_adv: programme.configurateurDatas
          ? programme.configurateurDatas.administrateurAdv
          : null,
      }),
    });

    setIsLoading(false);
    setOpenModal(false);

    if (data.response.success) {
      setChecked(!checked);
      if (typeof callback === "function") {
        callback("update-client-activation", {
          clientId: clientId,
          data: {
            date_activation: null,
            date_demande_activation: null,
            statut_activation: "DEMANDE_ACTIVATION",
          },
        });
      }
    }
  };

  const desactiver = async () => {
    setIsLoading(true);

    const data = await fetchApiImmodesk(
      `/private/contacts/${clientId}/desactiver`,
      {
        method: "POST",
        body: JSON.stringify({}),
      },
      stateAuth.token
    );

    setIsLoading(false);
    setOpenModal(false);

    if (!data || data.error) {
      // TODO error
      return;
    }

    setChecked(false);

    if (typeof callback === "function") {
      callback("update-client-activation", { clientId: clientId, data });
    }
  };

  const reactiver = async () => {
    setIsLoading(true);

    const data = await fetchApiImmodesk(
      `/private/contacts/${clientId}/reactiver`,
      {
        method: "POST",
        body: JSON.stringify({}),
      },
      stateAuth.token
    );

    setIsLoading(false);
    setOpenModal(false);

    if (!data || data.error) {
      // TODO error
      return;
    }

    setChecked(true);

    if (typeof callback === "function") {
      callback("update-client-activation", { clientId: clientId, data });
    }
  };

  const handleChange = () => {
    setOpenModal(true);
  };

  return (
    <>
      <Switch
        checked={checked}
        onChange={handleChange}
        color="success"
        disabled={disabledSwitch || isLoading}
        inputProps={{ "aria-label": "controlled" }}
      />
      <ModalConfirmation
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={handleConfirm}
        loading={isLoading}
        message={message}
      ></ModalConfirmation>
    </>
  );
};

export default SwitchCompteClient;

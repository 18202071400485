import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const TableProduits = ({produits}) => {

    const getDimensions = (produit) => {
        let dimensions = "";

        if(!produit || !produit.product_informations) {
            return dimensions;
        }

        const produitInformations = JSON.parse(JSON.stringify(produit.product_informations));

        if(!produitInformations.largeur || !produitInformations.hauteur) {
            return dimensions;
        }

        return `${produitInformations.largeur}x${produitInformations.hauteur}`;
    }

    return (
        <TableContainer>
            <Table aria-label="sticky table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Vignette</TableCell>
                        <TableCell>Ref</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Fabricant</TableCell>
                        <TableCell>Catégorie</TableCell>
                        <TableCell>Dimensions</TableCell>
                        <TableCell>Nb de lots</TableCell>
                        <TableCell>Nb de pièces</TableCell>
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {produits && produits.map((produit, index) => (
                        <TableRow hover key={index} sx={{height: "130px"}}>
                            <TableCell>
                                <img src={produit.product_thumb_keyS3_url}
                                     alt={produit.product_name}
                                     loading="lazy"
                                     style={{
                                         width: "100px",
                                         maxHeight: "100px",
                                         border: "1px solid #EEE"
                                    }}/>
                            </TableCell>
                            <TableCell>{produit.product_code}</TableCell>
                            <TableCell>{produit.product_name}</TableCell>
                            <TableCell>{produit.brand_name}</TableCell>
                            <TableCell>{produit.family_name} / {produit.category_name}</TableCell>
                            <TableCell>{getDimensions(produit)}</TableCell>
                            <TableCell>{produit.nb_lots}</TableCell>
                            <TableCell>{produit.nb_pieces}</TableCell>
                            <TableCell>{produit.total_quantity}</TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableProduits;

import React from 'react';
import {Masonry} from "@mui/lab";
import {alpha, useTheme} from "@mui/material/styles";
import {Box} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

const SelectMediasProgramme = ({medias, mediasSelected, handleAddMediaSelected}) => {
    const theme = useTheme();

    const mediaIsSelected = (media) => {
        return mediasSelected.find(m => m.id === media.id);
    }

    return (
        <Box sx={{display: "flex", alignItems: "center", minHeight: "100px"}}>
            <Masonry columns={2} spacing={2}>
                {medias.map((media, index) => (
                    <Box onClick={(event) => handleAddMediaSelected(media)}
                         key={index}
                         sx={{
                             position: "relative",
                             display: 'block',
                             maxWidth: '100%',
                             "&:hover": {
                                 cursor: "pointer"
                             }
                         }}>
                        <img
                            src={media.url}
                            srcSet={media.img}
                            alt={media.id}
                            loading="lazy"
                        />
                        <Box sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            visibility: mediaIsSelected(media) ? "visible" : "hidden",
                            backgroundColor: alpha(theme.palette.primary.main, 0.5),
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: theme.palette.common.white,
                        }}><CheckIcon sx={{fontSize: "3rem"}}/>
                        </Box>
                    </Box>
                ))}
            </Masonry>
        </Box>
    );
};

export default SelectMediasProgramme;

import React from 'react';
import {Box} from "@mui/material";
import { useTheme } from '@emotion/react';

const ProgrammeImage = ({imageUrl, csx}) => {
    const theme = useTheme();

    const defaultSx = {
        width: "100%",
        minHeight: "300px",
        maxWidth: "350px",
        display: "block",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.grey["600"],
        backgroundColor: theme.palette.grey["600"],
        backgroundImage: `url('${imageUrl}')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover"
    }

    return (
        <Box sx={{...defaultSx, ...csx}}>
        </Box>
    );
};

export default ProgrammeImage;

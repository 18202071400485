import React, {useState} from 'react';
import {Button} from "@mui/material";
import ModalComponent from "../../Common/Modal/ModalComponent";
import FormActualiteProgramme from "../Forms/FormActualiteProgramme";

const ModalAjouterActualiteProgramme = ({programme, callback}) => {
    const [openModal, setOpenModal] = useState(false);

    const callbackModal = (actualite) => {
        setOpenModal(false);
        if(typeof callback === 'function') {
            callback(actualite);
        }
    }

    return (
        <>
            <Button variant="outlined"
                    color="secondary"
                    onClick={() => setOpenModal(true)}>
                Ajouter une actualité
            </Button>

            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}>
                <FormActualiteProgramme programme={programme}
                                        callback={callbackModal}/>
            </ModalComponent>
        </>
    );
};

export default ModalAjouterActualiteProgramme;

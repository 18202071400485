import React from 'react';
import {Box, Button, Chip} from "@mui/material";

const AjouterFichier = ({files, setFiles}) => {
    const onChange = (event) => {
        if (!event || !event.target || !event.target.files) {
            return;
        }

        const file = event.target.files[0];
        file.id = Date.now();

        setFiles([...files, file]);
    }

    const handleDelete = (file) => {
        if (!file || !file.id) {
            return;
        }

        const fileIndex = files.findIndex(f => f.id === file.id);

        if (fileIndex === -1) {
            return;
        }

        const cpFiles = [...files];
        cpFiles.splice(fileIndex, 1);
        setFiles(cpFiles);
    }

    return (
        <Box>
            <label htmlFor="deposer-document-client-file">
                <input id="deposer-document-client-file"
                       multiple
                       type="file"
                       accept="image/*,.pdf,.odt,.ods,.docx,.doc,.xls,.xlsx"
                       onChange={onChange}
                       style={{display: "none"}}/>
                <Button variant="contained"
                        color="secondary"
                        component="span"
                >
                    Choisir un fichier
                </Button>
            </label>

            {files && files.length > 0 &&
                <>
                <Box sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "5px",
                    width: "100%",
                    marginTop: "30px"
                }}>
                    {files.map(file => (
                        <Chip label={`${file.name}`}
                              onDelete={() => handleDelete(file)}
                              sx={{width: "140px", justifyContent: "space-between"}}
                              key={file.id}/>
                    ))}
                </Box>
                </>
            }
        </Box>
    );
};

export default AjouterFichier;

import React, {useCallback, useEffect, useState} from 'react';
import {useAuth} from "../../../Contexts/useAuth";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput} from "@mui/material";
import CustomMuiSelect from '../../CustomMui/CustomMuiSelect';

const DocumentsCategories = ({callback, callbackType}) => {
    const {state: stateAuth} = useAuth();
    const [options, setOptions] = useState([]);
    const [optionsSelectedId, setOptionsSelectedId] = useState([]);
    const [optionsSelected, setOptionsSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = useCallback(async() => {
        setIsLoading(true);

        const data = await fetchApiImmodesk(`/private/configs/documents/categories/?check_exist=0&table_id=34`, {}, stateAuth.token);

        if(data.error) {
            //TODO error
            return;
        }

        setOptions(data);
        setIsLoading(false);
    }, [stateAuth]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(typeof callback === 'function') {
            if(callbackType === 'object') {
                callback(optionsSelected);
                return;
            }
            callback(optionsSelectedId);
        }
    }, [optionsSelectedId, optionsSelected, callback, callbackType]);

    const handleChange = (event) => {
        const value = event.target.value;

        if(value.length === 0) {
            setOptionsSelectedId([]);
            setOptionsSelected([]);
            return;
        }

        const newOptionsSelected = [];

        value.forEach(v => {
            const category = options.find(o => o.id === v);

            if(!category) {
                return;
            }

            newOptionsSelected.push(category);
        })

        const optionsId = newOptionsSelected.map(option => option.id);

        setOptionsSelectedId(optionsId);
        setOptionsSelected(newOptionsSelected);
    }

    return (
        <FormControl sx={{ width: "100%" }}>
            <InputLabel id="multiple-checkbox-documents-demandes-label">Document(s) demandé(s)</InputLabel>
            <CustomMuiSelect
                labelId="multiple-checkbox-documents-demandes-label"
                id="multiple-checkbox-documents-demandes"
                multiple
                value={optionsSelectedId}
                onChange={handleChange}
                input={<OutlinedInput label="Document(s) demandé(s)" />}
                sx={{ width: "100%" }}
                disabled={isLoading}
                renderValue={() => optionsSelected.map(o => o.label).join(', ') }
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={optionsSelectedId.indexOf(option.id) > -1} />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </CustomMuiSelect>
        </FormControl>
    );
};

export default DocumentsCategories;

import {useState} from 'react';
import {useNavigate} from "react-router-dom";
// @mui
import {alpha} from '@mui/material/styles';
import {Box, Divider, Typography, MenuItem, Avatar} from '@mui/material';
import IconButtonAnimate from '../../../Components/Common/Animate/IconButtonAnimate';
import MenuPopover from "../MenuPopover";
import {useAuth} from "../../../Contexts/useAuth";
import { useEffect } from 'react';

export default function AccountPopover() {
    const {state: stateAuth, dispatch} = useAuth();
    const navigate = useNavigate();

    const [open, setOpen] = useState(null);
    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        if(stateAuth && stateAuth.profil && stateAuth.profil.image && stateAuth.profil.image) {
            const timestamp = new Date().getTime(); // Force mise à jour de l'image pour le navigateur
            const newAvatar = stateAuth.profil.image.startsWith('http') ? `${stateAuth.profil.image}?t=${timestamp}` : stateAuth.profil.image;
            setAvatar(newAvatar)
        }
    }, [stateAuth])

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = () => {
        dispatch({type: "logout"});
        navigate("/login");
    }

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >

            <Avatar src={avatar} alt="logo"/>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    {stateAuth && stateAuth.profil &&
                        <>
                            <Typography variant="subtitle2" noWrap>
                                {stateAuth.profil.nom && stateAuth.profil.nom.toUpperCase()} {stateAuth.profil.prenom}
                            </Typography>
                            {stateAuth.profil.email &&
                                <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                                    {stateAuth.profil.email}
                                </Typography>
                            }
                        </>
                    }
                </Box>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <MenuItem onClick={() => navigate("/profil")}>Mes informations</MenuItem>
                <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
            </MenuPopover>
        </>
    );
}

import React from 'react';
import {useTheme} from "@mui/material/styles";
import {Box} from "@mui/material";

const StatsItem = ({item}) => {
    const theme = useTheme();

    const getBackgroundColor = () => {
        switch (item.severity){
            case 'error': return theme.palette.error.main
            case 'warning': return theme.palette.warning.main
            case 'success': return theme.palette.success.main
            default: return theme.palette.info.main
        }
    }

    const sx = {
        box: {
            display: "flex",
            flex: 1,
            backgroundColor: getBackgroundColor(),
            color: "white",
            paddingInline: "10px",
            paddingBlock: "5px",
            borderRadius: "4px",
            alignItems: "center",
        }
    }

    return (
        <>
            <Box severity={item.severity}
                   sx={sx.box}>{item.label}</Box>
            <Box severity={item.severity}
                   sx={{...sx.box, justifyContent: "center"}}>{item.value}</Box>
        </>
    );
};

export default StatsItem;

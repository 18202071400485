import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import StatsItem from "./StatsItem";

const StatsLots = ({ lots }) => {
	const [items, setItems] = useState([]);

	const statutIdLotReserve = 3;
	const statutIdLotVendu = 4;

    useEffect(() => {
        const lotsReserves = lots.filter(l => l.statut_id === statutIdLotReserve);
        const lotsVendus = lots.filter(l => l.statut_id === statutIdLotVendu);

        setItems([
            {
                label: "Nombre total de lots",
                value: lots.length,
                severity: "infos"
            },
            {
                label: "Nombre de lots réservés",
                value: lotsReserves.length,
                severity: "warning"
            },
            {
                label: "Nombre de lots vendus",
                value: lotsVendus.length,
                severity: "success"
            }
        ])
    }, [lots])

    return (
        <Box sx={{display: "grid", gridTemplateColumns: "3fr 50px", gap: "10px", gridAutoRows: "min-content"}}>
            {items && items.map((item, index) => (
                <StatsItem item={item} key={index}/>
            ))}
        </Box>
    );
};

export default StatsLots;

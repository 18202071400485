import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import { useAuth } from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";
import { ShowDate } from "../../Common/showDate";

const DerniereActualite = ({ programmeId, categories }) => {
  const { state: stateAuth } = useAuth();

  const [actualite, setActualite] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const maxLengthShortTitre = 62;

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const currentDate = new Date();
    const endDate = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;

    const dataActualite = await fetchApiImmodesk(
      `/private/programmes/${programmeId}/actualites?categories=${categories}&sort=DESC&end_date=${endDate}`,
      {},
      stateAuth.token
    );

    if (!dataActualite || dataActualite.error) {
      // TODO error
      return;
    }

    if (dataActualite.length === 0) {
      setActualite(false);
    } else if (dataActualite[0]) {
      setActualite({
        ...dataActualite[0],
        shortTitre:
          dataActualite[0].titre.length >= maxLengthShortTitre
            ? dataActualite[0].titre
                .substring(0, maxLengthShortTitre)
                .concat("...")
            : dataActualite[0].titre,
      });
    }

    setIsLoading(false);
  }, [programmeId, stateAuth, categories]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {actualite && (
            <Typography sx={{ height: "48px" }}>
              <Typography variant="span">{actualite.shortTitre}</Typography> (
              <ShowDate date={actualite.date.date} />)
            </Typography>
          )}
          {actualite === false && <Typography>Aucune actualité</Typography>}
        </>
      )}
    </Box>
  );
};

export default DerniereActualite;

import { Checkbox, MenuItem, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { ShowDate } from "../../Common/showDate.js";
import { useTheme } from "@mui/material/styles";

const NotificationMenuItem = ({
  notification,
  date,
  title,
  message,
  handleChangeCallback,
  handleClickCallback,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = () => {
    if (typeof handleChangeCallback !== "function") {
      return;
    }

    handleChangeCallback(notification);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    if (typeof handleClickCallback === "function") {
      handleClickCallback(notification);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <MenuItem sx={{ alignItems: "center", paddingBlock: "10px" }}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          maxWidth: "100%",
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            color: theme.palette.grey["500"],
            fontWeight: "normal",
            textAlign: "center",
          }}
        >
          <ShowDate
            date={date}
            format={{
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }}
          />
        </Box>
        <Box sx={{ fontWeight: "bold" }}>{title}</Box>
        <Box sx={{ whiteSpace: "normal" }}>
          <Typography sx={{}}>{message}</Typography>
        </Box>
      </Box>
      <Box sx={{ width: "32px" }}>
        <Checkbox
          checked={false}
          onChange={handleChange}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          inputProps={{ "aria-label": "controlled" }}
          sx={{ padding: "4px" }}
        />
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            sx={{
              padding: "3px 6px",
              fontSize: "0.7rem",
              color: theme.palette.common.white,
              backgroundColor: theme.palette.common.black,
            }}
          >
            Marquer comme lu
          </Typography>
        </Popover>
      </Box>
    </MenuItem>
  );
};

export default NotificationMenuItem;

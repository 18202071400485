import React from 'react';
import {CardContent, Typography} from "@mui/material";

const CardPersonne = ({label, personne, csx}) => {

    const getCivilite = (civilite) => {
        switch (civilite) {
            case "M": return "M."
            case "F": return "Mme"
            case "MF": return "M. et Mme"
            default: return "M."
        }
    }

    return (
        <CardContent sx={{...csx, border: "1px solid #E1E1E1"}}>
            <Typography variant="h5" component="div" sx={{marginBottom: "20px", fontSize: "1.3rem"}}>
                {label}
            </Typography>
            <Typography variant="body2" component="div">
                <Typography sx={{marginBottom: "5px"}}>{getCivilite(personne.civilite)} {personne.nom} {personne.prenom}</Typography>
                <Typography sx={{marginBottom: "5px"}}>Email: {personne.email}</Typography>
                <Typography>Téléphone mobile: {personne.tel_mobile}</Typography>
            </Typography>
        </CardContent>
    );
};

export default CardPersonne;

import React from 'react';
import {getDate, format, isValid} from "date-fns";
import {Box} from "@mui/material";
import dateFnsLocaleFr from "date-fns/locale/fr";

const DisplayDateCalendarFormat = ({date}) => {
    const newDate = new Date(date);

    const sx = {
        month: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textTransform: 'uppercase',
            backgroundColor: '#c9315b',
            borderRadius: '4px',
            marginBottom: '5px',
            fontSize: '0.85rem',
        },
        day: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#292929',
            textTransform: 'uppercase',
            backgroundColor: '#f5f5f5',
            borderRadius: '4px',
            fontSize: '3rem',
            height: '85px'
        }
    }

    return (
        <Box sx={{width: '95px'}}>
            <Box sx={sx.month}>{ isValid(newDate) ? format(newDate, 'LLLL', {locale: dateFnsLocaleFr}) : '-' }</Box>
            <Box sx={sx.day}>{ isValid(newDate) ? getDate(newDate) : '-' }</Box>
        </Box>
    );
};

export default DisplayDateCalendarFormat;

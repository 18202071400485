import React, {useCallback, useEffect, useState} from 'react';
import {TextField} from "@mui/material";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import {Autocomplete} from "@mui/lab";

const FormRechercherClient = ({handleClientSelected}) => {
    const limit = 25;
    const minLengthSearch = 3;

    const {state: stateAuth} = useAuth();

    const [input, setInput] = useState('')
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const search = useCallback(async(value) => {
        setIsLoading(true);

        const data = await fetchApiImmodesk(`/private/contacts?limit=${limit}&q=${value}`, {}, stateAuth.token);

        if(data && data.data) {
            setOptions(data.data);
        }

        setIsLoading(false);
    }, [limit, stateAuth]);

    useEffect(() => {
        if(input.length < minLengthSearch) {
            setOptions([]);
            return;
        }

        search(input);
    }, [input, search]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.nom.toUpperCase() + " " + option.prenom}
            onInputChange={(event, value) => setInput(value)}
            onChange={(event, value) => handleClientSelected(value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            open={open}
            loadingText="Chargement..."
            noOptionsText="Aucun résultat"
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            loading={isLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Rechercher un contact"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default FormRechercherClient;

import React, {useCallback, useEffect, useState} from 'react';
import {fetchApiConfigurateur} from "../../../Api/Api_configurateur";
import {useAuth} from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";
import TableProduits from "../Produits/TableProduits";

const ProgrammeProduitsStats = ({programmeId}) => {
    const {state: stateAuth} = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [produits, setProduits] = useState([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        const dataProduits = await fetchApiConfigurateur(`/private/programmes/${programmeId}/stats/products`, {}, stateAuth.token);
        setProduits(dataProduits);
        setIsLoading(false);
    }, [programmeId, stateAuth])

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {isLoading && <Loading/>}
            {!isLoading &&
                <TableProduits produits={produits}/>
            }
        </>
    );
};

export default ProgrammeProduitsStats;

import React, {useState} from 'react';
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import {Button} from "@mui/material";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import EmailIcon from '@mui/icons-material/Email';

const ModalActiverEspaceClient = ({client, callback, label, message}) => {
    const {state: stateAuth} = useAuth();

    const btnLabel = label ? label : 'Activer';
    const btnMessage = message ? message : `Souhaitez-vous renvoyer le lien d'activation par mail au client ?`;

    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleConfirmation = async () => {
        setIsLoading(true);

        const promoteurId = stateAuth.profil.rattachment_id
            ? stateAuth.profil.rattachment_id
            : stateAuth.profil.employeur_id

        const dataDemandRecu = await fetchApiImmodesk(`/public/contacts/demand-activation`, {
            method: 'POST',
            body: JSON.stringify({
                contact_id: client.id,
                promoteur_id: promoteurId,
            }),
        });

        const data = await fetchApiImmodesk(`/private/contacts/${client.id}/activation`, {}, stateAuth.token);

        setIsLoading(false);

        if (dataDemandRecu.error) {
            // TODO error
            return;
        }

        if(typeof callback === 'function') {
            callback(data);
        }

        setOpenModal(false);
    }

    return (
        <>
            <Button variant="contained"
                    color="secondary"
                    onClick={() => setOpenModal(true)}>
                <EmailIcon sx={{ marginRight: "10px" }}/> {btnLabel}
            </Button>

            <ModalConfirmation openModal={openModal}
                               handleConfirm={handleConfirmation}
                               handleClose={() => setOpenModal(false)}
                               loading={isLoading}
                               message={btnMessage}/>
        </>
    );
};

export default ModalActiverEspaceClient;

import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import BadgeLot from "../../Common/BadgeLot";
import ModalAjouterAcquereur from "../Modal/ModalAjouterAcquereur";
import SwitchCompteClient from "../Espace-client/SwitchCompteClient";
import AfficherPrix from "../../Common/showPrice";
import ActionsLot from "./ActionsLot";
import ModalFormClient from "../Modal/ModalFormClient";
import IconEspaceClient from "../Espace-client/IconEspaceClient";
import StatutActivation from "../Espace-client/StatutActivation";
import ModalTchatClient from "../Modal/ModalTchatClient";
import { useAuth } from "../../../Contexts/useAuth";

const Lots = ({ lots, lotsSelectedChange, callback, programme }) => {
  const { state: stateAuth } = useAuth();
  const [lotsSelected, setLotsSelected] = useState([]);

  useEffect(() => {
    lotsSelectedChange(lotsSelected);
  }, [lotsSelectedChange, lotsSelected]);

  const handleSelect = (e, lot) => {
    const selectedIndex = lotsSelected.findIndex(l => l.id === lot.id);
    let newLotsSelected = [];

    if (selectedIndex === -1) {
      newLotsSelected = newLotsSelected.concat(lotsSelected, lot);
    } else {
      newLotsSelected = [...lotsSelected];
      newLotsSelected.splice(selectedIndex, 1);
    }

    setLotsSelected(newLotsSelected);
  };

  const handleSelectAll = e => {
    if (e.target.checked) {
      setLotsSelected([...lots]);
      return;
    }
    setLotsSelected([]);
  };

  const isSelected = lot => {
    if (!lot) {
      return false;
    }
    return lotsSelected.findIndex(l => l.id === lot.id) !== -1;
  };

  return (
    <TableContainer>
      <Table aria-label="sticky table" size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "80px" }}>
              <Checkbox
                color="secondary"
                indeterminate={
                  lotsSelected.length > 0 && lotsSelected.length < lots.length
                }
                checked={lots.length > 0 && lotsSelected.length === lots.length}
                onChange={handleSelectAll}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            </TableCell>
            <TableCell>Nom</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Prix</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Compte client</TableCell>
            {stateAuth &&
            stateAuth.configsCommercialisateur &&
            !stateAuth.configsCommercialisateur.messagerie_desactiver ? (
              <TableCell></TableCell>
            ) : null}
            <TableCell>Avancement</TableCell>
            <TableCell>Vendeur</TableCell>
            <TableCell>Statut</TableCell>
            <TableCell sx={{ width: "80px" }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lots &&
            lots.map(lot => (
              <TableRow
                hover
                key={
                  lot.id +
                  (lot.transaction?.client_id ? lot.transaction.client_id : "")
                }
              >
                <TableCell>
                  <Checkbox
                    color="secondary"
                    checked={isSelected(lot)}
                    onChange={e => handleSelect(e, lot)}
                    inputProps={{ "aria-label": "select all" }}
                  />
                </TableCell>
                <TableCell>
                  {lot.transaction?.is_co_client ||
                  lot.transaction?.is_co_acquereur
                    ? ""
                    : lot.nom}
                </TableCell>
                <TableCell>
                  {lot.transaction?.is_co_client ||
                  lot.transaction?.is_co_acquereur ? (
                    ""
                  ) : (
                    <BadgeLot type={lot.type} />
                  )}
                </TableCell>
                <TableCell>
                  {lot.transaction?.is_co_client ||
                  lot.transaction?.is_co_acquereur ? (
                    ""
                  ) : (
                    <AfficherPrix
                      prix={
                        !lot.transaction
                          ? lot.prix_vente_total
                          : lot.transaction && lot.transaction.prix
                          ? lot.prix_vente_total
                          : 0
                      }
                    />
                  )}
                </TableCell>
                <TableCell>
                  {!lot.transaction && lot.statut_id === 1 && (
                    <ModalAjouterAcquereur lot={lot} callback={callback} />
                  )}
                  {lot.transaction && (
                    <ModalFormClient
                      clientId={lot.transaction.client_id}
                      callback={callback}
                    >
                      <Typography variant="span">
                        {lot.transaction.client_nom.toUpperCase()}{" "}
                        {lot.transaction.client_prenom}
                      </Typography>
                    </ModalFormClient>
                  )}
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      a: {
                        display: "flex",
                        marginLeft: "5px",
                        color: "success.main",
                      },
                    }}
                  >
                    <SwitchCompteClient
                      clientId={lot.transaction?.client_id}
                      statut={
                        lot.transaction?.client_statut_activation
                          ? lot.transaction.client_statut_activation
                          : false
                      }
                      disabledSwitch={
                        !lot.transaction || lot.transaction.type_id < 2
                      }
                      programme={programme}
                      lot={lot}
                      callback={callback}
                    />
                    {lot.transaction && lot.transaction.client_id && (
                      <Tooltip title="Accès direct" arrow>
                        <Box>
                          <IconEspaceClient
                            clientId={lot.transaction.client_id}
                            activation={
                              lot.transaction?.client_statut_activation
                                ? lot.transaction.client_statut_activation
                                : false
                            }
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </TableCell>

                {stateAuth &&
                stateAuth.configsCommercialisateur &&
                !stateAuth.configsCommercialisateur.messagerie_desactiver ? (
                  <TableCell>
                    {lot.transaction &&
                      lot.transaction.client_id &&
                      lot.transaction?.client_statut_activation &&
                      lot.transaction.client_statut_activation !==
                        "INACTIF" && (
                        <Tooltip title="Messagerie" arrow>
                          <Box>
                            <ModalTchatClient
                              clientId={lot.transaction.client_id}
                              title={`${lot.transaction.client_nom} ${lot.transaction.client_prenom}`}
                              sujet={`Message - ${lot.programme_nom}`}
                            />
                          </Box>
                        </Tooltip>
                      )}
                  </TableCell>
                ) : null}

                <TableCell>
                  <StatutActivation
                    statut={lot.transaction?.client_statut_activation}
                  />
                </TableCell>
                <TableCell>
                  {lot.transaction &&
                    lot.transaction.vendeur_nom &&
                    lot.transaction.vendeur_prenom && (
                      <>
                        {lot.transaction.vendeur_nom.toUpperCase()}{" "}
                        {lot.transaction.vendeur_prenom}
                      </>
                    )}
                </TableCell>
                <TableCell>
                  <Typography
                    variant="span"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {lot.statut_label}
                  </Typography>
                </TableCell>
                <TableCell>
                  <ActionsLot lot={lot} callback={callback} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Lots;

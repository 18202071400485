import React, {useState} from 'react';
import {Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {styled} from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {ShowDateWithHours} from "../../Common/showDate";
import ActionsDocumentsClientDemande from "./ActionsDocumentsClientDemande";
import ModalRelancerClientsDemandeDocumentsForm from "../Modal/ModalRelancerClientsDemandeDocumentsForm";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '0 !important',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
))(({theme}) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function DemandesDocumentsAccordion({data, isSelected, handleSelect, callback}) {
    const [expanded, setExpanded] = useState(true);

    const handleChange = (event, newExpanded) => {
        if(!event || !event.target || !event.target.className) {
            return;
        }
        if(event.target.className.includes('MuiAccordionSummary-content')) {
            setExpanded(!expanded);
        }
    }

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary aria-controls={`panel-${data.contact_id}-d-content`} id={`panel-${data.contact_id}-d-header`}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        color="secondary"
                        checked={isSelected(data)}
                        onChange={() => handleSelect(data)}
                    />
                    <Typography sx={{ fontWeight: 'bold', cursor: 'auto' }}>{data.contact_prenom} {data.contact_nom}</Typography>
                </Box>
                <ModalRelancerClientsDemandeDocumentsForm
                    variant="outlined"
                    clientId={data.contact_id}
                    disabled={data.documents.length === 0}
                />
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer>
                    <Table aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '200px'}}>Lot</TableCell>
                                <TableCell sx={{ width: '250px'}}>Date</TableCell>
                                <TableCell>Catégorie</TableCell>
                                <TableCell sx={{width: "80px"}}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.documents && data.documents.map((document) => (
                                <TableRow hover key={document.id}>
                                    <TableCell>
                                        {document.list_lot}
                                    </TableCell>
                                    <TableCell>
                                        <ShowDateWithHours date={document.date_creation.date}/>
                                    </TableCell>
                                    <TableCell>{document.media_categorie_label}</TableCell>
                                    <TableCell>
                                        <ActionsDocumentsClientDemande
                                            document={document}
                                            clientId={data.contact_id}
                                            callback={callback}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    );
}

export default DemandesDocumentsAccordion;

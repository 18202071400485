import React, { useCallback, useEffect, useState } from "react";
import MainContainer from "../../Common/MainContainer";
import Loading from "../../Common/Loading";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { useAuth } from "../../../Contexts/useAuth";
import { NavLink, useParams } from "react-router-dom";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import Lots from "../Lots/Lots";
import LotsEnvoyerEmail from "../Emails/LotsEnvoyerEmail";
import ProgrammeImage from "../../Common/ProgrammeImage";
import TabPanel from "../TabPanel";
import ProgrammeMedias from "../Medias/ProgrammeMedias";
import ActualitesProgramme from "../Actualites/ActualitesProgramme";
import CalendrierProgramme from "../Calendrier/CalendrierProgramme";
import StatsEspaceClientActivation from "../Stats/StatsEspaceClientActivation";
import DocumentsProgramme from "../Documents/DocumentsProgramme";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Maquette from "../Maquette/Maquette";
import StatsLots from "../Stats/StatsLots";
import StatsDocumentsAttente from "../Stats/StatsDocumentsAttente";
import { useTheme } from "@emotion/react";
import DerniereActualite from "../Actualites/DerniereActualite";
import DemandesDocuments from "../Documents/DemandesDocuments";
import { fetchApiConfigurateur } from "../../../Api/Api_configurateur";
import ModalDeposerDocumentClient from "../Modal/ModalDeposerDocumentClient";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Programme = () => {
  const { state: stateAuth } = useAuth();

  const theme = useTheme();

  const { id } = useParams();
  const [programme, setProgramme] = useState({});
  const [lots, setLots] = useState([]);
  const [demandesDocuments, setDemandesDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lotsSelected, setLotsSelected] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const dataProgramme = await fetchApiImmodesk(
      `/private/programmes/${id}`,
      {},
      stateAuth.token
    );

    const dataProgrammeContenus = await fetchApiImmodesk(
      `/private/programmes/contenus/${id}`,
      {},
      stateAuth.token
    );

    const dataLots = await fetchApiImmodesk(
      `/private/lots/?programme_id=${id}&limit=-1`,
      {},
      stateAuth.token
    );

    const dataTransactions = await fetchApiImmodesk(
      `/private/transactions?programme_id=${id}&etat=4&type_id=2,3,4,5,6,7,8&limit=-1&signature_procedure_status=null,0,10,20,30,40,45,50,52,60,65`,
      {},
      stateAuth.token
    );

    const dataDemandesDocuments = await fetchApiImmodesk(
      `/private/documents/demandes/programmes/${id}?signature_procedure_statut=0,10,20,30,40,45,50,52,60,65&transaction_statut=4&transaction_type=2,3,4,5,6,7,8&doc_done=0`,
      {},
      stateAuth.token
    );

    const dataProgrammeConfigurateur = await fetchApiConfigurateur(
      `/private/programmes/${id}`,
      {},
      stateAuth.token
    );

    const rewriteLots = [];
    dataLots.data.forEach(lot => {
      let transaction = dataTransactions.data.find(t => t.lot_id === lot.id);
      let coClient = transaction?.coclient_id ? true : false;
      let coAcq = transaction?.coacquereur_id ? true : false;
      lot.transaction = transaction;
      rewriteLots.push(lot);
      if (coClient) {
        let transactionCopy = { ...transaction };
        let lotCopy = { ...lot };
        transactionCopy.is_co_client = true;
        transactionCopy.client_id = transaction.coclient_id;
        transactionCopy.client_civilite = transaction.coclient_civilite;
        transactionCopy.client_nom = transaction.coclient_nom;
        transactionCopy.client_prenom = transaction.coclient_prenom;
        transactionCopy.client_tel = transaction.coclient_tel;
        transactionCopy.client_email = transaction.coclient_email;
        transactionCopy.client_statut_activation =
          transaction.coclient_statut_activation;
        lotCopy.transaction = transactionCopy;
        rewriteLots.push(lotCopy);
      }
      if (coAcq) {
        let transactionCopy = { ...transaction };
        let lotCopy = { ...lot };
        transactionCopy.is_co_acquereur = true;
        transactionCopy.client_id = transaction.coacquereur_id;
        transactionCopy.client_civilite = transaction.coacquereur_civilite;
        transactionCopy.client_nom = transaction.coacquereur_nom;
        transactionCopy.client_prenom = transaction.coacquereur_prenom;
        transactionCopy.client_tel = transaction.coacquereur_tel;
        transactionCopy.client_email = transaction.coacquereur_email;
        transactionCopy.client_statut_activation =
          transaction.coacquereur_statut_activation;
        lotCopy.transaction = transactionCopy;
        rewriteLots.push(lotCopy);
      }
    });

    dataProgramme.contenus = dataProgrammeContenus;
    dataProgramme.configurateurDatas = dataProgrammeConfigurateur;
    setProgramme(dataProgramme);
    setLots(rewriteLots);
    setDemandesDocuments(dataDemandesDocuments);
    setIsLoading(false);
  }, [id, stateAuth]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const lotsSelectedChange = lots => {
    setLotsSelected(lots);
  };

  const updateLot = async (type, data) => {
    if (type && type === "update-client-activation") {
      updateDemandActivationByClientId(data);
    } else if (type && type === "add-transaction") {
      await addTransactionLot(data);
    } else if (type && type === "delete-transaction") {
      await deleteTransaction(data);
    } else if (type && type === "update-client") {
      updateClient(data);
    }
    return;
  };

  const updateDemandActivationByClientId = ({ clientId, data }) => {
    const newLots = [...lots];

    newLots.forEach(lot => {
      if (lot.transaction && lot.transaction.client_id === clientId) {
        lot.client_statut_activation = data.statut_activation;
      }
    });

    setLots(newLots);
  };

  const addTransactionLot = async transaction => {
    const newLots = [...lots];
    const lot = newLots.find(l => l.id && l.id === transaction.lot_id);

    if (!lot) {
      return;
    }

    lot.statut_id = transaction.type_id;
    lot.statut_label = transaction.type_label;
    lot.transaction = transaction;
    lot.client_statut_activation = await getClientActivation(
      transaction.client_id
    );

    setLots(newLots);
  };

  const getClientActivation = async clientId => {
    const response = await fetchApiImmodesk(
      `/private/contacts/${clientId}/activation`,
      {},
      stateAuth.token
    );

    if (!response) {
      // TODO error
      return;
    }

    return response;
  };

  const deleteTransaction = async transaction => {
    const newLots = [...lots];
    const lotIndex = newLots.findIndex(l => l.id === transaction.lot_id);

    if (lotIndex === -1) {
      return;
    }

    const lot = await fetchApiImmodesk(
      `/private/lots/${transaction.lot_id}`,
      {},
      stateAuth.token
    );

    newLots[lotIndex] = lot;
    newLots[lotIndex].statut_id = lot.statut;
    setLots(newLots);
  };

  const updateClient = data => {
    const newLots = [...lots];

    newLots.forEach(lot => {
      if (!lot.transaction || lot.transaction.client_id !== data.id) {
        return;
      }
      lot.transaction.client_nom = data.nom;
      lot.transaction.client_prenom = data.prenom;
    });

    setLots(newLots);
  };

  const sx = {
    boxTitle: {
      marginBottom: "10px",
      color: theme.palette.grey["700"],
    },
    boxWithBorder: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.grey["400"],
      padding: "20px",
    },
  };

  const clientIdSelected = lotsSelected.reduce((acc, lot) => {
    if (!lot.transaction) {
      return acc;
    }
    if (!acc.includes(lot.transaction?.client_id)) {
      acc.push(lot.transaction.client_id);
    }
    if (
      lot.transaction?.coclient_id &&
      !acc.includes(lot.transaction?.coclient_id)
    ) {
      acc.push(lot.transaction.coclient_id);
    }
    if (
      lot.transaction?.coacquereur_id &&
      !acc.includes(lot.transaction.coacquereur_id)
    ) {
      acc.push(lot.transaction.coacquereur_id);
    }
    return acc;
  }, []);

  return (
    <MainContainer>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <NavLink to={`/clients`}>
            <Button
              variant="contained"
              sx={{ paddingLeft: "8px" }}
              color="secondary"
            >
              <ArrowBackIosNewIcon sx={{ height: "20px" }} /> Mes programmes
            </Button>
          </NavLink>

          <Typography
            variant="h3"
            sx={{ marginBottom: "40px", marginTop: "20px" }}
          >
            {programme.nom}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              alignItems: ["center", "flex-start"],
            }}
          >
            <ProgrammeImage imageUrl={programme.media_thumb} />
            <Box
              sx={{
                width: ["100%"],
                display: "grid",
                flex: 1,
                gap: ["40px", "20px", "20px", "20px", "40px"],
                gridTemplateColumns: [
                  "1fr",
                  "1fr",
                  "1fr",
                  "1fr",
                  "repeat(3, 1fr)",
                ],
                marginLeft: ["0", "40px"],
                marginTop: ["20px", "unset"],
              }}
            >
              <Box sx={sx.boxWithBorder}>
                <Typography variant="h6" sx={sx.boxTitle}>
                  Lots
                </Typography>
                <StatsLots lots={lots} />
              </Box>

              <Box sx={sx.boxWithBorder}>
                <Typography variant="h6" sx={sx.boxTitle}>
                  Activation
                </Typography>
                <StatsEspaceClientActivation lots={lots} />
              </Box>

              <Box sx={sx.boxWithBorder}>
                <Typography variant="h6" sx={sx.boxTitle}>
                  Dernière actualité
                </Typography>
                <DerniereActualite programmeId={programme.id} categories="3" />
                <Typography
                  variant="h6"
                  sx={{ ...sx.boxTitle, marginTop: "6px", marginBottom: "6px" }}
                >
                  Documents
                </Typography>
                <StatsDocumentsAttente demandesDocuments={demandesDocuments} />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}
          >
            <Tabs
              value={tabValue}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              onChange={(event, newValue) => setTabValue(newValue)}
            >
              <Tab label="Lots" {...a11yProps(0)} />
              <Tab label="Documents programme" {...a11yProps(1)} />
              <Tab label="Actualités des travaux" {...a11yProps(2)} />
              <Tab label="Calendrier" {...a11yProps(3)} />
              <Tab label="Médias" {...a11yProps(4)} />
              <Tab label="Maquette 3D" {...a11yProps(5)} />
              <Tab
                label="Documents en attente de réception"
                {...a11yProps(6)}
              />
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "stretch",
                marginBottom: "20px",
                gap: "8px",
              }}
            >
              <ModalDeposerDocumentClient
                clientId={
                  clientIdSelected?.length > 0 ? clientIdSelected : false
                }
                useIcon={true}
              />
              <LotsEnvoyerEmail lots={lotsSelected} />
            </Box>
            <Lots
              lots={lots}
              programme={programme}
              callback={updateLot}
              lotsSelectedChange={lotsSelectedChange}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <DocumentsProgramme programme={programme} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <ActualitesProgramme programme={programme} categories="3" />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Box sx={{ marginTop: "20px" }}>
              <CalendrierProgramme programme={programme} />
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <ProgrammeMedias programmeId={programme.id} />
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <Maquette
              maquetteUrl={programme.contenus["maquette-iframe"]}
              programmeId={programme.id}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <DemandesDocuments programmeId={programme.id} />
          </TabPanel>
        </>
      )}
    </MainContainer>
  );
};

export default Programme;

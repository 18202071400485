import React, {useState} from 'react';
import {Box, Container} from "@mui/material";
import {styled} from '@mui/material/styles';
import {HEADER, NAVBAR} from '../../config';
import useCollapseDrawer from "../../Hooks/useCollapseDrawer";
import NavbarVertical from "../Common/Navbar/NavbarVertical";
import Header from "./Header/Header";
import useSettings from "../../Hooks/useSettings";

const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({collapseClick, theme}) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT + 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        }),
        ...(collapseClick && {
            marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        }),
    },
}));

const MainContainer = ({children}) => {
    const { themeStretch } = useSettings();
    const {collapseClick, isCollapse} = useCollapseDrawer();

    const [open, setOpen] = useState(false);

    return (
        <Box sx={{
            display: {lg: 'flex'},
            minHeight: {lg: 1}
        }}>

            <Header isCollapse={isCollapse}
                    onOpenSidebar={() => setOpen(true)}/>

            <NavbarVertical isOpenSidebar={open}
                            onCloseSidebar={() => setOpen(false)}/>

            <MainStyle collapseClick={collapseClick}>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    {children}
                </Container>
            </MainStyle>
        </Box>
    );
};

export default MainContainer;

import React, {useCallback, useEffect, useState} from 'react';
import {Box, TextField} from "@mui/material";
import {DatePicker} from "@mui/lab";
import {fetchApiConfigurateur} from "../../../Api/Api_configurateur";
import {useAuth} from "../../../Contexts/useAuth";
import DisplayDateCalendarFormat from "../DisplayDateCalendarFormat";

const ProgrammeDateChoix = ({programme, callback}) => {
    const {state: stateAuth} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [beginningAt, setBeginningAt] = useState(programme.beginningAt);
    const [endAt, setEndAt] = useState(programme.endAt);

    const updateProgramme = useCallback(async () => {
        const newProgramme = {...programme, beginningAt, endAt};

        setIsLoading(true);

        const updateProgramme = await fetchApiConfigurateur(`/private/programmes/${programme.id}`, {
            'method': 'PUT',
            body: JSON.stringify(newProgramme)
        }, stateAuth.token);

        setIsLoading(false)

        if(typeof callback === "function") {
            callback(updateProgramme);
        }

    }, [stateAuth, beginningAt, endAt, programme, callback]);

    useEffect(() => {
      if(programme && (programme.beginningAt !== beginningAt || programme.endAt !== endAt)) {
        updateProgramme();
      }
        // eslint-disable-next-line
    }, [beginningAt, endAt])

    return (
        <Box sx={{display: 'flex', alignItems: 'center', paddingTop: '5px'}}>
            <Box sx={{marginRight: '40px'}}>
                {endAt &&
                    <DisplayDateCalendarFormat date={endAt} />
                }
            </Box>
            <Box sx={{width: '150px', display: 'flex', flexDirection: 'column'}}>
                <Box>
                    <DatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Ouverture des choix"
                        value={beginningAt}
                        inputFormat="dd/MM/yyyy"
                        maxDate={endAt}
                        disabled={isLoading}
                        onChange={(newValue) => {
                            setBeginningAt(newValue);
                        }}
                    />
                </Box>

                <Box sx={{marginTop: '1.5rem'}}>
                    <DatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Cloture des choix"
                        value={endAt}
                        inputFormat="dd/MM/yyyy"
                        minDate={beginningAt}
                        disabled={isLoading}
                        onChange={(newValue) => {
                            setEndAt( newValue)
                        }}
                    />
                </Box>
            </Box>

        </Box>
    );
};

export default ProgrammeDateChoix;

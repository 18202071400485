import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ShowDate } from "../../Common/showDate";
import ActionsCalendrier from "./ActionsCalendrier";

const TableActualites = ({ evenements, programme, callback }) => {
  return (
    <TableContainer>
      <Table aria-label="sticky table" size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "150px" }}>Date</TableCell>
            <TableCell>Titre</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {evenements &&
            evenements.map((evenement, index) => (
              <TableRow hover key={index}>
                <TableCell>
                  {evenement.date && <ShowDate date={evenement.date.date} />}
                </TableCell>
                <TableCell>{evenement.titre}</TableCell>
                <TableCell sx={{ width: "120px" }}>
                  <ActionsCalendrier
                    evenement={evenement}
                    programme={programme}
                    callback={callback}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableActualites;

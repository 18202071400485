import React, {useEffect, useState} from 'react';
import {Button, Menu, MenuItem} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {useAuth} from "../../../Contexts/useAuth";
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";

const ActionsDocumentsClientDisponible = ({document, callback}) => {
    const {state: stateAuth} = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState();
    const [messageModal, setMessageModal] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

    const openMenu = Boolean(anchorEl);

    useEffect(() => {
        if (!action) {
            return;
        }

        const {type} = action;

        switch (type) {
            case 'supprimer-document':
                setMessageModal(`Supprimer le document "${document.nom}"`);
                break;
            default:
                setMessageModal('');
        }
    }, [action, document.nom])

    const handleMenuItem = (action) => {
        setAction(action);
        setAnchorEl(null);

        if(action.modal === "modal-confirmation") {
            setOpenModalConfirmation(true);
        }
    }

    const handleConfirmationModal = async () => {
        const {type} = action;

        switch (type){
            case 'supprimer-document':
                supprimerDocument();
                break;
            default:
                return;
        }
    }

    const supprimerDocument = async () => {
        setIsLoading(true);

        const dataDeleteDocument = await fetchApiImmodesk(`/private/documents/${document.id}`,
            {
                method: 'DELETE',
            },
            stateAuth.token
        );

        setIsLoading(false);

        if(dataDeleteDocument.error){
            // TODO error
            return;
        }

        callback();
    }

    const sx = {
        menuItem: {
            fontSize: '0.8rem',
            color: "#000",
            "a": {
                textDecoration: "none",
                fontSize: '0.8rem',
                color: "#000",
            }
        }
    }

    return (
        <>
            <Button
                id="actions-documents-client-disponible--button"
                aria-controls={openMenu ? 'actions-documents-client-demande-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <MoreHorizIcon sx={{transform: 'rotate(90deg)'}}/>
            </Button>
            <Menu
                id="actions-documents-client-disponible-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem sx={sx.menuItem}>
                    <a href={document.url}
                       target="_blank"
                       rel="noreferrer">Voir le document</a>
                </MenuItem>
                <MenuItem onClick={() => handleMenuItem({type: "supprimer-document", modal: "modal-confirmation"})} sx={sx.menuItem}>Supprimer le document</MenuItem>
            </Menu>

            <ModalConfirmation openModal={openModalConfirmation}
                               handleConfirm={handleConfirmationModal}
                               handleClose={() => setOpenModalConfirmation(false)}
                               loading={isLoading}
                               message={messageModal}/>

        </>
    );
};

export default ActionsDocumentsClientDisponible;

import { Button, Typography } from '@mui/material';
import React from 'react';
import FormProfil from './FormProfil';
import MainContainer from '../MainContainer';
import { ArrowBackIosNew } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const MesInformations = () => {
  const navigate = useNavigate();

  return (
    <MainContainer>
      <Button variant="contained"
              onClick={() => navigate(-1)}
              sx={{ paddingLeft: "8px", marginBottom: "20px" }}
              color="secondary">
                <ArrowBackIosNew sx={{ height: "20px" }}/> Retour
      </Button>
      <Typography  variant="h4">Mes informations</Typography>
      <FormProfil />
    </MainContainer>
  );
};

export default MesInformations;

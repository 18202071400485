import React, {useCallback, useEffect, useState} from 'react';
import {useAuth} from "../../../Contexts/useAuth";
import {fetchApiConfigurateur} from "../../../Api/Api_configurateur";
import Loading from "../../Common/Loading";
import {Box, Button, Typography} from "@mui/material";
import CardPersonne from "../CardPersonne";
import CircularProgress from "@mui/material/CircularProgress";

const SignatureChoixTma = ({configurationId, type, callback}) => {
    const {state: stateAuth} = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(false);
    const [configuration, setConfiguration] = useState(null);

    const checkClient = useCallback((client) => {
        const isValidClient = checkIsValid(client)
        setBtnSubmitDisabled(!isValidClient);
    }, []);

    const getConfiguration = useCallback(async () => {
        setIsLoading(true);

        const dataConfiguration = await fetchApiConfigurateur(`/private/configurations/${configurationId}`, {}, stateAuth.token);

        setIsLoading(false);

        if (!dataConfiguration) {
            // TODO error
            return;
        }

        setConfiguration(dataConfiguration);
        checkClient(dataConfiguration.infos.client);
    }, [configurationId, checkClient, stateAuth]);

    useEffect(() => {
        getConfiguration();
        // eslint-disable-next-line
    }, [])

    const checkIsValid = (personne) => {
        return personne &&
            personne.nom &&
            personne.nom !== null &&
            personne.prenom &&
            personne.prenom !== null &&
            personne.tel_mobile &&
            personne.tel_mobile !== null &&
            personne.email &&
            personne.email !== null;
    }

    const getDocumentsByType = () => {
        const documentsTmp = [];

        if (!type) {
            return documentsTmp;
        }

        switch (type) {
            case 'tma':
                const docTmaA = configuration.documents && configuration.documents.ficheTmaArchitecte ? configuration.documents.ficheTmaArchitecte : null;
                if (docTmaA) {
                    documentsTmp.push(docTmaA);
                }
                return documentsTmp;
            case 'choix':
                const docChoix = configuration.documents && configuration.documents.ficheChoixUrl ? configuration.documents.ficheChoixUrl : null;
                if (docChoix) {
                    documentsTmp.push(docChoix);
                }
                return documentsTmp;
            default:
                return documentsTmp;
        }
    }

    const handleSubmit = async () => {
        const documentsUrl = getDocumentsByType();
        const client = configuration.infos.client;

        const bodySignature = {
            document_url: documentsUrl,
            members: [
                {
                    nom: client.nom,
                    prenom: client.prenom,
                    email: client.email,
                    title: "client",
                    tel_mobile: client.tel_mobile,
                    position: 1,
                    position_signature: "480,60,580,100",
                }
            ]
        }

        setIsLoadingSubmit(true);

        const dataSignature = await fetchApiConfigurateur(`/protected/clients/configurations/${configurationId}/signatures`,
            {
                method: 'POST',
                body: JSON.stringify(bodySignature),
            },
            stateAuth.token);

        setIsLoadingSubmit(false);

        if (!dataSignature) {
            // TODO error
            return;
        }

        if (dataSignature.message) {
            // TODO gestion message
            return;
        }

        if (typeof callback === 'function') {
            callback();
        }
    }


    return (
        <Box>
            {isLoading && <Loading/>}
            {!isLoading && configuration &&
                <>
                    <Typography sx={{marginBlock: "30px"}}>Lancer la procédure de signature pour le lot {configuration.infos.lot.nom}</Typography>

                    <Box sx={{marginTop: "15px"}}>

                        {configuration.infos && configuration.infos.client &&
                            <CardPersonne label="Client"
                                          personne={configuration.infos.client}
                                          csx={{marginBottom: "20px"}}/>
                        }
                    </Box>

                    <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                        <Button variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={isLoadingSubmit || btnSubmitDisabled}>
                            {isLoadingSubmit &&
                                <CircularProgress size="20px"
                                                  color="secondary"
                                                  sx={{marginRight: '10px'}}/>}
                            <Box component="span">Valider</Box>
                        </Button>
                    </Box>
                </>
            }

        </Box>
    );
};

export default SignatureChoixTma;

import React, { useCallback, useEffect, useState } from "react";
import Loading from "../../Common/Loading";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import MainContainer from "../../Common/MainContainer";
import { useAuth } from "../../../Contexts/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import FormClient from "../Forms/FormClient";
import TabPanel from "../TabPanel";
import DocumentsClientDemande from "../Documents/DocumentsClientDemande";
import DocumentsClientDisponible from "../Documents/DocumentsClientDisponible";
import EspaceClient from "../Espace-client/EspaceClient";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import RendezVousClient from "../RendezVous/RendezVousClient.js";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Client = () => {
  const { state: stateAuth } = useAuth();

  const navigate = useNavigate();

  const { id } = useParams();
  const [client, setClient] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const dataClient = await fetchApiImmodesk(
      `/private/contacts/${id}`,
      {},
      stateAuth.token
    );

    setIsLoading(false);

    if (dataClient.error) {
      // TODO error
      return;
    }

    setClient(dataClient);
  }, [stateAuth.token, id]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const updateClient = (client) => {
    setClient({ ...client });
  };

  const updateClientActivation = (dataActivation) => {
    const { date_demande_activation, date_desactivation, statut_activation } =
      dataActivation;
    const newClient = {
      ...client,
      date_demande_activation,
      date_desactivation,
      statut_activation,
    };
    setClient(newClient);
  };

  return (
    <MainContainer>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{ paddingLeft: "8px" }}
            color="secondary"
          >
            <ArrowBackIosNew sx={{ height: "20px" }} /> Retour
          </Button>

          <Typography
            variant="h2"
            sx={{ marginBottom: "40px", marginTop: "20px" }}
          >
            {client?.prenom} {client?.nom}
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              onChange={(event, newValue) => setTabValue(newValue)}
            >
              <Tab label="Identité" {...a11yProps(0)} />
              <Tab
                label="Documents en attente de réception"
                {...a11yProps(1)}
              />
              <Tab label="Documents disponibles" {...a11yProps(2)} />
              <Tab label="Activation" {...a11yProps(3)} />
              <Tab label="Rendez-vous" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <FormClient initClient={client} callback={updateClient} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <DocumentsClientDemande client={client} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <DocumentsClientDisponible client={client} />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <EspaceClient client={client} callback={updateClientActivation} />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <RendezVousClient client={client} />
          </TabPanel>
        </>
      )}
    </MainContainer>
  );
};

export default Client;

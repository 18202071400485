import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import ModalComponent from "./ModalComponent";
import CircularProgress from "@mui/material/CircularProgress";

const ModalConfirmation = ({openModal, handleClose, handleConfirm, message, loading}) => {

    return (
       <ModalComponent openModal={openModal} handleClose={handleClose} hideCloseIcon={true}>
           <>
                <Typography variant="h6" component="h2">
                    Confirmation
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {message}
                </Typography>
                <Box sx={{marginTop: '30px', paddingTop: '20px', display: 'flex', justifyContent: 'flex-end', borderTop: '1px solid #EEE'}}>
                    <Button variant="outlined" onClick={handleClose} sx={{marginRight: '20px'}}>Non</Button>
                    <Button variant="contained" onClick={handleConfirm} disabled={loading}>{loading &&
                        <CircularProgress size="20px"
                                          color="secondary"
                                          sx={{marginRight: '10px'}}/>}
                        <Box component="span">Oui</Box></Button>
                </Box>
           </>
       </ModalComponent>
    );
};

export default ModalConfirmation;

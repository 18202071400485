import React, { useCallback, useEffect, useState } from "react";
import { ShowDate } from "../../Common/showDate";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import { useAuth } from "../../../Contexts/useAuth";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ModalActiverEspaceClient from "../Modal/ModalActiverEspaceClient";
import Loading from "../../Common/Loading";
import StatutActivation from "./StatutActivation";
import ModalDesactiverEspaceClient from "../Modal/ModalDesactiverEspaceClient";
import ModalReactiverEspaceClient from "../Modal/ModalReactiverEspaceClient";

const EspaceClient = ({ client, callback }) => {
  const { state: stateAuth } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [activerEspaceClient, setActiverEspaceClient] = useState(false);
  const [urlEspaceClient, setUrlEspaceClient] = useState();

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);

    const dataTransactions = await fetchApiImmodesk(
      `/private/transactions/?client_id=${client.id}&etat=4&limit=-1&signature_procedure_status=null,-20,0,10,20,30,35,40,45,50,60`,
      {},
      stateAuth.token
    );

    setIsLoading(false);

    if (dataTransactions.error) {
      // TODO error
      return;
    }

    let activerEspace = false;

    dataTransactions.data.forEach((transaction) => {
      if (transaction.type_id >= 2) {
        activerEspace = true;
      }
    });

    if (activerEspace) {
      setActiverEspaceClient(true);
    }
  }, [client, stateAuth]);

  const fetchUrlEspaceClient = useCallback(async () => {
    setIsLoading(true);

    const { token } = await fetchApiImmodesk(
      `/private/contacts/${client.id}/token`,
      {},
      stateAuth.token
    );

    setIsLoading(false);

    if (!token) {
      // TODO error
      return;
    }

    setUrlEspaceClient(
      `${stateAuth.configsCommercialisateur.userspace_url}?token=${token}`
    );
  }, [client, stateAuth]);

  useEffect(() => {
    if (!client.statut_activation) {
      fetchTransactions();
      return;
    }

    const useUserspace =
      stateAuth.configsCommercialisateur &&
      stateAuth.configsCommercialisateur.use_userspace &&
      stateAuth.configsCommercialisateur.userspace_url;

    if (useUserspace) {
      fetchUrlEspaceClient();
    }
    // eslint-disable-next-line
  }, [stateAuth, client]);

  return (
    <Box>
      {!activerEspaceClient && !client.statut_activation && <Loading />}

      {activerEspaceClient && !client.statut_activation && (
        <ModalActiverEspaceClient
          client={client}
          message={`Souhaitez-vous envoyer le lien d'activation par mail au client ?`}
          callback={callback}
        />
      )}

      {client.statut_activation &&
        (client.statut_activation === "ACTIVATION" ||
          client.statut_activation === "DEMANDE_ACTIVATION") && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: ["column", "row"],
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  marginBottom: ["20px", "0"],
                  justifyContent: ["space-between", "flex-start"],
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  target="_blank"
                  disabled={isLoading}
                  href={urlEspaceClient}
                >
                  Accès direct
                </Button>

                {client.statut_activation === "ACTIVATION" && (
                  <Box sx={{ marginLeft: ["0px", "20px"] }}>
                    <ModalDesactiverEspaceClient
                      clientId={client.id}
                      callback={callback}
                    />
                  </Box>
                )}
              </Box>

              <ModalActiverEspaceClient
                client={client}
                label="Renvoyer les codes"
                callback={callback}
              />
            </Box>
          </>
        )}

      {client.statut_activation &&
        client.statut_activation === "DESACTIVER" && (
          <>
            <ModalReactiverEspaceClient
              clientId={client.id}
              callback={callback}
            />
          </>
        )}

      {client.statut_activation && (
        <Box sx={{ marginTop: "20px" }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Statut</TableCell>
                  <TableCell>Date d'activation promoteur</TableCell>
                  <TableCell>Date d'activation client</TableCell>
                  <TableCell>Date désactivation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <StatutActivation statut={client.statut_activation} />
                  </TableCell>
                  <TableCell>
                    {client &&
                      client.date_demande_activation &&
                      client.date_demande_activation.date && (
                        <ShowDate date={client.date_demande_activation.date} />
                      )}
                  </TableCell>
                  <TableCell>
                    {client &&
                      client.date_activation &&
                      client.date_activation.date && (
                        <ShowDate date={client.date_activation.date} />
                      )}
                  </TableCell>
                  <TableCell>
                    {client &&
                      client.date_desactivation &&
                      client.date_desactivation.date && (
                        <ShowDate date={client.date_desactivation.date} />
                      )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default EspaceClient;

// @mui
import {styled} from '@mui/material/styles';
import {List, Box, ListSubheader} from '@mui/material';
//
import {NavListRoot} from './NavList';
import {useAuth} from "../../../Contexts/useAuth";

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
    ({theme}) => ({
        ...theme.typography.overline,
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        color: theme.palette.text.primary,
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shorter,
        }),
    })
);

export default function NavSection({navConfig, isCollapse = false, ...other}) {
    const {state: stateAuth} = useAuth();

    const renderListByAuth = (group, index) => {
        const {auth} = group;
        switch (auth) {
            case 'clients':
                if(!stateAuth.configsCommercialisateur || !stateAuth.configsCommercialisateur.use_userspace) return
                return renderList(group, index);
            case 'configurateurs':
                if(!stateAuth.configsCommercialisateur || !stateAuth.configsCommercialisateur.use_configurateur) return
                return renderList(group, index);
            default:
                return renderList(group, index);
        }
    }

    const renderList = (group, index) => {
        return <List key={index} disablePadding sx={{px: 2}}>
            <ListSubheaderStyle
                sx={{
                    ...(isCollapse && {opacity: 0}),
                }}
            >
                {group.subheader}
            </ListSubheaderStyle>

            {group.items.map((list, i) => (
                <NavListRoot key={index + i} list={list} isCollapse={isCollapse}/>
            ))}
        </List>
    }

    return (
        <Box {...other}>
            {navConfig.map((group, index) => renderListByAuth(group, index))}
        </Box>
    );
}

import React, {useState} from 'react';
import FormActualiteProgramme from "../Forms/FormActualiteProgramme";
import ModalComponent from "../../Common/Modal/ModalComponent";
import {Button} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

export function BtnEditActualite({actualite, programme, callback}) {
    const [openModal, setOpenModal] = useState(false);

    const updateActualite = (actu) => {
        setOpenModal(false);

        if(typeof callback === 'function') {
            callback("update", actu);
        }
    }

    return (
        <>
            <Button variant="outlined"
                    size="small"
                    sx={{minWidth: 'unset'}}
                    onClick={() => setOpenModal(true)}>
                <EditIcon fontSize="small"/>
            </Button>
            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}>
                    <FormActualiteProgramme programme={programme}
                                            initActualite={actualite}
                                            callback={updateActualite}/>
            </ModalComponent>
        </>
    );
}

import React, {useState} from 'react';
import {Button, Modal, Box, Tooltip} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {useTheme} from "@mui/material/styles";
import AssignmentIcon from '@mui/icons-material/Assignment';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import EmbedDocumentPdf from "./EmbedDocumentPdf";

const ViewDocument = ({url, type, tooltipTitle, iconDoc}) => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);


    const sx = {
        modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: ['100%', '90vw', '1200px'],
            maxWidth: ['100%', '90vw', '90vw'],
            backgroundColor: theme.palette.background.paper,
            boxShadow: 1,
            padding: '25px',
        },
        close: {
            position: 'absolute',
            top: '4px',
            right: '4px',
            opacity: '0.6',
            transition: 'opacity 200ms ease-out',
            '&:hover': {
                cursor: 'pointer',
                opacity: '0.8',
            }
        },
    };


    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const downloadFile = () => {
       window.open(url);
    }

    const getColor = () => {
        switch (type) {
            case 'tma-architecte':
                return theme.palette.success.main;
            default:
                return '';
        }
    }

    const getIcon = () => {
        const color = getColor();

        switch (iconDoc) {
            case 'notealt':
                return <NoteAltIcon sx={{color}} />
            default:
                return <AssignmentIcon sx={{color}} />
        }
    }

    return (
        <>
            <Button variant="text"
                    color="secondary"
                    sx={{padding: "5px", minWidth: "unset"}}
                    onClick={handleOpen}>
                {tooltipTitle &&
                    <Tooltip title={tooltipTitle} arrow>
                        {getIcon()}
                    </Tooltip>
                }
                {!tooltipTitle && getIcon()}
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box sx={sx.modal}>
                    <CloseIcon sx={sx.close} onClick={handleClose}/>
                    <EmbedDocumentPdf url={url} />
                    <Box sx={{textAlign: 'center', marginTop: '20px'}}>
                        <Button variant="contained" onClick={downloadFile}>
                            <DownloadIcon />Télécharger
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default ViewDocument;

import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    TextField
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import MediasCategories from "./MediasCategories";
import {mediasCategories} from "../../../const/const";

const AddMediaProgramme = ({programmeId, callback}) => {
    const {state: stateAuth} = useAuth();

    const categorieIdIllustration = 12;

    const [media, setMedia] = useState({
        categorie_id: categorieIdIllustration,
        is_public: true,
        titre: "",
        descriptif: "",
    });

    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        setCategories(mediasCategories)
    }, [])

    const handleChangeMediaCategory = (categoryId) => {
        const category = mediasCategories.find(m => m.id === categoryId);

        if (!category) {
            return;
        }

        const newMedia = {...media, categorie_id: category.id, categorie_label: category.label};
        setMedia(newMedia);
    }

    const handleChangeFile = (event) => {
        if(!event || !event.target.files || !event.target.files[0]) {
            return;
        };

        setFile(event.target.files[0]);
    }

    const handleSubmit = async () => {
        const formData = new FormData();

        formData.append('titre', media.titre);
        formData.append('descriptif', media.descriptif);
        formData.append('is_public', media.is_public);
        formData.append('categorie_id', media.categorie_id);
        formData.append('file', file, file.name);

        setIsLoading(true);
        const dataMedia = await fetchApiImmodesk(`/private/programmes/${programmeId}/medias`, {
            method: "POST",
            type: "file",
            body: formData
        }, stateAuth.token);
        setIsLoading(false);

        if (dataMedia.error) {
            // TODO error
            return;
        }

        if(typeof callback === 'function') {
            callback(dataMedia);
        }
    }

    return (
        <>
            {file &&
                <Box sx={{marginBottom: "20px", justifyContent: "center", display: "flex"}}>
                    <img id="target"
                         alt="preview"
                         src={URL.createObjectURL(file)}
                         style={{height: "200px"}}/>
                </Box>
            }
            <Box sx={{justifyContent: "center", display: "flex", marginBottom: "20px"}}>
                <label htmlFor="contained-button-file">
                    <input id="contained-button-file"
                           type="file"
                           accept=".jpeg,.jpg,.png"
                           onChange={handleChangeFile}
                           style={{display: "none"}}/>
                    <Button variant="contained"
                            color="secondary"
                            component="span">
                        Choisir une image
                    </Button>
                </label>
            </Box>

            <MediasCategories categories={categories}
                              initCategory={media.categorie_id}
                              callback={handleChangeMediaCategory}/>

            <TextField label="Texte pour le référencement"
                       size="small"
                       fullWidth
                       value={media.titre}
                       onChange={(e) => setMedia({...media, titre: e.target.value})}
                       variant="outlined"
                       sx={{marginTop: "20px"}}/>

            <TextField label="Description du media"
                       size="small"
                       fullWidth
                       sx={{marginTop: "20px"}}
                       value={media.descriptif}
                       onChange={(e) => setMedia({...media, descriptif: e.target.value})}
                       variant="outlined"/>

            <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                <Button variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isLoading || !file}>
                    {isLoading &&
                        <CircularProgress size="20px"
                                          color="secondary"
                                          sx={{marginRight: '10px'}}/>}
                    <Box component="span">Valider</Box>
                </Button>
            </Box>
        </>
    );
};

export default AddMediaProgramme;

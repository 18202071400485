import React, {useCallback, useEffect, useState} from 'react';
import {FormControlLabel, Switch, Typography} from "@mui/material";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";
import DeposerDocumentClientDemandeListe from "./DeposerDocumentClientDemandeListe";
import DeposerDocumentClientCategories from "./DeposerDocumentClientCategories";

const DeposerDocumentClient = ({clientId, callback}) => {
    const {state: stateAuth} = useAuth();

    const limitDocumentsDemandes = -1;

    const [isLoading, setIsLoading] = useState(true);
    const [isDocumentDemande, setIsDocumentDemande] = useState(false);
    const [documentsDemandes, setDocumentsDemandes] = useState([]);
    const [categories, setCategories] = useState([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        if(typeof clientId === 'number') {
            const dataDocuments = await fetchApiImmodesk(`/private/documents/demandes?contact_id=${clientId}&limit=${limitDocumentsDemandes}&done=0`, {}, stateAuth.token);
            if (dataDocuments.error) {
                // TODO error
                return;
            }
            setDocumentsDemandes(dataDocuments.data);
        }

        const dataCategories = await fetchApiImmodesk(`/private/configs/documents/categories/?check_exist=0&table_id=34`, {}, stateAuth.token);
        if (dataCategories.error) {
            // TODO error
            return;
        }
        setIsLoading(false);
        orderCategories(dataCategories);
    }, [clientId, stateAuth, limitDocumentsDemandes]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    const handleChangeIsDocumentDemande = (event) => {
        setIsDocumentDemande(event.target.checked);
    }

    // On veut Appel de fond, Contrat de réservation, Autres documents, puis le reste
    const orderCategories = (categories) => {
        const newCategories = [...categories];
        const categoriesLabel = ['Appel de fonds', 'Contrat de réservation', 'Autres documents'];

        categoriesLabel.forEach(categorieLabel => {
            const categorieIndex = categories.findIndex(c => c.label === categorieLabel);
            if(categorieIndex !== -1) {
                const cpCategorie = {...newCategories[categorieIndex]};
                newCategories.splice(categorieIndex, 1);
                newCategories.unshift(cpCategorie);
            }
        })

        setCategories(newCategories);
    }

    return (
        <>
            <Typography variant="h6"
                        component="h2"
                        sx={{marginBottom: "20px"}}>Déposer un document</Typography>

            {isLoading && <Loading/>}

            {!isLoading &&
                <>
                    {documentsDemandes && documentsDemandes.length > 0 &&
                        <FormControlLabel
                            checked={isDocumentDemande}
                            onChange={handleChangeIsDocumentDemande}
                            control={<Switch color="primary"/>}
                            label="Votre document concerne une demande de document ?"
                            labelPlacement="start"
                            sx={{marginLeft: 0, marginRight: 0, marginBottom: "40px", span: {fontSize: ".9rem"}}}
                        />
                    }

                    {isDocumentDemande &&
                        <DeposerDocumentClientDemandeListe documents={documentsDemandes}
                                                           clientId={clientId}
                                                           callback={callback}/>
                    }

                    {!isDocumentDemande &&
                        <DeposerDocumentClientCategories categories={categories}
                                                         clientId={clientId}
                                                         callback={callback}/>
                    }
                </>
            }
        </>
    );
};

export default DeposerDocumentClient;

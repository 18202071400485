import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";
import CircularProgress from "@mui/material/CircularProgress";
import {useForm, Controller} from "react-hook-form";
import {Alert} from "@mui/lab";

const FormClientTransaction = ({initClient, lot, callback}) => {
    const {state: stateAuth} = useAuth();

    const statutVenduId = 3;

    const [client, setClient] = useState({});
    const [lotPrix, setLotPrix] = useState([]);
    const [isLoadingPrix, setIsLoadingPrix] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {handleSubmit, register, formState: {errors}, control, reset} = useForm({});

    const fetchLotPrix = useCallback(async () => {
        setIsLoadingPrix(true);
        const data = await fetchApiImmodesk(`/private/lots/${lot.id}/prix`, {}, stateAuth.token);
        setLotPrix(data);
        setIsLoadingPrix(false);
    }, [lot, stateAuth]);

    useEffect(() => {
        if (!initClient) {
            setClient({});
            reset();
            return;
        }
        setClient({...initClient});
        reset();
    }, [initClient, reset])

    useEffect(() => {
        if (!lot) {
            return;
        }
        reset();
        fetchLotPrix();
        // eslint-disable-next-line
    }, [lot, reset])

    const onSubmit = async (dataForm) => {
        const dataPrix = lotPrix.find(p => p.id === dataForm.lotPrixId);

        if (!dataPrix) {
            return;
        }

        setIsLoading(true);

        const url = client?.id ? `/private/contacts/${client.id}` : `/private/contacts/`;
        const method = client?.id ? "PUT" : "POST";

        const dataClient = await fetchApiImmodesk(url, {
            method,
            body: JSON.stringify(client)
        }, stateAuth.token);

        if(dataClient.error) {
            // TODO error
            return;
        }

        const dataTransaction = await fetchApiImmodesk('/private/transactions', {
            method: 'POST',
            body: JSON.stringify({
                client_id: dataClient.id,
                lot_id: lot.id,
                prix_TTC: dataPrix.prix_ttc_raw,
                tva: dataPrix.tva,
                vendeur_id: stateAuth.profil.id,
                type_id: statutVenduId
            })
        }, stateAuth.token);

        if(dataTransaction.error) {
            // TODO error
            return;
        }

        if(typeof callback === 'function') {
            callback('add-transaction', dataTransaction);
        }

        setIsLoading(false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{display: "grid", gap: "15px", gridTemplateColumns: ["1fr", "1fr 1fr"]}}>

                <TextField
                    id="client-nom"
                    label="Nom"
                    name="nom"
                    required
                    value={client?.nom || ""}
                    {...register("client.nom", {
                        required: "Le nom est obligatoire.",
                    })}
                    InputLabelProps={{
                        shrink: client?.nom ? true : false
                    }}
                    onChange={(event) => setClient({...client, nom: event.target.value})}
                />

                <TextField
                    id="client-prenom"
                    label="Prénom"
                    name="prenom"
                    required
                    value={client?.prenom || ""}
                    {...register("client.prenom", {
                        required: "Le prénom est obligatoire.",
                    })}
                    InputLabelProps={{
                        shrink: client?.prenom ? true : false
                    }}
                    onChange={(event) => setClient({...client, prenom: event.target.value})}
                />

                <TextField
                    id="client-telephone"
                    label="Téléphone"
                    name="telephone"
                    value={client?.tel || ""}
                    {...register("client.tel", {})}
                    InputLabelProps={{
                        shrink: client?.tel ? true : false
                    }}
                    onChange={(event) => setClient({...client, tel: event.target.value})}
                />

                <TextField
                    id="client-email"
                    label="Adresse email"
                    name="email"
                    required
                    value={client?.email || ""}
                    {...register("client.email", {
                        required: "L'email est obligatoire.",
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Entrer une adresse email valide."
                        }
                    })}

                    InputLabelProps={{
                        shrink: client?.email ? true : false
                    }}
                    onChange={(event) => setClient({...client, email: event.target.value})}
                />
            </Box>
            <Typography variant="caption">* champ obligatoire</Typography>

            <Typography sx={{marginTop: "40px", marginBottom: "20px"}}>Choisir le prix</Typography>

            {isLoadingPrix && <Loading/>}
            {!isLoadingPrix &&
                <FormControl>
                    <Controller
                        rules={{ required: "Sélectionner un prix." }}
                        control={control}
                        name="lotPrixId"
                        render={({ field}) => (
                            <RadioGroup {...field}>
                                {lotPrix && lotPrix.map((d, index) => (
                                    <FormControlLabel key={d.id}
                                                      value={d.id}
                                                      control={<Radio/>}
                                                      label={d.label + " : " + d.prix_ttc}/>
                                ))}
                            </RadioGroup>
                        )}
                    />
                </FormControl>
            }

            {errors && Object.keys(errors).length > 0 &&
                <Box sx={{display: "grid", gap: "5px", gridTemplateColumns: "1fr", marginTop: "10px"}}>
                    {errors?.client?.nom && <Alert severity="warning">{errors.client.nom.message}</Alert>}
                    {errors?.client?.prenom && <Alert severity="warning">{errors.client.prenom.message}</Alert>}
                    {errors?.client?.email && <Alert severity="warning">{errors.client.email.message}</Alert>}
                    {errors?.lotPrixId && <Alert severity="warning">{errors.lotPrixId.message}</Alert>}
                </Box>
            }

            <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                <Button variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading}>
                    {isLoading &&
                        <CircularProgress size="20px"
                                          color="secondary"
                                          sx={{marginRight: '10px'}}/>}
                    <Box component="span">Valider</Box>
                </Button>
            </Box>

        </form>
    );
};

export default FormClientTransaction;

export const decodeToken = (token) => {
    if(!token) {
        return;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload)

}

export const tokenIsValid = (token) => {
    if(!token) {
        return;
    }
    const decToken = decodeToken(token);
    const currentTimestamp = new Date().getTime() / 1000;
    return decToken.exp > currentTimestamp;
}

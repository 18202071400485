import React, {useCallback, useEffect, useState} from 'react';
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";
import {Box, Grid} from "@mui/material";
import MediaCard from "./MediaCard";
import ModalAjouterMediaProgramme from "../Modal/ModalAjouterMediaProgramme";

const ProgrammeMedias = ({programmeId}) => {
    const {state: stateAuth} = useAuth();

    const [medias, setMedias] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        const dataMedias = await fetchApiImmodesk(`/private/programmes/${programmeId}/medias?type=2&categories=12,160`, {}, stateAuth.token);
        setMedias(dataMedias);
        setIsLoading(false);
    }, [programmeId, stateAuth])

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    const removeMedia = (mediaId) => {
      if(!mediaId) {
        return;
      }

      const newMedias = [...medias];
      const indexMedia = newMedias.findIndex(m => m.id === mediaId);

      if(indexMedia !== -1) {
        newMedias.splice(indexMedia, 1);
      }

      setMedias(newMedias);
    }

    const addMedia = (media) => {
        setMedias([...medias, media]);
    }

    return (
        <>
            <Box sx={{marginTop: "10px", marginBottom: "40px"}}>
                <ModalAjouterMediaProgramme programmeId={programmeId}
                                            callback={addMedia}/>
            </Box>

            {isLoading && <Loading/>}
            {!isLoading &&
                    <Grid container spacing={2}>
                        {medias.length > 0 && medias.map(media => (
                            <Grid item xs={12} md={6} lg={4} key={media.id}>
                                <MediaCard initMedia={media}
                                          programmeId={programmeId}
                                          callback={removeMedia}/>
                            </Grid>
                        ))}
                    </Grid>
            }
        </>
    );
};

export default ProgrammeMedias;

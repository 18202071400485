import React from 'react';

const StatutActivation = ({statut}) => {
    return (
        <>
            {statut && statut === 'INACTIF' &&
                <>Non activé</>
            }
            {statut && statut === 'DEMANDE_ACTIVATION' &&
                <>Activation promoteur</>
            }
            {statut && statut === 'ACTIVATION' &&
                <>Activation client</>
            }
            {statut && statut === 'DESACTIVER' &&
                <>Désactivé</>
            }
        </>
    );
};

export default StatutActivation;

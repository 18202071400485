import { Button, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import ModalComponent from "../../Common/Modal/ModalComponent.js";
import FromRendezVousClient from "../Forms/FormRendezVousClient.js";

const ModalAjouterRendezVous = ({ clientId, callback }) => {
  const [openModal, setOpenModal] = useState(false);

  const callbackModal = () => {
    setOpenModal(false);
    if (typeof callback === "function") {
      callback();
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setOpenModal(true)}
      >
        Ajouter un rendez-vous
      </Button>

      <ModalComponent
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
      >
        <Typography variant="h6" component="h2" sx={{ marginBottom: "20px" }}>
          Ajouter un rendez-vous
        </Typography>
        <FromRendezVousClient clientId={clientId} callback={callbackModal} />
      </ModalComponent>
    </>
  );
};

export default ModalAjouterRendezVous;

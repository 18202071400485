import React, {useCallback, useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import {fetchApiConfigurateur} from "../../../Api/Api_configurateur";
import Loading from "../../Common/Loading";
import ProgrammeCard from "./ProgrammeCard";
import {useAuth} from "../../../Contexts/useAuth";
import MainContainer from "../../Common/MainContainer";

const Programmes = () => {
    const {state: stateAuth} = useAuth();

    const [programmes, setProgrammes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchProgrammes = useCallback(async () => {
        setIsLoading(true);
        const data = await fetchApiConfigurateur('/private/programmes',{}, stateAuth.token);
        setProgrammes(data.rows);
        setIsLoading(false);
    }, [stateAuth]);

    useEffect(() => {
        fetchProgrammes()
        // eslint-disable-next-line
    }, []);

    const updateProgramme = (programme) => {
        const newProgrammes = [...programmes];
        const programmeIndex = newProgrammes.findIndex(p => p.id === programme.id);

        if(programmeIndex === -1) {
            return;
        }

        newProgrammes.splice(programmeIndex, 1, programme);

        setProgrammes(newProgrammes);
    }

    return (
        <MainContainer>
            <Typography variant="h3" sx={{marginBottom: '40px'}}>Mes programmes</Typography>
            {isLoading && <Loading/>}
            <Box sx={{ display: "grid", gridTemplateColumns: ["1fr"], gap: "20px" }}>
                {!isLoading && programmes && programmes.map(programme =>(
                    <ProgrammeCard programme={programme}
                                updateProgramme={updateProgramme}
                                key={programme.id}/>
                ))}
            </Box>
        </MainContainer>
    );
};

export default Programmes;

import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../Contexts/useAuth";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Loading from "../../Common/Loading";
import ActionsDocumentsClientDemande from "./ActionsDocumentsClientDemande";
import { ShowDate } from "../../Common/showDate";
import { Pagination } from "@mui/lab";
import ModalDemandeDocumentsClient from "../Modal/ModalDemandeDocumentsClient";
import ModalRelancerClientsDemandeDocumentsForm from "../Modal/ModalRelancerClientsDemandeDocumentsForm";

const DocumentsClientDemande = ({ client }) => {
  const { state: stateAuth } = useAuth();

  const limit = 10;

  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const dataDocuments = await fetchApiImmodesk(
      `/private/documents/demandes?contact_id=${client.id}&limit=${limit}&page=${page}&done=0&adv=true`,
      {},
      stateAuth.token
    );

    setIsLoading(false);

    if (dataDocuments.error) {
      // TODO error
      return;
    }

    const totalPages =
      dataDocuments.total / limit < 1
        ? 1
        : Math.ceil(dataDocuments.total / limit);
    setDocuments({ ...dataDocuments, totalPages });
  }, [client, limit, page, stateAuth]);

  useEffect(() => {
    if (!client) {
      return;
    }
    fetchData();
    // eslint-disable-next-line
  }, [client]);

  const handleChangePage = (event, p) => {
    setPage(p);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <ModalDemandeDocumentsClient client={client} callback={fetchData} />
        <ModalRelancerClientsDemandeDocumentsForm
          clientId={client.id}
          disabled={!documents || documents.total === 0}
        />
      </Box>
      <TableContainer>
        <Table aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Catégorie</TableCell>
              <TableCell sx={{ width: "80px" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={4} sx={{ paddingBlock: "40px" }}>
                  <Loading />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              documents &&
              documents.data &&
              documents.data.length === 0 && (
                <TableRow hover key={document.id}>
                  <TableCell colSpan={4} sx={{ paddingBlock: "20px" }}>
                    Aucun document
                  </TableCell>
                </TableRow>
              )}
            {!isLoading &&
              documents &&
              documents.data &&
              documents.data.length > 0 &&
              documents.data.map((document) => (
                <TableRow hover key={document.id}>
                  <TableCell>
                    <ShowDate date={document.date_creation.date} />
                  </TableCell>
                  <TableCell>{document.media_categorie_label}</TableCell>
                  <TableCell>
                    <ActionsDocumentsClientDemande
                      document={document}
                      clientId={client.id}
                      callback={fetchData}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ marginTop: "40px", textAlign: "center" }}>
        <Pagination
          count={documents.totalPages}
          variant="outlined"
          shape="rounded"
          page={page}
          disabled={isLoading}
          onChange={handleChangePage}
          sx={{ display: "inline-flex" }}
        />
      </Box>
    </>
  );
};

export default DocumentsClientDemande;

import React from 'react';
import {Box} from "@mui/material";

const BadgeLot = ({type}) => {

    const getColorByType = type => {
        if(!type) {
            return;
        }
        switch (type.toLowerCase()) {
            case 't1':
                return '#158dbc';
            case 't2':
                return '#9eabd3';
            case 't3':
                return '#be3362';
            case 't4':
                return '#78c1ba';
            case 't5':
                return '#a5ab45';
            case 't6':
                return '#e0781e';
            case 'villa':
                return '#142743';
            case 'villa_exception':
                return '#547582';
            default:
                return '#c67cd5'
        }
    }

    const sx = {
        box: {
            display: 'inline-flex',
            padding: '4px 8px',
            borderRadius: '4px',
            textTransform: 'uppercase',
            color: '#FFF',
            backgroundColor: getColorByType(type)
        }
    }

    return (
        <Box sx={sx.box}>
            {type}
        </Box>
    );
};

export default BadgeLot;

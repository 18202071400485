import React, {useState} from 'react';
import {Button} from "@mui/material";
import ModalComponent from "../../Common/Modal/ModalComponent";
import AjouterAcquereur from "../Acquereurs/AjouterAcquereur";

const ModalAjouterAcquereur = ({lot, callback}) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Button variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => setOpenModal(true)}>
                Ajouter un acquéreur
            </Button>

            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false) }>

            <AjouterAcquereur lot={lot} callback={callback}/>

            </ModalComponent>
        </>
    );
};

export default ModalAjouterAcquereur;

import React, { useCallback, useEffect, useState } from "react";
import { useNotifications } from "../../../Contexts/useNotifications";
import { Badge, Box } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { alpha } from "@mui/material/styles";
import IconButtonAnimate from "../Animate/IconButtonAnimate";
import MenuPopover from "../MenuPopover";
import NotificationsClients from "../../Clients/Notifications/NotificationsClients";
import { useAuth } from "../../../Contexts/useAuth";
import { fetchApiNotification } from "../../../Api/Api_notifications";
import ModalComponent from "../Modal/ModalComponent";
import Tchat from "../../Clients/Tchat/Tchat";
import useIot from "react-aws-iot";
import { useNavigate } from "react-router-dom";

const NotificationsPopover = () => {
  const { state: stateAuth } = useAuth();
  const { state: stateNotifications, dispatch: dispatchNotifications } =
    useNotifications();
  const { messageText: messageTextIot } = useIot(stateAuth.configAwsIot);
  const notificationsClients =
    stateNotifications.notificationsClients &&
    stateNotifications.notificationsClients.Items
      ? stateNotifications.notificationsClients.Items.filter(
          (n) =>
            n.isRead === 0 &&
            (n.ressource === "messages" ||
              n.ressource === "new-document-customer-available")
        )
      : [];
  const totalNotifications = notificationsClients.length;

  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [clientIdModal, setClientIdModal] = useState(null);
  const [titleModal, setTitleModal] = useState(null);

  const navigate = useNavigate();

  const addNotificationMessage = useCallback(
    (message) => {
      dispatchNotifications({
        type: "addNotificationClient",
        payload: message,
      });
    },
    [dispatchNotifications]
  );

  useEffect(() => {
    if (!messageTextIot) {
      return;
    }

    const message = JSON.parse(messageTextIot);

    if (
      message?.ressource === "messages" ||
      message?.ressource === "new-document-customer-available"
    ) {
      addNotificationMessage(message);
    }
  }, [messageTextIot, addNotificationMessage]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeNotificationClient = async (notification) => {
    const newNotification = await updateNotification(notification);

    if (!newNotification) {
      return;
    }

    dispatchNotifications({
      type: "updateNotificationClient",
      payload: newNotification,
    });
  };

  const handleClickNotificationClient = async (notification) => {
    if (!notification) {
      return;
    }
console.log('notification.ressource', notification.ressource);
    if (notification.ressource === "message" || notification.ressource === "messages") {
      openModalClient(notification);
    } else if (notification.ressource === "new-document-customer-available") {
      if (!notification.params || !notification.params.authorId) {
        return;
      }
      navigate(`/clients/contacts/${notification.params.authorId}`);
    }
  };

  const openModalClient = (notification) => {
    setClientIdModal(notification.params.authorId);
    setTitleModal(
      `${notification.params.authorNom} ${notification.params.authorPrenom}`
    );
    setOpenModal(true);
  };

  const updateNotification = async (notification) => {
    const newNotification = { ...notification, isRead: 1 };
    const dataNotification = await fetchApiNotification(
      `/messages/${newNotification.id}`,
      {
        method: "PUT",
        body: JSON.stringify(newNotification),
      },
      stateAuth.token
    );

    if (!dataNotification) {
      return;
    }

    return dataNotification;
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Badge badgeContent={totalNotifications} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          width: ["100%", "340px"],
          maxHeight: ["100%", "340px"],
          overflow: "auto",
          left: ["0", "16px"],
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        {notificationsClients && (
          <NotificationsClients
            notifications={notificationsClients}
            handleChangeCallback={handleChangeNotificationClient}
            handleClickCallback={handleClickNotificationClient}
          />
        )}
        {notificationsClients && notificationsClients.length === 0 && (
          <Box
            sx={{
              padding: "6px 16px",
              height: "50px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Aucune notification
          </Box>
        )}
      </MenuPopover>

      <ModalComponent
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
      >
        <Tchat clientId={clientIdModal} title={titleModal} />
      </ModalComponent>
    </>
  );
};

export default NotificationsPopover;

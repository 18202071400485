import React, {useEffect, useState} from 'react';
import {
    Alert, Box,
    Checkbox, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import BadgeLot from "../../Common/BadgeLot";
import ViewDocument from "../ViewDocument";
import ActionsLot from "./ActionsLot";
import SignatureConfiguration from "../Signatures/SignatureConfiguration";

const Lots = ({lots, programme, lotsSelectedChange, callback}) => {
    const [lotsSelected, setLotsSelected] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [messageAlerte, setMessageAlerte] = useState('');

    useEffect(() => {
        lotsSelectedChange(lotsSelected);
    }, [lotsSelectedChange, lotsSelected])

    const handleSelect = (e, lot) => {
        const selectedIndex = lotsSelected.findIndex(l => l.id === lot.id);
        let newLotsSelected = [];

        if (selectedIndex === -1) {
            newLotsSelected = newLotsSelected.concat(lotsSelected, lot);
        } else {
            newLotsSelected = [...lotsSelected];
            newLotsSelected.splice(selectedIndex, 1);
        }

        setLotsSelected(newLotsSelected);
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setLotsSelected([...lots]);
            return;
        }
        setLotsSelected([]);
    };

    const isSelected = (lot) => {
        if (!lot) {
            return false;
        }
        return lotsSelected.findIndex(l => l.id === lot.id) !== -1;
    }

    const snackbarError = (message) => {
        setOpenSnackBar(true);
        setMessageAlerte(message);
    }

    const getClient = (lot) => {
        if(!lot.configuration || !lot.configuration.infos || !lot.configuration.infos.client) {
            return '';
        }

        return `${lot.configuration.infos.client.nom.toUpperCase()} ${lot.configuration.infos.client.prenom}`;
    }

    return (
        <>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table aria-label="sticky table" size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: "80px"}}>
                                <Checkbox
                                    color="secondary"
                                    indeterminate={lotsSelected.length > 0 && lotsSelected.length < lots.length}
                                    checked={lots.length > 0 && lotsSelected.length === lots.length}
                                    onChange={handleSelectAll}
                                    inputProps={{'aria-label': 'select all desserts',}}
                                />
                            </TableCell>
                            <TableCell>Nom</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Fiche TMA</TableCell>
                            <TableCell>Fiche de choix</TableCell>
                            <TableCell>Fiche de pose</TableCell>
                            <TableCell sx={{width: "80px"}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lots && lots.map(lot => (
                            <TableRow hover key={lot.id}>
                                <TableCell>
                                    <Checkbox
                                        color="secondary"
                                        checked={isSelected(lot)}
                                        onChange={(e) => handleSelect(e, lot)}
                                        inputProps={{'aria-label': 'select all desserts',}}
                                    />
                                </TableCell>
                                <TableCell>
                                    {lot.name}
                                </TableCell>
                                <TableCell>
                                    {getClient(lot)}
                                </TableCell>
                                <TableCell>
                                    <BadgeLot type={lot.type}/>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        {lot.documents_tma && lot.documents_tma.ficheTmaUrl &&
                                            <ViewDocument url={lot.documents_tma.ficheTmaUrl}
                                                          tooltipTitle="Fiche TMA"/>
                                        }

                                        {lot.documents_tma && lot.documents_tma.ficheTmaArchitecte &&
                                            <Box sx={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                                <ViewDocument url={lot.documents_tma.ficheTmaArchitecte}
                                                              tooltipTitle="Fiche TMA modifiée"
                                                              type="tma-architecte"
                                                              iconDoc="notealt"/>
                                            </Box>
                                        }

                                        {lot.configuration_tma_configurations_signatures_sign_data &&
                                            lot.configuration_tma_configurations_signatures_sign_data.files &&
                                            lot.configuration_tma_configurations_signatures_sign_data.files[0] &&
                                            lot.configuration_tma_configurations_signatures_sign_data.files[0].id &&
                                            lot.configuration_tma_configurations_signatures_sign_data.members &&
                                            <Box sx={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                                <SignatureConfiguration procedure={lot.configuration_tma_configurations_signatures_sign_data}/>
                                            </Box>
                                        }
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        {lot.documents_deco && lot.documents_deco.ficheChoixUrl &&
                                            <ViewDocument url={lot.documents_deco.ficheChoixUrl}
                                                          tooltipTitle="Fiche de choix"/>
                                        }
                                        {lot.configuration_choix_configurations_signatures_sign_data &&
                                            lot.configuration_choix_configurations_signatures_sign_data.files &&
                                            lot.configuration_choix_configurations_signatures_sign_data.files[0] &&
                                            lot.configuration_choix_configurations_signatures_sign_data.files[0].id &&
                                            lot.configuration_choix_configurations_signatures_sign_data.members &&
                                            <Box sx={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                                <SignatureConfiguration procedure={lot.configuration_choix_configurations_signatures_sign_data}/>
                                            </Box>
                                        }

                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {lot.documents_deco && lot.documents_deco.fichePoseUrl &&
                                        <ViewDocument url={lot.documents_deco.fichePoseUrl}
                                                      tooltipTitle="Fiche de pose"/>
                                    }
                                </TableCell>
                                <TableCell>
                                    <ActionsLot lot={lot}
                                                programme={programme}
                                                callback={callback}
                                                error={snackbarError}/>
                                </TableCell>
                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Snackbar open={openSnackBar}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert onClose={() => setOpenSnackBar(false)}
                       severity="error"
                       sx={{width: '100%'}}>
                    {messageAlerte}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Lots;

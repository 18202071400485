export const fetchApiNotification = async (url, init = {}, token = false) => {

    let headers = new Headers();

    if (token) {
        headers = {Authorization:`Bearer ${token}`};
    }

    const response = await fetch(process.env.REACT_APP_API_NOTIFICATIONS + url, {...init, headers})

    if (!response.ok) {
        console.error(`fetchApi error ${url}: `, response);
        return false;
    }

    if(response.statusText === "No Content" || response.status===204) {
        return true;
    }

    return await response.json();

}

import React from 'react';
import {Box} from "@mui/material";

const TabPanel = ({children, value, index}) => {
    return (
        <Box role="tabpanel"
             hidden={value !== index}
             id={`tabpanel-${index}`}
             aria-labelledby={`stab-${index}`}
        >
            {value === index && (
                <Box sx={{py: 3}}>
                    {children}
                </Box>
            )}
        </Box>
    );
};

export default TabPanel;

import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    TextField
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import {Alert} from "@mui/lab";
import MediasCategories from "./MediasCategories";
import {mediasCategories} from "../../../const/const";

const MediaCard = ({initMedia, programmeId, callback}) => {
    const {state: stateAuth} = useAuth();

    const [media, setMedia] = useState(initMedia);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        setCategories(mediasCategories)
    }, [])

    const handleConfirmationModal = async () => {
        setIsLoadingDelete(true);
        const dataDelete = await fetchApiImmodesk(`/private/programmes/${programmeId}/medias/${media.id}`, {
            method: "DELETE"
        }, stateAuth.token);
        setIsLoadingDelete(false);

        if (!dataDelete || dataDelete.message) {
            // TODO error
            return;
        }

        setOpenModalConfirmation(false);

        if (typeof callback === 'function') {
            callback(media.id);
        }
    }

    const handleChangeMediaCategory = (categoryId) => {
        const category = mediasCategories.find(m => m.id === categoryId);

        if (!category) {
            return;
        }

        const newMedia = {...media, categorie_id: category.id, categorie_label: category.label};
        setMedia(newMedia);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const dataMedia = await fetchApiImmodesk(`/private/programmes/${programmeId}/medias/${media.id}`, {
            method: "PUT",
            body: JSON.stringify(media)
        }, stateAuth.token);
        setIsLoading(false);

        if (!dataMedia || dataMedia.message) {
            // TODO error
            return;
        }
    }

    return (
        <>
            <Card sx={{width: "4O0px"}}>
                <Box sx={{position: "relative"}}>
                    <CardMedia
                        component="img"
                        height="200"
                        image={media.url}
                    />
                </Box>

                <CardContent>
                    <MediasCategories categories={categories}
                                      initCategory={media.categorie_id}
                                      callback={handleChangeMediaCategory}/>
                    <TextField label="Texte pour le référencement"
                               size="small"
                               fullWidth
                               value={media.titre}
                               onChange={(e) => setMedia({...media, titre: e.target.value})}
                               variant="outlined"
                               sx={{marginTop: "20px"}}/>
                    <TextField label="Description du media"
                               size="small"
                               fullWidth
                               sx={{marginTop: "20px"}}
                               value={media.descriptif}
                               onChange={(e) => setMedia({...media, descriptif: e.target.value})}
                               variant="outlined"/>

                    {media.is_default_media &&
                        <Alert severity="warning" sx={{marginTop: "20px"}}>Vignette principale du programme</Alert>
                    }

                </CardContent>
                <CardActions sx={{padding: "20px", paddingTop: "0", justifyContent: "space-between"}}>
                    <Button variant="outlined"
                            disabled={media.is_default_media}
                            onClick={() => setOpenModalConfirmation(true)}
                            color="error">Supprimer
                    </Button>

                    <Button variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isLoading}>
                        {isLoading &&
                            <CircularProgress size="20px"
                                              color="secondary"
                                              sx={{marginRight: '10px'}}/>}
                        <Box component="span">Enregistrer</Box>
                    </Button>
                </CardActions>
            </Card>

            <ModalConfirmation openModal={openModalConfirmation}
                               handleConfirm={handleConfirmationModal}
                               handleClose={() => setOpenModalConfirmation(false)}
                               loading={isLoadingDelete}
                               message="Souhaitez-vous supprimer ce média ?"/>
        </>
    );
};

export default MediaCard;

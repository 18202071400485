import React, {useState} from 'react';
import {Button} from "@mui/material";
import ModalComponent from "../../Common/Modal/ModalComponent";
import FormEvenementProgramme from "../Calendrier/FormEvenementProgramme";

const ModalAjouterEvenementProgramme = ({programme, callback}) => {
    const [openModal, setOpenModal] = useState(false);

    const callbackModal = (evenement) => {
        setOpenModal(false);
        if(typeof callback === 'function') {
            callback(evenement);
        }
    }

    return (
        <>
            <Button variant="outlined"
                    color="secondary"
                    onClick={() => setOpenModal(true)}>
                Ajouter un événement
            </Button>

            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}>

                <FormEvenementProgramme programme={programme}
                                        callback={callbackModal}/>
            </ModalComponent>
        </>
    );
};

export default ModalAjouterEvenementProgramme;

import React, {useState} from 'react';
import ModalComponent from "../../Common/Modal/ModalComponent";
import FormLightClient from "../Forms/FormLightClient";
import {Button} from "@mui/material";

const ModalFormClient = ({clientId, children, callback}) => {
    const [openModal, setOpenModal] = useState(false);

    const callbackModal = (type, data) => {
        setOpenModal(false);
        if(typeof callback === 'function') {
            callback(type, data);
        }
    }

    return (
        <>
            <Button variant="text"
                    color="secondary"
                    size="small"
                    onClick={() => setOpenModal(true)}>
                {children}
            </Button>
            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}>
                <FormLightClient clientId={clientId}
                            callback={callbackModal}/>
            </ModalComponent>
        </>
    );
};

export default ModalFormClient;

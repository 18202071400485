import React, {useCallback, useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import MainContainer from "../../Common/MainContainer";
import {useAuth} from "../../../Contexts/useAuth";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import Loading from "../../Common/Loading";
import ProgrammeCard from './ProgrammeCard';
import { Box } from '@mui/system';

const Programmes = () => {
    const {state: stateAuth} = useAuth();

    const [programmes, setProgrammes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchProgrammes = useCallback(async () => {
        const data = await fetchApiImmodesk('/private/programmes?limit=99',{}, stateAuth.token);
        setProgrammes(data.data);
        setIsLoading(false);
    }, [stateAuth]);

    useEffect(() => {
        fetchProgrammes()
    }, [fetchProgrammes]);

    return (
        <MainContainer>
            <Typography variant="h3"
                        sx={{marginBottom: '40px'}}>Mes programmes</Typography>
            {isLoading && <Loading/>}
            {!isLoading && programmes &&
              <Box sx={{ display: "grid", gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"], gap: "20px" }}>
                {programmes.map(programme => (
                  <ProgrammeCard programme={programme} key={programme.id}/>
                ))}
              </Box>
            }
        </MainContainer>
    );
};

export default Programmes;

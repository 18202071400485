const AfficherPrix = ({prix}) => {
    const formatPrix = prix && typeof prix === "string" ? Number.parseInt(prix) : prix;
    return (
        formatPrix.toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            })
    );
};

export default AfficherPrix;

import React from 'react';
import {Box, Button, Modal} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from "@mui/material/styles";

const ModalComponent = ({openModal, handleClose, children, hideCloseIcon, size}) => {
    const theme = useTheme();

    const sx = {
        modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: size === 'lg' ?  ['100%', '90vw', '1200px'] : ['100%', '500px', '500px'],
            maxWidth: ['98vw', '90vw', '90vw'],
            backgroundColor: theme.palette.background.paper,
            boxShadow: 1,
            padding: '25px',
            maxHeight: '98vh',
            overflow: 'auto',
        },
        close: {
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            minWidth: "unset",
            padding: "0",
            opacity: "0.6",
            transition: "all .5s ease_in_out",
            svg: {
                fill: "black",
            },
            "&:hover": {
                backgroundColor: "transparent",
                opacity: "0.8",
            }
        }
    }

    return (
        <Modal open={openModal}
               onClose={handleClose}
               aria-labelledby="modal-confirmation">
            <Box sx={sx.modal}>
                {hideCloseIcon !== true &&
                    <Button variant="text"
                            sx={sx.close}
                            onClick={handleClose}><CloseIcon/></Button>
                }
                {children}
            </Box>
        </Modal>
    );
};

export default ModalComponent;

import React from 'react';
import {Box, CircularProgress} from "@mui/material";

const Loading = ({sizeCircularProgress}) => {
    return (
        <Box sx={{display: "flex", width: '100%', justifyContent: 'center', alignItems: "center"}}>
            <CircularProgress color="primary" size={sizeCircularProgress || "50px"}/>
        </Box>
    );
};

export default Loading;

import React, { useEffect, useState, useCallback } from "react";
import { Box } from "@mui/material";
import DemandesDocumentsAccordion from "./DemandesDocumentsAccordion";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import { useAuth } from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";
import RelancerClientsDemandeDocuments from "./RelancerClientsDemandeDocuments";

function DemandesDocuments({ programmeId, callback }) {
  const { state: stateAuth } = useAuth();

  const [documents, setDocuments] = useState([]);
  const [clientsSelected, setClientsSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const response = await fetchApiImmodesk(
      `/private/documents/demandes/programmes/${programmeId}?signature_procedure_statut=0,10,20,30,40,45,50,52,60,65&transaction_statut=4&transaction_type=2,3,4,5,6,7,8&doc_done=0`,
      {},
      stateAuth.token
    );

    const newDocuments = response.reduce((acc, document) => {
      const index = acc.findIndex(a => a.contact_id === document.contact_id);
      document.id = document.document_id;
      document.list_lot = document.list_lot
        ? document.list_lot
            .split(",")
            .sort((a, b) => a.localeCompare(b))
            .join(",")
        : [];

      if (document.date_creation && !document.date_creation.date) {
        document.date_creation = {
          date: document.date_creation,
        };
      }

      if (index === -1) {
        return [
          ...acc,
          {
            contact_id: document.contact_id,
            contact_nom: document.contact_nom,
            contact_prenom: document.contact_prenom,
            documents: [document],
          },
        ];
      }

      acc[index].documents.push(document);
      return acc;
    }, []);

    setDocuments(newDocuments);
    setIsLoading(false);
  }, [programmeId, stateAuth.token]);

  useEffect(() => {
    if (!programmeId) {
      return;
    }
    fetchData();
  }, [fetchData, programmeId]);

  const handleSelectClient = client => {
    const selectedIndex = clientsSelected.findIndex(
      d => d.contact_id === client.contact_id
    );
    const newClientsSelected = [...clientsSelected];

    if (selectedIndex === -1) {
      newClientsSelected.push(client);
    } else {
      newClientsSelected.splice(selectedIndex, 1);
    }

    setClientsSelected(newClientsSelected);
  };

  const clientIsSelected = client => {
    if (!client) {
      return false;
    }
    return (
      clientsSelected.findIndex(c => c.contact_id === client.contact_id) !== -1
    );
  };

  const clientsSelectedId = clientsSelected.map(c => c.contact_id);

  return (
    <Box>
      {isLoading && <Loading />}
      {!isLoading && documents.length === 0 && <p>Aucun document</p>}
      {!isLoading && documents.length > 0 && (
        <Box sx={{ marginBottom: "24px" }}>
          <RelancerClientsDemandeDocuments
            clientsId={clientsSelectedId}
            variant="button"
            messageConfirmation="Souhaitez-vous relancer les clients sélectionnés ?"
            buttonLabel="Relancer la selection par email"
          />
        </Box>
      )}
      {!isLoading &&
        documents.length > 0 &&
        documents.map(data => (
          <DemandesDocumentsAccordion
            data={data}
            isSelected={clientIsSelected}
            handleSelect={handleSelectClient}
            callback={fetchData}
            key={data.contact_id}
          />
        ))}
    </Box>
  );
}

export default DemandesDocuments;

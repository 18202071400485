import React, {useState} from 'react';
import {Badge, Button} from "@mui/material";
import ModalComponent from "../../Common/Modal/ModalComponent";
import MessageIcon from '@mui/icons-material/Message';
import Tchat from "../Tchat/Tchat";
import {useNotifications} from "../../../Contexts/useNotifications";

const ModalTchatClient = ({clientId, title, sujet = ''}) => {
    const {state: stateNotifications} = useNotifications();
    const [openModal, setOpenModal] = useState(false);

    // Pour le moment on prend uniquement les notifications avec ressource = messages
    const notificationsUnread = stateNotifications.notificationsClients && stateNotifications.notificationsClients.Items ? stateNotifications.notificationsClients.Items.filter(n => n.authorId === clientId && n.isRead === 0 && n.ressource === "messages" && (!n.params || !n.params.type)) : [];

    return (
        <>
            <Button variant="outlined"
                    color="secondary"
                    sx={{
                        padding: "4px",
                        minWidth: "auto",
                    }}
                    onClick={() => setOpenModal(true)}>
                <Badge badgeContent={notificationsUnread.length}
                       color="error">
                    <MessageIcon />
                </Badge>
            </Button>

            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}>
                <Tchat clientId={clientId}
                       title={title}
                        sujet={sujet}/>
            </ModalComponent>
        </>
    );
};

export default ModalTchatClient;

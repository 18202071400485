import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, TextField, Typography} from "@mui/material";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";
import TchatMessage from "./TchatMessage";
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from "@mui/material/CircularProgress";
import {useTheme} from "@mui/material/styles";
import {Alert} from "@mui/lab";
import {useNotifications} from "../../../Contexts/useNotifications";
import {fetchApiNotification} from "../../../Api/Api_notifications";
import { useRef } from 'react';

const Tchat = ({clientId, title, sujet}) => {
    const containerMessagesRef = useRef(null);
    const {state: stateAuth} = useAuth();
    const theme = useTheme();
    const {state: stateNotifications, dispatch: dispatchNotifications} = useNotifications();

    const [currentMessage, setCurrentMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sendIsLoading, setSendIsLoading] = useState(false);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        const dataHistoriques = await fetchApiImmodesk(`/private/historiques?contact_id=${clientId}&limit=-1&qualification_id=34,35,45`, {}, stateAuth.token);
        setIsLoading(false);
        setMessages(dataHistoriques.data.reverse());
    }, [clientId, stateAuth]);

    const updateNotification = useCallback(async(notification) => {
        const newNotification = {...notification, isRead: 1};
        const dataNotification= await fetchApiNotification(`/messages/${newNotification.id}`, {
            method: "PUT",
            body: JSON.stringify(newNotification)
        }, stateAuth.token)

        if(!dataNotification) {
            return;
        }

        return dataNotification;
    }, [stateAuth]);

    const updateNotifications = useCallback(async () => {
        if(!stateNotifications) {
            return;
        }

        const notificationsByAuthorId = stateNotifications.notificationsClients.Items.filter(n => n.authorId === clientId && n.isRead === 0);
        const newNotifications = await Promise.all(notificationsByAuthorId.map(async (notification) => await updateNotification(notification)));

        dispatchNotifications({type: 'updateNotificationsClient', payload: newNotifications});
    }, [clientId, dispatchNotifications, stateNotifications, updateNotification])

    useEffect(() => {
        fetchData();
        updateNotifications();
        // eslint-disable-next-line
    }, [clientId])

    useEffect(() => {
        if(!messages.length || !containerMessagesRef.current) {
            return;
        }
        containerMessagesRef.current.scrollIntoView({block: "end", behavior: 'smooth'});
      }, [messages]);

    const getPositionMessage = (message) => {
        if(!message || !message.emetteur_id) {
            return 'flex-end';
        }

        return parseInt(message.emetteur_id) === clientId ? 'flex-start' : 'flex-end';
    }

    const getTitle = (message) => {
        if(!message || !message.date_creation) {
            return '';
        }

        const date = new Date(message.date_creation.date);
        return date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'numeric', year: 'numeric', hour: "2-digit", minute: "2-digit"});
    }

    const send = async () => {
        const bodyStringify = JSON.stringify({
            message: currentMessage,
            sujet: sujet,
            contact_id: clientId
        })

        setSendIsLoading(true);
        const dataMessage = await fetchApiImmodesk(`/private/messages`, {
            'method': 'POST',
            'headers': new Headers({
                'Content-Length': bodyStringify.length,
                'Content-Type': 'application/json',
            }),
            'body': bodyStringify,
        }, stateAuth.token);
        setSendIsLoading(false);

        if(dataMessage.error) {
            return;
        }

        addMessage({
            date_creation: {
                date: new Date()
            },
            emetteur_id: stateAuth.profil.id,
            texte: currentMessage
        });
        setCurrentMessage('');
    }

    const addMessage = (message) => {
        const newMessages = [...messages];
        newMessages.push(message);
        setMessages(newMessages);
    }

    const getBackgroundColorMessage = (message) => {
        if(!message || !message.emetteur_id) {
            return theme.palette.grey["200"];
        }

        return parseInt(message.emetteur_id) === clientId ? theme.palette.grey["200"] : theme.palette.chart.blue[0];
    }

    const getColorMessage = (message) => {
        if(!message || !message.emetteur_id) {
            return theme.palette.grey["700"];
        }

        return parseInt(message.emetteur_id) === clientId ? theme.palette.grey["700"] : 'white';
    }

    return (
        <>
            <Typography variant='h4'>{title}</Typography>
            {isLoading && <Loading/>}
            {!isLoading &&
                <>
                    <Box sx={{maxHeight: ['100%', '300px'], overflowY: 'auto', marginTop: '20px'}}>
                        {messages && messages.map((message, index) => (
                            <TchatMessage message={message.texte}
                                          title={getTitle(message)}
                                          color={getColorMessage(message)}
                                          backgroundColor={getBackgroundColorMessage(message)}
                                          position={getPositionMessage(message)}
                                          key={index}/>
                        ))}
                        <Box ref={containerMessagesRef} />
                        {messages && messages.length === 0 &&
                            <Alert icon={false}
                                   severity="info">Aucun message</Alert>
                        }
                    </Box>
                    <Box sx={{marginTop: '20px', display: 'flex'}}>
                        <TextField
                            id="outlined-name"
                            label="Ecrire un message"
                            value={currentMessage}
                            sx={{flex: 1, marginRight: '5px'}}
                            onChange={(e) => setCurrentMessage(e.target.value)}
                        />

                            <Button variant="contained"
                                    color="primary"
                                    onClick={send}
                                    sx={{width: '50px', display: 'flex', justifyContent: 'center'}}
                                    disabled={currentMessage.length === 0 || sendIsLoading}>
                                    {sendIsLoading &&
                                        <CircularProgress size="20px"
                                                          color="secondary"/>
                                    }
                                    {!sendIsLoading && <SendIcon />}
                            </Button>
                    </Box>

                </>
            }
        </>
    );
};

export default Tchat;

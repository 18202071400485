import React, {useCallback, useEffect, useState} from 'react';
import {useAuth} from "../Contexts/useAuth";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {fetchApiImmodesk} from "../Api/Api_immodesk";
import GuardedRoute from "./GuardedRoute";
import Client from "./Clients/Clients/Client";
import Login from "./Login";
import {default as ProgrammesConfigurateur} from "./Configurateurs/Programmes/Programmes";
import {default as ProgrammeConfigurateur} from "./Configurateurs/Programmes/Programme";
import {default as ProgrammesClient} from "./Clients/Programmes/Programmes";
import {default as ProgrammeClient} from "./Clients/Programmes/Programme";
import Loading from "./Common/Loading";
import {Box} from "@mui/material";
import {fetchApiNotification} from "../Api/Api_notifications";
import {useNotifications} from "../Contexts/useNotifications";
import MesInformations from './Common/Mes-informations/MesInformations';

const Router = () => {

    const {state: stateAuth, dispatch} = useAuth();
    const {dispatch: dispatchNotificiations} = useNotifications();

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation()

    const getProfil = useCallback(async () => {
        const profil = await fetchApiImmodesk(`/private/profil`, {}, stateAuth.token)
        dispatch({type: "setProfil", payload: {profil}});
    }, [stateAuth, dispatch]);

    const getConfigsCommercialisateur = useCallback(async () => {
        const configsCommercialisateur = await fetchApiImmodesk(`/private/configs/commercialisateurs`, {}, stateAuth.token);
        dispatch({type: "setConfigsCommercialisateur", payload: {configsCommercialisateur}});
    }, [stateAuth, dispatch]);

    const getNotifications = useCallback(async () => {
        const dataNotifications = await fetchApiNotification(`/messages`, {}, stateAuth.token);
        dispatchNotificiations({type: 'setNotifications', payload: {'notificationsClients': dataNotifications}});
    }, [stateAuth, dispatchNotificiations])

    const getConfigIot = useCallback(async () => {
        const headers = new Headers({Authorization:`Bearer ${stateAuth.token}`});

        const fetchIoT = await fetch(`${process.env.REACT_APP_API_NOTIFICATIONS}/keys`, {headers});
        const config = await fetchIoT.json();

        const configIot = {
            ...config,
            host: config.iotEndpoint,
            accessKeyId: config.accessKey,
            iotTopic: `${process.env.REACT_APP_PREFIX_IOT_TOPIC}-user-${stateAuth.profil.id}`
        };

        delete configIot.iotEndpoint;
        delete configIot.accessKey;

        dispatch({type: "setConfigAwsIot", payload: configIot});
    }, [stateAuth, dispatch])

    useEffect(() => {
        if (stateAuth.init && stateAuth.isAuth && !stateAuth.profil && !stateAuth.configsCommercialisateur) {
            getProfil();
            getConfigsCommercialisateur();
            getNotifications();
        }
        else if(stateAuth.init && stateAuth.isAuth && stateAuth.profil && stateAuth.configsCommercialisateur) {
          if (!stateAuth.configAwsIot) {
            getConfigIot();
          }
          else if(location && location.pathname && (location.pathname === '/' || location.pathname === '/login')) {
            const route = stateAuth.configsCommercialisateur.use_userspace ? '/clients' : '/configurateurs';
            navigate(route);
          }
        }
        else {
          setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [stateAuth])

    return (
        <>
        {isLoading &&
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Loading/>
            </Box>
        }
        {!isLoading &&
            <Routes>
                <Route exact
                       path="/"
                       element={<GuardedRoute/>}/>

                <Route path="/clients"
                       element={<GuardedRoute component={ProgrammesClient} permission="clients"/>}/>

                <Route path="/clients/contacts/:id"
                       element={<GuardedRoute component={Client} permission="clients"/>}/>

                <Route path="/clients/programmes/:id"
                       element={<GuardedRoute component={ProgrammeClient} permission="clients"/>}/>

                <Route path="/configurateurs"
                       element={<GuardedRoute component={ProgrammesConfigurateur} permission="configurateurs"/>}/>

                <Route path="/configurateurs/programmes/:id"
                       element={<GuardedRoute component={ProgrammeConfigurateur} permission="configurateur"/>}/>

                <Route path="/profil"
                       element={<MesInformations/>}/>

                <Route path="/login"
                       element={<Login/>}/>
            </Routes>
        }
        </>
    );
};

export default Router;

import React, {useState} from 'react';
import ModalComponent from "./Modal/ModalComponent";
import {Button} from "@mui/material";
import ChangePassword from './ChangePassword';

const ModalChangePassword = () => {
    const [openModal, setOpenModal] = useState(false);

    const closeModal = () => {
      setOpenModal(false);
    }

    return (
        <>
            <Button variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => setOpenModal(true)}>
                Modifier mon mot de passe
            </Button>
            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}>
              <ChangePassword callback={closeModal}/>
            </ModalComponent>
        </>
    );
};

export default ModalChangePassword;

import React from 'react';
import {HEADER, NAVBAR} from "../../../config";
import useResponsive from "../../../Hooks/useResponsive";
import useOffSetTop from "../../../Hooks/useOffsetTop";
import {AppBar, Box, Stack, Toolbar} from "@mui/material";
import cssStyles from "../../../Utils/cssStyles";
import Logo from "../Logo";
import {styled} from "@mui/material/styles";
import IconButtonAnimate from "../Animate/IconButtonAnimate";
import Iconify from "../Iconify";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "../Notifications/NotificationsPopover";

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({isCollapse, isOffset, verticalLayout, theme}) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('lg')]: {
        height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
        ...(isCollapse && {
            width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
        }),
        ...(isOffset && {
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        }),
        ...(verticalLayout && {
            width: '100%',
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
            backgroundColor: theme.palette.background.default,
        }),
    },
}));

const Header = (({onOpenSidebar, isCollapse = false, verticalLayout = false}) => {
    const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
    const isDesktop = useResponsive('up', 'lg');

    return (
        <RootStyle isCollapse={isCollapse}
                   isOffset={isOffset}
                   verticalLayout={verticalLayout}>

            <Toolbar sx={{
                minHeight: '100% !important',
                px: {lg: 5},
            }}>
                {isDesktop && verticalLayout && <Logo sx={{mr: 2.5}}/>}

                {!isDesktop && (
                    <IconButtonAnimate onClick={onOpenSidebar}
                                       sx={{ mr: 1, color: 'text.primary' }}>
                        <Iconify icon="eva:menu-2-fill" />
                    </IconButtonAnimate>
                )}

                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row"
                       alignItems="center"
                       spacing={{ xs: 1.5, sm: 3 }}>
                    <NotificationsPopover />
                    <AccountPopover/>
                </Stack>

            </Toolbar>
        </RootStyle>
    );
});

export default Header;

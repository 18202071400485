import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import { useAuth } from "../../../Contexts/useAuth";
import { Alert } from "@mui/lab";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ModalChangePassword from "../ModalChangePassword";

const FormProfil = () => {
  const { state: stateAuth, dispatch } = useAuth();

  const [profil, setProfil] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});

  const reactQuillModules = {
    toolbar: [
      [{ 'header': []}],
      ['bold', 'italic'],
      [{'align': []}],
      [
        { list: 'ordered' },
        { list: 'bullet' },
      ],
      ['image'],
    ],
  }

  useEffect(() => {
    if (stateAuth.profil) {
      setProfil(stateAuth.profil);
    }
  }, [stateAuth]);

  const onSubmit = async () => {
    setIsLoading(true);
    const image = fileBase64 ? fileBase64 : profil.image;
    const profilNew = { ...profil, image };
    const dataProfil = await fetchApiImmodesk(
      `/private/profil`,
      {
        method: "PUT",
        body: JSON.stringify(profilNew),
      },
      stateAuth.token
    );

    setIsLoading(false);

    if (dataProfil.error) {
      // TODO error
      return;
    }

    dispatch({ type: "setProfil", payload: { profil: profilNew } });
  };

  const handleChangeFile = (event) => {
    if (!event || !event.target.files || !event.target.files[0]) {
      return;
    }

    const image = URL.createObjectURL(event.target.files[0]);
    getBase64Image(image);
    setFile(image);
  };

  const getBase64Image = (image) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.src = image;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const imgBase64 = canvas.toDataURL("image/png");
      setFileBase64(imgBase64);
    };
  };

  return (
    profil && (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              marginTop: "30px",
              display: "inline-flex",
              flexDirection: "column",
            }}
          >
            {(file || (profil && profil.image)) && (
              <Box
                sx={{
                  paddingBlock: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  id="target"
                  alt="preview"
                  src={file || `${profil.image}?t=${new Date().getTime()}`}
                  style={{ height: "100px" }}
                />
              </Box>
            )}
            <label htmlFor="contained-button-file">
              <input
                id="contained-button-file"
                type="file"
                accept=".jpeg,.jpg,.png"
                onChange={handleChangeFile}
                style={{ display: "none" }}
              />
              <Button variant="contained" color="secondary" component="span">
                Choisir une image
              </Button>
            </label>
          </Box>
        </Box>

        <Box
          sx={{
            display: "grid",
            gap: "15px",
            gridTemplateColumns: [
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(5, 1fr)",
            ],
            marginBottom: "15px",
            marginTop: "40px",
          }}
        >
          <TextField
            id="profil-nom"
            label="Nom"
            name="nom"
            required
            value={profil?.nom || ""}
            {...register("profil.nom", {
              required: "Le nom est obligatoire.",
            })}
            InputLabelProps={{
              shrink: profil?.nom ? true : false,
            }}
            onChange={(event) =>
              setProfil({ ...profil, nom: event.target.value })
            }
          />

          <TextField
            id="profil-prenom"
            label="Prénom"
            name="prenom"
            required
            value={profil?.prenom || ""}
            {...register("profil.prenom", {
              required: "Le prénom est obligatoire.",
            })}
            InputLabelProps={{
              shrink: profil?.prenom ? true : false,
            }}
            onChange={(event) =>
              setProfil({ ...profil, prenom: event.target.value })
            }
          />

          <TextField
            id="profil-tel"
            label="Téléphone"
            name="tel"
            value={profil?.tel || ""}
            InputLabelProps={{
              shrink: profil?.tel ? true : false,
            }}
            onChange={(event) =>
              setProfil({ ...profil, tel: event.target.value })
            }
          />

          <TextField
            id="profil-tel_mobile"
            label="Téléphone mobile"
            name="tel"
            value={profil?.tel_mobile || ""}
            InputLabelProps={{
              shrink: profil?.tel_mobile ? true : false,
            }}
            onChange={(event) =>
              setProfil({ ...profil, tel_mobile: event.target.value })
            }
          />

          <TextField
            id="profil-email"
            label="Email"
            name="email"
            required
            value={profil?.email || ""}
            {...register("profil.email", {
              required: "L'email est obligatoire.",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entrer une adresse email valide.",
              },
            })}
            InputLabelProps={{
              shrink: profil?.email ? true : false,
            }}
            onChange={(event) =>
              setProfil({ ...profil, email: event.target.value })
            }
          />

          <TextField
            id="profil-adresse"
            label="Adresse"
            name="adresse1"
            value={profil?.adresse1 || ""}
            InputLabelProps={{
              shrink: profil?.adresse1 ? true : false,
            }}
            onChange={(event) =>
              setProfil({ ...profil, adresse1: event.target.value })
            }
          />

          <TextField
            id="profil-adresse2"
            label="Complément d'adresse"
            name="adresse2"
            value={profil?.adresse2 || ""}
            InputLabelProps={{
              shrink: profil?.adresse2 ? true : false,
            }}
            onChange={(event) =>
              setProfil({ ...profil, adresse2: event.target.value })
            }
          />

          <TextField
            id="profil-ville"
            label="Ville"
            name="ville"
            value={profil?.ville || ""}
            InputLabelProps={{
              shrink: profil?.ville ? true : false,
            }}
            onChange={(event) =>
              setProfil({ ...profil, ville: event.target.value })
            }
          />

          <TextField
            id="profil-cp"
            label="Code postal"
            name="code_postal"
            value={profil?.code_postal || ""}
            InputLabelProps={{
              shrink: profil?.code_postal ? true : false,
            }}
            onChange={(event) =>
              setProfil({ ...profil, code_postal: event.target.value })
            }
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ModalChangePassword />
          </Box>
        </Box>

        <Typography sx={{ marginTop: "30px", marginBottom: "5px" }}>
          Signature
        </Typography>

        <ReactQuill
          theme="snow"
          value={profil.signature}
          modules={reactQuillModules}
          onChange={(value) => setProfil({ ...profil, signature: value })}
        />

        <Typography variant="caption">* champ obligatoire</Typography>

        {errors && Object.keys(errors).length > 0 && (
          <Box
            sx={{
              display: "grid",
              gap: "5px",
              gridTemplateColumns: "1fr",
              marginTop: "10px",
            }}
          >
            {errors?.profil?.nom && (
              <Alert severity="warning">{errors.profil.nom.message}</Alert>
            )}
            {errors?.profil?.prenom && (
              <Alert severity="warning">{errors.profil.prenom.message}</Alert>
            )}
            {errors?.profil?.email && (
              <Alert severity="warning">{errors.profil.email.message}</Alert>
            )}
          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading && (
              <CircularProgress
                size="20px"
                color="secondary"
                sx={{ marginRight: "10px" }}
              />
            )}
            <Box component="span">Valider</Box>
          </Button>
        </Box>
      </form>
    )
  );
};

export default FormProfil;

import React, {useEffect, useState} from 'react';
import {Box, Button, TextField, Typography} from "@mui/material";
import DocumentsCategories from "./DocumentsCategories";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";

const DemandeDocumentsClient = ({client, callback}) => {
    const {state: stateAuth} = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [commentaire, setCommentaire] = useState("");

    const handleSubmit = async () => {
        setIsLoading(true);

        const body = {
            "contact_id": client.id,
            "media_categorie_id": documents.join(','),
            "vendeur_id": stateAuth.profil.id,
        }

        if(documents.length === 1) {
            body.media_note = commentaire;
        }

        await fetchApiImmodesk(`/private/documents/demandes/`,
            {
                method: 'POST',
                body: JSON.stringify(body),
            },
            stateAuth.token);

        setIsLoading(false);

        if(typeof callback === 'function') {
            callback();
        }
    }

    useEffect(() => {
        if(documents && documents.length > 1) {
            setCommentaire("");
        }
    }, [documents])

    return (
        <Box>
            <Typography variant="h6"
                        component="h2"
                        sx={{marginBottom: "40px"}}>Demande de documents</Typography>

            <DocumentsCategories callback={setDocuments} />

            {documents && documents.length === 1 &&
                <TextField
                    label="Commentaire"
                    multiline
                    rows={4}
                    value={commentaire}
                    fullWidth
                    onChange={(e) => setCommentaire(e.target.value)}
                    maxRows={Infinity}
                    sx={{marginTop: "20px"}}
                />
            }

            <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                <Button variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isLoading || documents.length === 0}>
                    {isLoading &&
                        <CircularProgress size="20px"
                                          color="secondary"
                                          sx={{marginRight: '10px'}}/>}
                    <Box component="span">Valider</Box>
                </Button>
            </Box>

        </Box>
    );
};

export default DemandeDocumentsClient;

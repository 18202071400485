import {Box} from "@mui/system";
import React from "react";
import RelancerClientsDemandeDocuments from "../Documents/RelancerClientsDemandeDocuments.js";
import StatsItem from "./StatsItem";

const StatsDocumentsAttente = ({demandesDocuments}) => {
    const clientsId = demandesDocuments.reduce((acc, demande) => {
        if(acc.indexOf(demande.contactId) === -1) {
            acc.push(demande.contactId);
        }
        return acc;
    }, []);

    const messageConfirmation = "Souhaitez-vous relancer tous vos clients pour lesquels vous avez des demandes de documents en attente de réception ?";

    return (
        <>
            <Box sx={{display: "grid", gridTemplateColumns: "2fr 50px", gap: "10px", gridAutoRows: "min-content"}}>
                <StatsItem item={{
                    severity: "success",
                    label: "Documents en attente",
                    value: demandesDocuments.length
                }}/>
            </Box>
            <Box sx={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
                <RelancerClientsDemandeDocuments clientsId={clientsId}
                                                 messageConfirmation={messageConfirmation}
                                                 buttonLabel="Relancer les clients de ce programme"
                />
            </Box>
        </>
    )
};

export default StatsDocumentsAttente;

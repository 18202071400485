import { Alert, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { fetchApiImmodesk } from '../../Api/Api_immodesk';
import { useAuth } from '../../Contexts/useAuth';

const ChangePassword = ({callback}) => {
  const {state: stateAuth} = useAuth();

    const [currentPassword, setCurrentPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [newPasswordVerif, setNewPasswordVerif] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const minLengthPassword= 5;

    const disabledBtn = () => {
      if((currentPassword && currentPassword.length === 0) || (newPassword && newPassword.length < minLengthPassword) || (newPasswordVerif && newPasswordVerif.length < minLengthPassword) || newPassword !== newPasswordVerif) {
        return true;
      }
      return false;
    }

    const handleSubmit = async () => {
      setIsLoading(true);

      const dataProfil = await fetchApiImmodesk(`/private/profil?password=${newPassword}&password_old=${currentPassword}&password_validation=${newPasswordVerif}`, {
          method: 'PUT',
      }, stateAuth.token);

      setIsLoading(false);

      if(!dataProfil || dataProfil.error) {
          if(dataProfil.response.message) {
            setError(dataProfil.response.message);
          }
          else {
            setError(false);
          }
          return;
      }
      else {
        setError(false);
      }

      if(typeof callback === 'function') {
        callback();
      }
    }

    return (
      <Box>
            <Typography variant="h4" sx={{ marginBottom: "30px" }}>Modifier mon mot de passe</Typography>

           <TextField
              id="current-password"
              label="Mot de passe actuel"
              name="currentPassword"
              type="password"
              value={currentPassword}
              fullWidth
              sx={{ marginBottom: "15px" }}
              onChange={(event) =>
                setCurrentPassword(event.target.value)
              }
            />

            <TextField
              id="new-password"
              label="Nouveau mot de passe"
              name="new-password"
              type="password"
              value={newPassword}
              fullWidth
              sx={{ marginBottom: "15px" }}
              onChange={(event) =>
                setNewPassword(event.target.value)
              }
            />

            <TextField
              id="conf-new-password"
              label="Confirmation du nouveau mot de passe"
              name="conf-new-password"
              type="password"
              value={newPasswordVerif}
              fullWidth
              error={newPassword !== newPasswordVerif}
              onChange={(event) =>
                setNewPasswordVerif(event.target.value)
              }
            />

            {error &&
              <Alert severity='warning' sx={{marginTop: "20px"}}>{error}</Alert>
            }

            <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                <Button variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isLoading || disabledBtn()}>
                    {isLoading &&
                        <CircularProgress size="20px"
                                          color="secondary"
                                          sx={{marginRight: '10px'}}/>
                    }
                    <Box component="span">Valider</Box>
                </Button>
            </Box>
      </Box>
    );
};

export default ChangePassword;

import { Button, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModalConfirmation from "../../Common/Modal/ModalConfirmation.js";
import ModalComponent from "../../Common/Modal/ModalComponent.js";
import { useEffect } from "react";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk.js";
import { useAuth } from "../../../Contexts/useAuth.js";
import FormRendezVousClient from "../Forms/FormRendezVousClient.js";

const ActionsRendezVous = ({ item, callback }) => {
  const { state: stateAuth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    if (!action) {
      return;
    }

    const { type } = action;

    switch (type) {
      case "delete-action":
        setMessageModal(`Supprimer le rendez-vous`);
        break;
      case "terminer-action":
        setMessageModal(`Passer l'action en réalisée`);
        break;
      default:
        setMessageModal("");
    }
  }, [action, item]);

  const handleMenuItem = (action) => {
    setAction(action);
    setAnchorEl(null);

    if (action.modal === "modal-confirmation") {
      setOpenModalConfirmation(true);
    } else {
      setOpenModal(true);
    }
  };

  const handleConfirmationModal = async () => {
    const { type } = action;

    switch (type) {
      case "delete-action":
        deleteAction();
        break;
      case "terminer-action":
        terminerAction();
        break;
      default:
        return;
    }
  };

  const handleCloseModalComponent = () => {
    const { type } = action;

    switch (type) {
      default:
        setOpenModal(false);
    }
  };

  const deleteAction = async () => {
    setIsLoading(true);

    const dataDeleteAction = await fetchApiImmodesk(
      `/private/actions/${item.id}`,
      {
        method: "DELETE",
      },
      stateAuth.token
    );

    setIsLoading(false);

    if (dataDeleteAction.error) {
      // TODO error
      return;
    }

    if (typeof callback === "function") {
      callback("delete", item);
    }

    setOpenModalConfirmation(false);
  };

  const terminerAction = async () => {
    setIsLoading(true);

    item.done = true;

    const dataAction = await fetchApiImmodesk(
      `/private/actions/${item.id}`,
      {
        method: "PUT",
        body: JSON.stringify(item),
      },
      stateAuth.token
    );

    setIsLoading(false);

    if (dataAction.error) {
      // TODO error
      return;
    }

    if (typeof callback === "function") {
      callback("update", dataAction);
    }

    setOpenModalConfirmation(false);
  };

  const callbackModifierAction = (nameAction, action) => {
    if (typeof callback === "function") {
      if (nameAction === "update-action") {
        callback("update", action);
      }
    }
    setOpenModal(false);
  };

  const sx = {
    menuItem: {
      fontSize: "0.8rem",
      color: "#000",
      a: {
        textDecoration: "none",
        fontSize: "0.8rem",
        color: "#000",
      },
    },
  };

  return (
    <>
      <Button
        id="actions-button"
        aria-controls={openMenu ? "actions-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreHorizIcon sx={{ transform: "rotate(90deg)" }} />
      </Button>
      <Menu
        id="actions-documents-client-demande-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            handleMenuItem({
              type: "modifier-action",
              modal: "modal-modifier-action",
            })
          }
          sx={sx.menuItem}
        >
          Modifier le rendez-vous
        </MenuItem>
        <MenuItem
          sx={sx.menuItem}
          disabled={item.done}
          onClick={() =>
            handleMenuItem({
              type: "terminer-action",
              modal: "modal-confirmation",
            })
          }
        >
          Passer le rendez-vous en réalisé
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleMenuItem({
              type: "delete-action",
              modal: "modal-confirmation",
            })
          }
          sx={sx.menuItem}
        >
          Supprimer le rendez-vous
        </MenuItem>
      </Menu>

      <ModalConfirmation
        openModal={openModalConfirmation}
        handleConfirm={handleConfirmationModal}
        handleClose={() => setOpenModalConfirmation(false)}
        loading={isLoading}
        message={messageModal}
      />

      <ModalComponent
        openModal={openModal}
        handleClose={handleCloseModalComponent}
      >
        {action && action.modal && action.modal === "modal-modifier-action" && (
          <>
            <Typography
              variant="h6"
              component="h2"
              sx={{ marginBottom: "20px" }}
            >
              Édition d'un rendez-vous
            </Typography>
            <FormRendezVousClient
              client={item.contact_id}
              initAction={item}
              callback={callbackModifierAction}
            />
          </>
        )}
      </ModalComponent>
    </>
  );
};

export default ActionsRendezVous;

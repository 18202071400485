import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ShowDate } from "../../Common/showDate";
import {BtnEditActualite} from "./BtnEditActualite";
import {BtnDeleteActualite} from "./BtnDeleteActualite";
import {Box} from "@mui/system";

const TableActualites = ({ actualites, programme, callback }) => {
  return (
    <TableContainer>
      <Table aria-label="sticky table" size="small">
        <TableHead>
          <TableRow>
            {/*<TableCell sx={{ width: "150px" }}>Statut</TableCell>*/}
            <TableCell sx={{ width: "150px" }}>Date</TableCell>
            <TableCell>Titre de l'article</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actualites &&
            actualites.map((actualite, index) => (
              <TableRow hover key={index}>
                {/*<TableCell>*/}
                {/*    <SwitchStatutActualite actualite={actualite} />*/}
                {/*</TableCell>*/}
                <TableCell>
                  {actualite.date && actualite.date.date && (
                    <ShowDate date={actualite.date.date} />
                  )}
                </TableCell>
                <TableCell>{actualite.titre}</TableCell>
                <TableCell sx={{ width: "120px"}}>
                  <Box sx={{display: 'flex', gap: '4px' }}>
                    <BtnDeleteActualite actualite={actualite}
                                        callback={callback}/>
                    <BtnEditActualite actualite={actualite}
                                      programme={programme}
                                      callback={callback}/>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableActualites;

import React, {useState} from 'react';
import {FormControl, InputLabel, MenuItem} from "@mui/material";
import CustomMuiSelect from '../../CustomMui/CustomMuiSelect';

const MediasCategories = ({initCategory, categories, callback}) => {
    const [category, setCategory] = useState(initCategory);

    const handleChange = (event) => {
        setCategory(event.target.value)

        if(typeof callback === 'function') {
            callback(event.target.value);
        }
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="media-category-select-label">Catégorie</InputLabel>
            <CustomMuiSelect
                labelId="media-category-select-label"
                id="media-category-select"
                value={category}
                label="Catégorie"
                size="small"
                onChange={handleChange}
            >
                {categories && categories.map(item => (
                    <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                ))}
            </CustomMuiSelect>
        </FormControl>
    );
};

export default MediasCategories;

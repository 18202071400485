import React, {useCallback, useEffect, useState} from 'react';
import {
		Box,
		Button,
		FormControl,
		InputLabel,
		MenuItem,
		TextField,
		Typography
} from "@mui/material";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";
import AjouterFichier from "../Files/AjouterFichier";
import CircularProgress from "@mui/material/CircularProgress";
import CustomMuiSelect from '../../CustomMui/CustomMuiSelect';

const DeposerDocumentProgramme = ({document, programmeId, callback}) => {
		const {state: stateAuth} = useAuth();

		const title = document ? "Modifier un document" : "Déposer un document";

		const [isLoading, setIsLoading] = useState(true);
		const [categories, setCategories] = useState([]);
		const [categorieSelected, setCategorieSelected] = useState(document ? {id: document.categorie_id, label: document.categorie_label} : {});
		const [files, setFiles] = useState([]);
		const [titre, setTitre] = useState(document ? document.titre : '');

		const fetchData = useCallback(async () => {
				setIsLoading(true);

				const dataCategories = await fetchApiImmodesk(`/private/configs/documents/categories/?check_exist=0&table_id=22`, {}, stateAuth.token);

				setIsLoading(false);

				if (dataCategories.error) {
						// TODO error
						return;
				}

				setCategories(dataCategories);
		}, [stateAuth]);

		useEffect(() => {
				fetchData();
				// eslint-disable-next-line
		}, [])

		const handleCategories = (event) => {
				const categorie = categories.find(c => c.id === event.target.value);

				if (!categorie) {
						return;
				}

				setCategorieSelected(categorie);
		}

		const handleSubmit = async() => {
			setIsLoading(true);

			const formData = new FormData();
				formData.append('programme_id', programmeId);
				formData.append('titre', titre);
				formData.append('categorie_id', categorieSelected.id);

			if(files.length > 0) {
				await Promise.all(files.map((file) => {
					formData.append('file', file, file.name);
					return fetchApi(formData);
				}));
			}
			else {
				await fetchApi(formData);
			}

			setIsLoading(false);
			if(typeof callback === 'function') {
					callback();
			}
		}

		const fetchApi = (formData) => {
			const urlImmodesk = document ? `/private/documents/${document.id}` : `/private/documents`;
			const fetchApiMethod = document ? 'PUT' : 'POST';

			return fetchApiImmodesk(urlImmodesk, {
				method: fetchApiMethod,
				type: 'file',
				body: formData
			}, stateAuth.token);
		}

		return (
				<>
						<Typography variant="h6"
												component="h2"
												sx={{marginBottom: "20px"}}>{title}</Typography>

						{isLoading && <Loading/>}

						{!isLoading &&
								<>
										<Box sx={{marginBottom: "40px", textAlign: "center"}}>
												<AjouterFichier files={files} setFiles={setFiles} />
										</Box>

										<FormControl fullWidth>
												<InputLabel id="select-categorie-label">Sélectionner une catégorie</InputLabel>
												<CustomMuiSelect
														labelId="select-categorie-label"
														id="select-categorie"
														value={categorieSelected?.id}
														label="Sélectionner une catégorie"
														onChange={handleCategories}
												>
														{categories && categories.map((categorie, index) => (
																<MenuItem value={categorie.id} key={index}>{categorie.label}</MenuItem>
														))}
												</CustomMuiSelect>
										</FormControl>

										<TextField label="Titre"
															 fullWidth
															 value={titre}
															 onChange={(e) => setTitre(e.target.value)}
															 variant="outlined"
															 sx={{marginTop: "20px"}}/>

										<Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
												<Button variant="contained"
																color="primary"
																onClick={handleSubmit}
																disabled={isLoading || !categorieSelected.id || (!document && files.length === 0)}>
														{isLoading && <CircularProgress size="20px" color="secondary" sx={{marginRight: '10px'}}/>}
														<Box component="span">Envoyer</Box>
												</Button>
										</Box>
								</>
						}
				</>
		);
};

export default DeposerDocumentProgramme;

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { fetchApiImmodesk } from "../Api/Api_immodesk";
import { useAuth } from "../Contexts/useAuth";
import Loading from "./Common/Loading";
import { Alert } from "@mui/lab";
import CustomMuiSelect from "./CustomMui/CustomMuiSelect";

const Login = () => {
  const { dispatch } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [commercialisateurId, setCommercialisateurId] = useState("");
  const [error, setError] = useState(null);
  const [promoteurs, setPromoteurs] = useState(null);

  const fetchPreLogin = async () => {
    setIsLoading(true);
    const body = {
      email: email,
      password: password,
      discr: "membre"
    };

    const preLogin = await fetchApiImmodesk("/public/auth/prelogin", {
      method: "POST",
      body: JSON.stringify(body)
    });

    // Si on a un status = erreur
    if (preLogin && preLogin.status) {
      if (preLogin.status === 403) {
        setError({
          severity: "warning",
          message:
            "Nous avons trouvé aucune correspondance avec cet email et mot de passe."
        });
      } else {
        setError({ severity: "error", message: "Une erreur s'est produite." });
      }
    } else if (preLogin && preLogin.length === 1) {
      setError(null);
      setCommercialisateurId(preLogin[0].employeur_id);
    } else if (preLogin && preLogin.length > 1) {
      preLogin.sort((a, b) => (a.employeur_nom > b.employeur_nom ? 1 : -1));

      setError(null);
      setPromoteurs(
        preLogin.map(promoteur => ({
          ...promoteur,
          employeur_nom: promoteur.employeur_nom.toUpperCase()
        }))
      );
      // TODO plusieurs promoteurs
    } else {
      setError({ severity: "error", message: "Une erreur s'est produite." });
    }

    setIsLoading(false);
  };

  const fetchLogin = useCallback(async () => {
    setIsLoading(true);

    const body = {
      email: email,
      password: password,
      commercialisateur_id: commercialisateurId,
      discr: "membre",
      referer: "espace-adv"
    };

    const login = await fetchApiImmodesk("/public/auth/login", {
      method: "POST",
      body: JSON.stringify(body)
    });

    // Erreur login
    if (!login.token) {
      const message = login.message
        ? login.message
        : "Une erreur s'est produite.";
      setIsLoading(false);
      setError({ severity: "error", message });
      return;
    }

    dispatch({ type: "setToken", payload: { token: login.token } });
    dispatch({ type: "setIsAuth", payload: { isAuth: true } });
  }, [dispatch, commercialisateurId, email, password]);

  useEffect(() => {
    if (!commercialisateurId) {
      return;
    }

    fetchLogin();
    // eslint-disable-next-line
  }, [commercialisateurId]);

  const handleSubmit = async e => {
    e.preventDefault();
    await fetchPreLogin();
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: ["10px", "0px", "0px"]
      }}
    >
      <Box
        sx={{
          width: ["100%", "350px", "350px"],
          padding: "30px 20px",
          boxShadow: 1,
          height: "max-content"
        }}
      >
        <Typography
          variant="h5"
          sx={{ textAlign: "center", marginBottom: "30px" }}
        >
          Connectez-vous
        </Typography>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {error && (
              <Alert severity={error.severity} sx={{ marginBottom: "40px" }}>
                {error.message}
              </Alert>
            )}
            <form>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  id="login-email"
                  label="Email"
                  type="email"
                  variant="outlined"
                  size="small"
                  value={email}
                  sx={{ marginBottom: "20px" }}
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  id="login-password"
                  label="Mot de passe"
                  type="password"
                  variant="outlined"
                  size="small"
                  value={password}
                  sx={{ marginBottom: "20px" }}
                  onChange={e => setPassword(e.target.value)}
                />

                {promoteurs && (
                  <FormControl size="small">
                    <InputLabel id="select-promoteur-label">
                      Promoteur
                    </InputLabel>
                    <CustomMuiSelect
                      labelId="select-promoteur-label"
                      id="select-promoteur"
                      value={commercialisateurId}
                      label="Promoteur"
                      onChange={e => {
                        setCommercialisateurId(e.target.value);
                      }}
                      sx={{ marginBottom: "20px" }}
                    >
                      {promoteurs.map(promoteur => (
                        <MenuItem
                          value={promoteur.employeur_id}
                          key={promoteur.employeur_id}
                        >
                          {promoteur.employeur_nom}
                        </MenuItem>
                      ))}
                    </CustomMuiSelect>
                  </FormControl>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  Connexion
                </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Login;

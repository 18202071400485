import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk.js";
import { useAuth } from "../../../Contexts/useAuth.js";
import Loading from "../../Common/Loading.js";
import RendezVousItems from "./RendezVousItems.js";
import ModalAjouterRendezVous from "../Modal/ModalAjouterRendezVous.js";

const RendezVousClient = ({ client }) => {
  const { state: stateAuth } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [previousActions, setPreviousActions] = useState([]);
  const [nextActions, setNextActions] = useState([]);

  const getActions = useCallback(async () => {
    setIsLoading(true);

    const actionsClient = await fetchApiImmodesk(
      `/private/contacts/${client.id}/actualites?action_id=2,3`,
      {},
      stateAuth.token
    );

    filterActions(actionsClient);

    setIsLoading(false);
  }, [client, stateAuth]);

  const filterActions = (actionsClient) => {
    const currentDate = new Date();
    const tmpPreviousAction = [];
    const tmpNextAction = [];

    actionsClient.forEach((action) => {
      if (!action || !action.date_echeance || !action.date_echeance.date) {
        return;
      }
      if (new Date(action.date_echeance.date) >= currentDate) {
        tmpNextAction.push(action);
      } else {
        tmpPreviousAction.push(action);
      }
    });

    setPreviousActions(tmpPreviousAction);
    setNextActions(tmpNextAction);
  };

  useEffect(() => {
    if (client && client.id) {
      getActions();
    }
    // eslint-disable-next-line
  }, [client]);

  const handleAction = (type, action) => {
    if (type && type === "delete") {
      const actionIndexPrevious = previousActions.findIndex(
        (a) => a.id === action.id
      );

      if (actionIndexPrevious !== -1) {
        const newPreviousActions = [...previousActions];
        newPreviousActions.splice(actionIndexPrevious, 1);
        setPreviousActions(newPreviousActions);
        return;
      }

      const actionIndexNext = nextActions.findIndex((a) => a.id === action.id);
      if (actionIndexNext !== -1) {
        const newNextActions = [...nextActions];
        newNextActions.splice(actionIndexNext, 1);
        setNextActions(newNextActions);
        return;
      }
    } else if (type && type === "update") {
      updateAction(action);
      return;
    }
  };

  const updateAction = (action) => {
    const newActions = [...previousActions, ...nextActions];
    const index = newActions.findIndex((a) => a.id === action.id);

    if (index === -1) {
      return;
    }

    newActions[index] = action;
    filterActions(newActions);
  };

  return (
    <Box>
      <ModalAjouterRendezVous clientId={client.id} callback={getActions} />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box sx={{ marginBottom: "64px", marginTop: "32px" }}>
            <RendezVousItems
              isLoading={isLoading}
              label="Prochain(s) rendez-vous"
              items={nextActions}
              callback={handleAction}
            />
          </Box>
          <Box>
            <RendezVousItems
              isLoading={isLoading}
              label="Historique des rendez-vous"
              items={previousActions}
              callback={handleAction}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default RendezVousClient;

import React, {useEffect, useState} from 'react';
import {useAuth} from "../../../Contexts/useAuth";
import {Box, Button, TextField, Typography} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {formatDate} from "../../../Utils/utils";

const FormEvenementProgramme = ({programme, initEvenement, callback}) => {
    const {state: stateAuth} = useAuth();
    const categorieIdActuCalendrier = 4;

    const title = initEvenement && initEvenement.id ? 'Modifier un événement' : 'Ajouter un événement';

    const [evenement, setEvenement] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState(null);

    useEffect(() => {
        if (initEvenement) {
            if (initEvenement.date && initEvenement.date.date) {
                setDate(new Date(initEvenement.date.date));
            }
            setEvenement(initEvenement);
        } else {
            setEvenement({
                titre: '',
                programme_id: programme.id,
                categorie_id: categorieIdActuCalendrier,
            });
        }
    }, [initEvenement, programme])

    const handleSubmit = async () => {
        const formatEvenement = {...evenement};
        // L'API attend une date au format Y-m-d H:i:s
        formatEvenement.date = formatDate(date);

        setIsLoading(true);
        const dataEvenement = await fetchApiImmodesk(`/private/programmes/actualites`, {
            method: formatEvenement.id ? "PUT" : "POST",
            body: JSON.stringify(formatEvenement)
        }, stateAuth.token)
        setIsLoading(false);

        if (dataEvenement.error) {
            // TODO error
            return;
        }

        if (typeof callback === 'function') {
            callback(dataEvenement);
        }
    }

    return (
        <>
            {evenement &&
                <>
                    <Typography variant="h6"
                                component="h2"
                                sx={{marginBottom: "20px"}}>{title}</Typography>

                    <TextField label="Titre"
                               size="small"
                               fullWidth
                               value={evenement.titre}
                               onChange={(e) => setEvenement({...evenement, titre: e.target.value})}
                               variant="outlined"
                               sx={{marginTop: "20px", marginBottom: "20px"}}/>

                    <DatePicker
                        label="Date de l'événement"
                        value={date}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                            setDate(newValue);
                        }}
                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                    />

                    <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                        <Button variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={isLoading || !date || !evenement.titre}>
                            {isLoading &&
                                <CircularProgress size="20px"
                                                  color="secondary"
                                                  sx={{marginRight: '10px'}}/>}
                            <Box component="span">Enregistrer</Box>
                        </Button>
                    </Box>
                </>
            }
        </>
    );
};

export default FormEvenementProgramme;

import {createContext, useContext, useReducer} from "react";

const NotificationsContext = createContext();

function authReducer(state, action) {
    const {type, payload} = action;

    switch (type) {
        case 'setNotifications': {
            return {...payload};
        }
        case 'addNotificationClient': {
            const newPayload = {...state};
            newPayload.notificationsClients.Items.push(payload);
            return newPayload
        }
        case 'updateNotificationClient': {
            const newNotifications = {...state};
            const index = newNotifications.notificationsClients.Items.findIndex(n => n.id === payload.id);
            if(index === -1) {
                return;
            }
            newNotifications.notificationsClients.Items[index] = payload;
            return newNotifications;
        }
        case 'updateNotificationsClient': {
            if(!Array.isArray(payload)){
                return;
            }
            const newNotifications = {...state};
            payload.forEach((notification) => {
                const index = newNotifications.notificationsClients.Items.findIndex(n => n.id === notification.id);
                if(index === -1) {
                    return;
                }
                newNotifications.notificationsClients.Items[index] = notification;
            });
            return newNotifications;
        }
        default:
            return state;
    }
}

function NotificationsProvider({ children }) {
    const [state, dispatch] = useReducer(authReducer, {});
    return <NotificationsContext.Provider value={{state, dispatch}}>{children}</NotificationsContext.Provider>;
}

function useNotifications() {
    const context = useContext(NotificationsContext)
    if (context === undefined) {
        throw new Error('useNotifications must be used within a NotificationsProvider')
    }
    return context;
}

function NotificationsConsumer({children}) {
    return (
        <NotificationsContext.Consumer>
            {context => {
                if (context === undefined) {
                    throw new Error('NotificationsConsumer must be used within a NotificationsProvider')
                }
                return children(context)
            }}
        </NotificationsContext.Consumer>
    );
}

export {useNotifications, NotificationsProvider, NotificationsConsumer}


import React, {useEffect, useState} from 'react';
import {Button, Divider, Menu, MenuItem, Typography} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import {fetchApiConfigurateur} from "../../../Api/Api_configurateur";
import {useAuth} from "../../../Contexts/useAuth";
import ModalComponent from "../../Common/Modal/ModalComponent";
import FormUploadTma from "../Forms/FormUploadTma";
import SignatureChoixTma from "../Signatures/SignatureChoixTma";
import EmbedDocumentPdf from "../EmbedDocumentPdf";

const ActionsLot = ({lot, callback, error}) => {
    const {state: stateAuth} = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState();
    const [messageModal, setMessageModal] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

    const openMenu = Boolean(anchorEl);

    useEffect(() => {
        if (!action) {
            return;
        }

        const {type} = action;

        switch (type) {
            case 'annuler-tma':
                setMessageModal(`Souhaitez-vous annuler la fiche TMA du lot ${lot.name}`);
                break;
            case 'annuler-choix':
                setMessageModal(`Souhaitez-vous annuler la fiche de choix du lot ${lot.name}`);
                break;
            default:
                setMessageModal('');
        }
    }, [action, lot])

    const handleMenuItem = (action) => {
        setAction(action);
        setAnchorEl(null);

        if (action.modal === "modal-confirmation") {
            setOpenModalConfirmation(true);
        } else {
            setOpenModal(true);
        }
    }


    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseModal = () => {
        setOpenModalConfirmation(false);
    }

    const handleCloseModalComponent = () => {
        const {type} = action;

        switch (type) {
            case "signature-choix":
                setOpenModal(false);
                if (typeof callback === "function") {
                    callback();
                }
                return;
            case "signature-tma":
                setOpenModal(false);
                if (typeof callback === "function") {
                    callback();
                }
                return;
            case "upload-tma":
                setOpenModal(false);
                if (typeof callback === "function") {
                    callback();
                }
                return;
            default:
                setOpenModal(false)
        }
    }

    const handleConfirmationModal = () => {
        const {type} = action;

        switch (type) {
            case 'annuler-tma':
                annulerConfiguration(lot.configuration_tma_id, lot.configuration_tma_configurations_signatures_sign_data !== null, lot && lot.documents_tma && lot.documents_tma.ficheTmaArchitecte);
                return;
            case 'annuler-choix':
                annulerConfiguration(lot.configuration_deco_id, lot.configuration_choix_configurations_signatures_sign_data !== null, false);
                return;
            default:
                return false;
        }
    }

    const annulerConfiguration = async (configurationId, hasSignature, deleteFicheTmaArchitecte) => {
        setIsLoading(true);
        if(hasSignature) {
            await fetchApiConfigurateur(`/protected/clients/configurations/${configurationId}/signatures`, {'method': 'DELETE'}, stateAuth.token);
        }

        if(deleteFicheTmaArchitecte) {
            await fetchApiConfigurateur(`/private/documents/tma/configurations/${configurationId}/promoteur`, {'method': 'DELETE'}, stateAuth.token);
        }

        const configurationApi = await fetchApiConfigurateur(`/private/configurations/${configurationId}/unfinalize`, {'method': 'PUT'}, stateAuth.token);

        setIsLoading(false);
        setOpenModalConfirmation(false);

        if (!configurationApi.id && configurationApi.message) {
            if (configurationApi.message === 'Configuration not found') {
                error(`La fiche n'existe pas, veuillez actualiser la page.`);
                return;
            }
            error(configurationApi.message);
            return;
        }

        if (typeof callback === "function") {
            callback();
        }
    }

    const getSizeModal = () => {
        if(!action) {
            return 'default';
        }

        const {modal} = action;

        switch (modal) {
            case 'modal-fiche-signature-tma':
            case 'modal-fiche-signature-choix':
                return 'lg'
            default:
                return 'default'
        }
    }

    const sx = {
        menuItem: {
            fontSize: '0.8rem'
        }
    }

    return (
        <>
            <Button
                id="actions-lot-button"
                aria-controls={openMenu ? 'actions-lot-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <MoreHorizIcon sx={{transform: 'rotate(90deg)'}}/>
            </Button>
            <Menu
                id="actions-lot-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleMenuItem({type: 'annuler-tma', modal: 'modal-confirmation'})}
                          sx={sx.menuItem}
                          disabled={!lot.configuration_tma_id}>Annuler TMA</MenuItem>
                <MenuItem onClick={() => handleMenuItem({type: 'upload-tma', modal: 'modal-upload-tma'})}
                          sx={sx.menuItem}
                          disabled={!lot.configuration_tma_id}>Intégrer TMA modifiée</MenuItem>
                <MenuItem onClick={() => handleMenuItem({type: 'signature-tma', modal: 'modal-signature-tma'})}
                          sx={sx.menuItem}
                          disabled={!lot.documents_tma || !lot.documents_tma.ficheTmaArchitecte || lot.configuration_tma_configurations_signatures_sign_data}>Signature TMA</MenuItem>

                <MenuItem onClick={() => handleMenuItem({type: 'fiche-signature-tma', modal: 'modal-fiche-signature-tma'})}
                          sx={sx.menuItem}
                          disabled={!lot.documents_tma || !lot.documents_tma.signed}>Voir la fiche TMA signée</MenuItem>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <MenuItem onClick={() => handleMenuItem({type: 'annuler-choix', modal: 'modal-confirmation'})}
                          sx={sx.menuItem}
                          disabled={!lot.configuration_deco_id}>Annuler choix</MenuItem>
                <MenuItem onClick={() => handleMenuItem({type: 'signature-choix', modal: 'modal-signature-choix'})}
                          sx={sx.menuItem}
                          disabled={!lot.configuration_deco_id || lot.configuration_choix_configurations_signatures_sign_data}>Signature des choix</MenuItem>

                <MenuItem onClick={() => handleMenuItem({type: 'fiche-signature-choix', modal: 'modal-fiche-signature-choix'})}
                          sx={sx.menuItem}
                          disabled={!lot.documents_deco || !lot.documents_deco.signed}>Voir la fiche choix signée</MenuItem>
            </Menu>

            <ModalConfirmation openModal={openModalConfirmation}
                               handleConfirm={handleConfirmationModal}
                               handleClose={handleCloseModal}
                               loading={isLoading}
                               message={messageModal}/>

            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}
                            size={getSizeModal()}>
                {action && action.modal && action.modal === "modal-signature-choix" &&
                    <>
                        <Typography variant="h6"
                                    component="h2"
                                    sx={{marginBottom: "20px"}}>Signature des choix</Typography>
                        <SignatureChoixTma configurationId={lot.configuration_deco_id}
                                           type="choix"
                                           callback={handleCloseModalComponent}/>
                    </>
                }

                {action && action.modal && action.modal === "modal-upload-tma" &&
                    <>
                        <Typography variant="h6"
                                    component="h2"
                                    sx={{marginBottom: "20px"}}>Intégrer une fiche TMA modifiée (au format pdf)</Typography>
                        <FormUploadTma configurationId={lot.configuration_tma_id}
                                       callback={handleCloseModalComponent}/>
                    </>
                }

                {action && action.modal && action.modal === "modal-signature-tma" &&
                    <>
                        <Typography variant="h6"
                                    component="h2"
                                    sx={{marginBottom: "20px"}}>Signature fiche TMA</Typography>
                        <SignatureChoixTma configurationId={lot.configuration_tma_id}
                                           type="tma"
                                           callback={handleCloseModalComponent}/>
                    </>
                }

                {action && action.modal && action.modal === "modal-fiche-signature-tma" &&
                    <>
                        <Typography variant="h6"
                                    component="h2"
                                    sx={{marginBottom: "20px"}}>Fiche TMA signée</Typography>
                        <EmbedDocumentPdf url={lot.documents_tma.signed}/>
                    </>
                }

                {action && action.modal && action.modal === "modal-fiche-signature-choix" &&
                    <>
                        <Typography variant="h6"
                                    component="h2"
                                    sx={{marginBottom: "20px"}}>Fiche choix signée</Typography>
                        <EmbedDocumentPdf url={lot.documents_deco.signed}/>
                    </>
                }
            </ModalComponent>
        </>
    );
};

export default ActionsLot;

import React, {useState} from 'react';
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import {Button} from "@mui/material";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";

const ModalReactiverEspaceClient = ({clientId, callback}) => {
    const {state: stateAuth} = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleConfirmation = async () => {
        setIsLoading(true);

        const data = await fetchApiImmodesk(`/private/contacts/${clientId}/reactiver`, {
            method: 'POST',
            body: JSON.stringify({}),
        }, stateAuth.token);

        setIsLoading(false);

        if (data.error) {
            // TODO error
            return;
        }

        if(typeof callback === 'function') {
            callback(data);
        }
    }

    return (
        <>
            <Button variant="outlined"
                    color="secondary"
                    onClick={() => setOpenModal(true)}>
                Réactiver
            </Button>

            <ModalConfirmation openModal={openModal}
                               handleConfirm={handleConfirmation}
                               handleClose={() => setOpenModal(false)}
                               loading={isLoading}
                               message={`Souhaitez-vous réactiver l'espace client ?`}/>
        </>
    );
};

export default ModalReactiverEspaceClient;

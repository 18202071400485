import React, {useState} from 'react';
import {Button} from "@mui/material";
import ModalComponent from "../../Common/Modal/ModalComponent";
import DeposerDocumentProgramme from "../Documents/DeposerDocumentProgramme";

const ModalDeposerDocumentProgramme = ({programmeId, callback}) => {
    const [openModal, setOpenModal] = useState(false);

    const callbackModal = () => {
        setOpenModal(false);
        if(typeof callback === 'function') {
            callback();
        }
    }

    return (
        <>
            <Button variant="outlined"
                    color="secondary"
                    onClick={() => setOpenModal(true)}>
                Déposer un document
            </Button>

            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}>

                <DeposerDocumentProgramme programmeId={programmeId}
                                       callback={callbackModal}/>

            </ModalComponent>
        </>
    );
};

export default ModalDeposerDocumentProgramme;

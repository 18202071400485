import { Button } from "@mui/material";
import React, { useState } from "react";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import { useAuth } from "../../../Contexts/useAuth";
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";

const ModalDesactiverEspaceClient = ({ clientId, callback }) => {
  const {state: stateAuth} = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleConfirmation = async () => {
    setIsLoading(true);

	const data = await fetchApiImmodesk(`/private/contacts/${clientId}/desactiver`, {
		method: 'POST',
		body: JSON.stringify({}),
	}, stateAuth.token);

	setIsLoading(false);

	if (data.error) {
		// TODO error
		return;
	}

	setOpenModal(false);

	if(typeof callback === 'function') {
		callback(data);
	}
  };

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        onClick={() => setOpenModal(true)}
      >
        Désactiver
      </Button>

      <ModalConfirmation
        openModal={openModal}
        handleConfirm={handleConfirmation}
        handleClose={() => setOpenModal(false)}
        loading={isLoading}
        message={`Souhaitez-vous désactiver l'espace client ?`}
      />
    </>
  );
};

export default ModalDesactiverEspaceClient;

import React, { useState } from "react";
import { useAuth } from "../../../Contexts/useAuth";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import ModalComponent from "../../Common/Modal/ModalComponent";
import FormEnvoyerEmail from "../Emails/FormEnvoyerEmail";
import { Button, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

const ModalRelancerClientsDemandeDocumentsForm = ({ clientId, disabled, variant = "contained" }) => {
  const { state: stateAuth } = useAuth();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initMessage =
    "Nous sommes toujours en attente d'un ou de plusieurs documents concernant votre acquisition.\nNous vous invitons dès à présent à vous connecter sur votre espace client afin de nous les envoyer.\nNous vous en remercions.";

  const handleSendMessage = async ({ sujet, message }) => {
    setIsLoading(true);

    const newMessage = message.replaceAll("\n", "<br/>");

    const data = await fetchApiImmodesk(
      "/private/messages",
      {
        method: "POST",
        body: JSON.stringify({
          sujet,
          message: newMessage,
          contact_id: clientId,
        }),
      },
      stateAuth.token
    );

    if (data && data.error) {
      //TODO error
      return;
    }

    setOpenModal(false);
    setIsLoading(false);
  };

  return (
    <>
      <Button
        variant={variant}
        color="secondary"
        disabled={disabled}
        onClick={() => setOpenModal(true)}
      >
        <EmailIcon sx={{ marginRight: "10px" }} /> Relancer par email
      </Button>
      <ModalComponent
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
      >
        <Typography variant="h6" component="h2" sx={{ marginBottom: "20px" }}>
          Relancer par email
        </Typography>
        <FormEnvoyerEmail
          initSujet="Document(s) en attente"
          initMessage={initMessage}
          actionSubmit={handleSendMessage}
          isLoading={isLoading}
        />
      </ModalComponent>
    </>
  );
};

export default ModalRelancerClientsDemandeDocumentsForm;

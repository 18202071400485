import React from "react";
import NotificationMenuItem from "./NotificationMenuItem.js";

const NotificationNewDocumentCustomerAvailable = ({
  notification,
  handleClickCallback,
  handleChangeCallback,
}) => {
  const date = new Date(parseInt(notification.creationDate) * 1000);

  return (
    <NotificationMenuItem
      notification={notification}
      date={date}
      title={notification?.params?.sujet || "Nouveau document disponible"}
      message={notification?.params?.message}
      handleClickCallback={handleClickCallback}
      handleChangeCallback={handleChangeCallback}
    />
  );
};

export default NotificationNewDocumentCustomerAvailable;

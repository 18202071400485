import React, {useState} from 'react';
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import {Button} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export function BtnDeleteActualite({actualite, callback}) {
    const {state: stateAuth} = useAuth();
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const deleteActualite = async () => {
        setIsLoading(true);
        await fetchApiImmodesk(`/private/programmes/actualites/${actualite.id}`,
            {
                method: 'DELETE',
            },
            stateAuth.token
        );

        setIsLoading(false);
        setOpenModal(false);

        if(typeof callback === 'function') {
            callback("delete", actualite);
        }
    }

    return (
        <>
            <Button variant="outlined"
                    size="small"
                    color="error"
                    sx={{minWidth: 'unset'}}
                    onClick={() => setOpenModal(true)}>
                <DeleteOutlineIcon fontSize="small"/>
            </Button>
            <ModalConfirmation openModal={openModal}
                               handleConfirm={deleteActualite}
                               handleClose={() => setOpenModal(false)}
                               loading={isLoading}
                               message="Supprimer l'actualité ?"/>
        </>
    );
}

import React, {useState} from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import {Box, Button} from "@mui/material";

const ButtonDownload = ({disabled, download, label}) => {
    const [downloadLoading, setDownloadLoading] = useState(false);

    const handleDownload = async () => {
        setDownloadLoading(true);
        await download();
        setDownloadLoading(false);
    }

    return (
        <Button variant="contained"
                size="small"
                color="secondary"
                disabled={disabled || downloadLoading}
                onClick={handleDownload}>{downloadLoading ? <CircularProgress size="20px" color="secondary"/> : <DownloadIcon sx={{width: '20px'}}/>}<Box component="span" sx={{marginLeft: '10px'}}>{label}</Box></Button>
    );
};

export default ButtonDownload;

import React, {useState} from 'react';
import {
    Box,
    Button,
} from "@mui/material";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import Loading from "../../Common/Loading";

const AddMediaProgrammeCatTravaux = ({programmeId, callback}) => {
    const {state: stateAuth} = useAuth();

    const categorieIdTravaux = 160;

    const [isLoading, setIsLoading] = useState(false);

    const handleChangeFile = async (event) => {
        if (!event || !event.target.files || !event.target.files[0]) {
            return;
        }
        ;

        const file = event.target.files[0];
        const formData = new FormData();

        formData.append('is_public', true);
        formData.append('categorie_id', categorieIdTravaux);
        formData.append('file', file, file.name);

        setIsLoading(true);

        const dataMedia = await fetchApiImmodesk(`/private/programmes/${programmeId}/medias`, {
            method: "POST",
            type: "file",
            body: formData
        }, stateAuth.token);

        setIsLoading(false);

        if (dataMedia.error) {
            // TODO error
            return;
        }

        if (typeof callback === 'function') {
            callback(dataMedia);
        }
    }

    return (
        <>
            {isLoading && <Loading sizeCircularProgress="20px"/>}
            {!isLoading &&
                <Box sx={{justifyContent: "center", display: "flex", marginBottom: "20px"}}>
                    <label htmlFor="contained-button-file">
                        <input id="contained-button-file"
                               type="file"
                               accept=".jpeg,.jpg,.png"
                               onChange={handleChangeFile}
                               style={{display: "none"}}/>
                        <Button variant="contained"
                                color="secondary"
                                component="span">
                            Choisir une image
                        </Button>
                    </label>
                </Box>
            }
        </>
    );
};

export default AddMediaProgrammeCatTravaux;

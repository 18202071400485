import React, {useEffect, useState} from 'react';
import {Box, Button, MenuItem, TextField, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import {Alert} from "@mui/lab";
import CustomMuiSelect from '../../CustomMui/CustomMuiSelect';

const FormClient = ({initClient, callback}) => {
    const {state: stateAuth} = useAuth();

    const [client, setClient] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const {handleSubmit, register, formState: {errors}, reset} = useForm({});

    useEffect(() => {
        if (!initClient) {
            setClient(null);
            reset();
            return;
        }
        setClient({...initClient});
        reset();
    }, [initClient, reset])

    const onSubmit = async () => {
        setIsLoading(true);

        const dataClient = await fetchApiImmodesk(`/private/contacts/${client.id}`, {
            method: 'PUT',
            body: JSON.stringify(client)
        }, stateAuth.token);

        setIsLoading(false);

        if(dataClient.error) {
            // TODO error
            return;
        }

        if(typeof callback === 'function') {
            callback(dataClient);
        }
    }

    return (
       <>
            {client &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{display: "grid", gap: "15px", gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(5, 1fr)"], marginBottom: "15px"}}>
                        <CustomMuiSelect
                            id="client-civilite"
                            value={client.civilite || ""}
                            label="Civilité"
                            defaultValue={"M"}
                            onChange={(event) => setClient({...client, civilite: event.target.value})}>
                            <MenuItem value="M">M.</MenuItem>
                            <MenuItem value="F">Mme</MenuItem>
                            <MenuItem value="MF">M. et Mme</MenuItem>
                        </CustomMuiSelect>

                        <TextField
                            id="client-nom"
                            label="Nom"
                            name="nom"
                            required
                            value={client?.nom || ""}
                            {...register("client.nom", {
                                required: "Le nom est obligatoire.",
                            })}
                            InputLabelProps={{
                                shrink: client?.nom ? true : false
                            }}
                            onChange={(event) => setClient({...client, nom: event.target.value})}
                        />

                        <TextField
                            id="client-prenom"
                            label="Prénom"
                            name="prenom"
                            required
                            value={client?.prenom || ""}
                            {...register("client.prenom", {
                                required: "Le prénom est obligatoire.",
                            })}
                            InputLabelProps={{
                                shrink: client?.prenom ? true : false
                            }}
                            onChange={(event) => setClient({...client, prenom: event.target.value})}
                        />

                        <TextField
                            id="client-tel"
                            label="Téléphone"
                            name="tel"
                            value={client?.tel || ""}
                            InputLabelProps={{
                                shrink: client?.tel ? true : false
                            }}
                            onChange={(event) => setClient({...client, tel: event.target.value})}
                        />

                        <TextField
                            id="client-tel_mobile"
                            label="Téléphone mobile"
                            name="tel"
                            value={client?.tel_mobile || ""}
                            InputLabelProps={{
                                shrink: client?.tel_mobile ? true : false
                            }}
                            onChange={(event) => setClient({...client, tel_mobile: event.target.value})}
                        />

                        <TextField
                            id="client-email"
                            label="Email"
                            name="email"
                            required
                            value={client?.email || ""}
                            {...register("client.email", {
                                required: "L'email est obligatoire.",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Entrer une adresse email valide."
                                }
                            })}
                            InputLabelProps={{
                                shrink: client?.email ? true : false
                            }}
                            onChange={(event) => setClient({...client, email: event.target.value})}
                        />

                        <TextField
                            id="client-adresse"
                            label="Adresse"
                            name="adresse1"
                            value={client?.adresse1 || ""}
                            InputLabelProps={{
                                shrink: client?.adresse1 ? true : false
                            }}
                            onChange={(event) => setClient({...client, adresse1: event.target.value})}
                        />

                        <TextField
                            id="client-adresse2"
                            label="Complément d'adresse"
                            name="adresse2"
                            value={client?.adresse2 || ""}
                            InputLabelProps={{
                                shrink: client?.adresse2 ? true : false
                            }}
                            onChange={(event) => setClient({...client, adresse2: event.target.value})}
                        />

                        <TextField
                            id="client-ville"
                            label="Ville"
                            name="ville"
                            value={client?.ville || ""}
                            InputLabelProps={{
                                shrink: client?.ville ? true : false
                            }}
                            onChange={(event) => setClient({...client, ville: event.target.value})}
                        />

                        <TextField
                            id="client-cp"
                            label="Code postal"
                            name="cp"
                            value={client?.cp || ""}
                            InputLabelProps={{
                                shrink: client?.cp ? true : false
                            }}
                            onChange={(event) => setClient({...client, cp: event.target.value})}
                        />
                    </Box>

                    <TextField
                        id="client-complement-situation-note"
                        fullWidth
                        multiline
                        rows={5}
                        label="Note"
                        name="complement_situation_note"
                        value={client?.complement_situation_note || ""}
                        InputLabelProps={{
                            shrink: client?.complement_situation_note ? true : false
                        }}
                        onChange={(event) => setClient({...client, complement_situation_note: event.target.value})}
                    />

                    <Typography variant="caption">* champ obligatoire</Typography>

                    {errors && Object.keys(errors).length > 0 &&
                        <Box sx={{display: "grid", gap: "5px", gridTemplateColumns: "1fr", marginTop: "10px"}}>
                            {errors?.client?.nom && <Alert severity="warning">{errors.client.nom.message}</Alert>}
                            {errors?.client?.prenom && <Alert severity="warning">{errors.client.prenom.message}</Alert>}
                            {errors?.client?.email && <Alert severity="warning">{errors.client.email.message}</Alert>}
                        </Box>
                    }

                    <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                        <Button variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isLoading}>
                            {isLoading &&
                                <CircularProgress size="20px"
                                                color="secondary"
                                                sx={{marginRight: '10px'}}/>}
                            <Box component="span">Valider</Box>
                        </Button>
                    </Box>
                </form>
            }
        </>
    );
};

export default FormClient;

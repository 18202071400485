import React, { useState } from "react";
import {Button, Link} from '@mui/material'
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import { useAuth } from "../../../Contexts/useAuth";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";

const RelancerClientsDemandeDocuments = ({clientsId, variant="link", messageConfirmation, buttonLabel}) => {
  const {state: stateAuth} = useAuth();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmationModal = async () => {
    setIsLoading(true);

    const data = await fetchApiImmodesk('/private/messages', {
      method: 'POST',
      body: JSON.stringify({
          sujet: "Document(s) en attente",
          message: "Nous sommes toujours en attente d'un ou de plusieurs documents concernant votre acquisition. <br />Nous vous invitons dès à présent à vous connecter sur votre espace client afin de nous les envoyer. <br />Nous vous en remercions.",
          contact_id: clientsId.join(',')
      })
    }, stateAuth.token);

    if(data && data.error) {
      //TODO error
      return;
    }

    setOpenModal(false);
    setIsLoading(false);
  };

  const disabled = clientsId.length === 0;

  return (
    <>
        {variant === 'link' && (
          <Link
            component="button"
            sx={{ fontSize: "1rem", pointerEvents: disabled ? "none" : "auto", color: disabled ? "grey.500" : "primary.main" }}
            disabled={disabled}
            onClick={() => setOpenModal(true)}
          >
              {buttonLabel}
          </Link>
        )}

        {variant === 'button' && (
            <Button variant="outlined"
                    color="secondary"
                    disabled={disabled}
                    onClick={() => setOpenModal(true)}>{buttonLabel}</Button>
        )}
      <ModalConfirmation
        openModal={openModal}
        handleConfirm={handleConfirmationModal}
        handleClose={() => setOpenModal(false)}
        loading={isLoading}
        message={messageConfirmation}
      />
    </>
  );
};

export default RelancerClientsDemandeDocuments;

import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, TextField, Typography} from "@mui/material";
import DatePicker from '@mui/lab/DatePicker';
import SelectMediasProgramme from "../Medias/SelectMediasProgramme";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import {useAuth} from "../../../Contexts/useAuth";
import {formatDate} from "../../../Utils/utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loading from "../../Common/Loading";
import AddMediaProgrammeCatTravaux from "../Medias/AddMediaProgrammeCatTravaux";

const FormActualiteProgramme = ({programme, initActualite, callback}) => {
    const {state: stateAuth} = useAuth();
    const categorieIdAvanceeTravaux = 3;

    const title = initActualite && initActualite.id ? 'Modifier une actualité' : 'Ajouter une actualité';

    const [actualite, setActualite] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMedias, setIsLoadingMedias] = useState(true);
    const [date, setDate] = useState(null);
    const [medias, setMedias] = useState([]);
    const [mediasSelected, setMediasSelected] = useState([]);

    const fetchMedias = useCallback(async () => {
        setIsLoadingMedias(true);

        if(initActualite) {
            const dataMediasActualite = await fetchApiImmodesk(`/private/programmes/actualites/medias?actualite_id=${initActualite.id}`, {}, stateAuth.token);
            setMediasSelected(dataMediasActualite);
        }

        const dataMedias = await fetchApiImmodesk(`/private/programmes/${programme.id}/images`, {}, stateAuth.token);

        setMedias(dataMedias);
        setIsLoadingMedias(false);
    }, [programme, stateAuth, initActualite])

    useEffect(() => {
        if (initActualite) {
            if (initActualite.date && initActualite.date.date) {
                setDate(new Date(initActualite.date.date));
            }
            setActualite(initActualite);
        } else {
            setActualite({
                programme_id: programme.id,
                categorie_id: categorieIdAvanceeTravaux,
                titre: '',
                texte: '',
                medias: ''
            });
        }
        fetchMedias();
    }, [initActualite, programme, fetchMedias])

    const handleSubmit = async () => {
        const formatActualite = {...actualite, medias: ''};
        // L'API attend une date au format Y-m-d H:i:s
        formatActualite.date = formatDate(date);

        // Ajout des medias sous la forme "1,2,3"
        if (mediasSelected.length > 0) {
            formatActualite.medias = mediasSelected.reduce((prev, current) => [...prev, current.id], []).join(',');
        }

        setIsLoading(true);
        const fetchActualite = await fetchApiImmodesk(`/private/programmes/actualites`, {
            method: formatActualite.id ? "PUT" : "POST",
            body: JSON.stringify(formatActualite)
        }, stateAuth.token)
        setIsLoading(false);

        if (!fetchActualite || !fetchActualite.id) {
            // TODO error
            return;
        }

        if (typeof callback === 'function') {
            callback(fetchActualite);
        }
    }

    const handleAddMedia = (media) => {
        if(!media) {
            return;
        }
        const mediasNew = [...medias, media];
        const mediasSelectedNew = [...mediasSelected, media];
        setMedias(mediasNew);
        setMediasSelected(mediasSelectedNew);
    }

    const handleAddMediaSelected = (media) => {
        const newMediasSelected = [...mediasSelected];
        const mediaIndex = newMediasSelected.findIndex(m => m.id === media.id);

        if (mediaIndex === -1) {
            newMediasSelected.push(media);
        } else {
            newMediasSelected.splice(mediaIndex, 1);
        }

        setMediasSelected(newMediasSelected);
    }

    return (
        <>
            {actualite &&
                <Box>
                    <Typography variant="h6"
                                component="h2"
                                sx={{marginBottom: "20px"}}>{title}</Typography>

                    <TextField label="Titre"
                               size="small"
                               fullWidth
                               value={actualite.titre}
                               onChange={(e) => setActualite({...actualite, titre: e.target.value})}
                               variant="outlined"
                               sx={{marginTop: "20px", marginBottom: "20px"}}/>

                    <DatePicker
                        label="Date de l'actualité"
                        value={date}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                            setDate(newValue);
                        }}
                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                    />

                    <Box sx={{marginTop: "20px"}}>
                        <ReactQuill theme="snow"
                                    value={actualite.texte}
                                    onChange={(value) => setActualite({...actualite, texte: value})}/>
                    </Box>



                    <Box sx={{marginTop: "40px"}}>
                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <Typography sx={{marginBottom: "10px"}}>Associer un média</Typography>
                            <Box sx={{minWidth: "160px"}}>
                                <AddMediaProgrammeCatTravaux programmeId={programme.id}
                                                             callback={handleAddMedia}/>
                            </Box>
                        </Box>

                        {isLoadingMedias && <Loading/>}
                        {!isLoadingMedias && medias &&
                            <Box sx={{maxHeight: "200px", overflowY: "auto"}}>
                                <SelectMediasProgramme medias={medias}
                                                       mediasSelected={mediasSelected}
                                                       handleAddMediaSelected={handleAddMediaSelected}/>
                            </Box>
                        }
                    </Box>

                    <Box sx={{display: "flex", justifyContent: "end", marginTop: "20px"}}>
                        <Button variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={isLoading || !date || !actualite.titre}>
                            {isLoading &&
                                <CircularProgress size="20px"
                                                  color="secondary"
                                                  sx={{marginRight: '10px'}}/>}
                            <Box component="span">Enregistrer</Box>
                        </Button>
                    </Box>

                </Box>
            }
        </>
    );
};

export default FormActualiteProgramme;

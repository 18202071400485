import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useAuth } from "../../../Contexts/useAuth";
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import ModalComponent from "../../Common/Modal/ModalComponent";
import DeposerDocumentClientDemande from "./DeposerDocumentClientDemande";
import FormEnvoyerEmail from "../Emails/FormEnvoyerEmail";

const ActionsDocumentsClientDemande = ({ document, clientId, callback }) => {
  const { state: stateAuth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState();
  const [messageModal, setMessageModal] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    if (!action) {
      return;
    }

    const { type } = action;

    switch (type) {
      case "delete-demande":
        setMessageModal(
          `Supprimer la demande de document "${document.media_categorie_label}"`
        );
        break;
      case "relancer-demande":
        if (document && document.date_creation && document.date_creation.date) {
          const newDate = new Date(document.date_creation.date);
          setMessageModal(
            `Bonjour,\nNous vous avons fait une demande de document à la date du ${newDate.toLocaleDateString(
              "fr-FR",
              { day: "numeric", month: "numeric", year: "numeric" }
            )} concernant un document de type ${document.media_categorie_label.toLowerCase()}.\nNous vous invitons dès à présent à vous connecter sur votre espace client afin de nous l'envoyer.\nNous vous en remercions.`
          );
        }
        break;
      default:
        setMessageModal("");
    }
  }, [action, document]);

  const handleMenuItem = (action) => {
    setAction(action);
    setAnchorEl(null);

    if (action.modal === "modal-confirmation") {
      setOpenModalConfirmation(true);
    } else {
      setOpenModal(true);
    }
  };

  const handleConfirmationModal = async () => {
    const { type } = action;

    switch (type) {
      case "delete-demande":
        deleteDemande();
        break;
      default:
        return;
    }
  };

  const handleCloseModalComponent = () => {
    const { type } = action;

    switch (type) {
      default:
        setOpenModal(false);
    }
  };

  const deleteDemande = async () => {
    setIsLoading(true);

    const dataDeleteDemande = await fetchApiImmodesk(
      `/private/documents/demandes/${document.id}?adv=true`,
      {
        method: "DELETE",
      },
      stateAuth.token
    );

    setIsLoading(false);

    if (dataDeleteDemande.error) {
      // TODO error
      return;
    }

    if (typeof callback === "function") {
      callback();
    }
  };

  const handleSendMessage = async ({ sujet, message }) => {
    setIsLoading(true);

    const newMessage = message.replaceAll("\n", "<br/>");

    const data = await fetchApiImmodesk(
      "/private/messages",
      {
        method: "POST",
        body: JSON.stringify({
          sujet,
          message: newMessage,
          contact_id: clientId,
        }),
      },
      stateAuth.token
    );

    if (data && data.error) {
      //TODO error
      return;
    }

    setOpenModal(false);
    setIsLoading(false);
  };

  const sx = {
    menuItem: {
      fontSize: "0.8rem",
      color: "#000",
      a: {
        textDecoration: "none",
        fontSize: "0.8rem",
        color: "#000",
      },
    },
  };

  return (
    <>
      <Button
        id="actions-documents-client-demande--button"
        aria-controls={
          openMenu ? "actions-documents-client-demande-menu" : undefined
        }
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreHorizIcon sx={{ transform: "rotate(90deg)" }} />
      </Button>
      <Menu
        id="actions-documents-client-demande-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            handleMenuItem({
              type: "deposer-document",
              modal: "modal-deposer-document",
            })
          }
          sx={sx.menuItem}
        >
          Déposer un document
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleMenuItem({
              type: "relancer-demande",
              modal: "modal-relancer-demande",
            })
          }
          sx={sx.menuItem}
        >
          Relancer la demande
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleMenuItem({
              type: "delete-demande",
              modal: "modal-confirmation",
            })
          }
          sx={sx.menuItem}
        >
          Supprimer la demande
        </MenuItem>
      </Menu>

      <ModalConfirmation
        openModal={openModalConfirmation}
        handleConfirm={handleConfirmationModal}
        handleClose={() => setOpenModalConfirmation(false)}
        loading={isLoading}
        message={messageModal}
      />

      <ModalComponent
        openModal={openModal}
        handleClose={handleCloseModalComponent}
      >
        {action &&
          action.modal &&
          action.modal === "modal-deposer-document" && (
            <>
              <Typography
                variant="h6"
                component="h2"
                sx={{ marginBottom: "20px" }}
              >
                Déposer un document - {document.media_categorie_label}
              </Typography>

              <DeposerDocumentClientDemande
                document={document}
                clientId={clientId}
                callback={callback}
              />
            </>
          )}

        {action &&
          action.modal &&
          action.modal === "modal-relancer-demande" && (
            <>
              <Typography
                variant="h6"
                component="h2"
                sx={{ marginBottom: "20px" }}
              >
                Relancer la demande
              </Typography>
              <FormEnvoyerEmail
                initSujet="Document en attente"
                initMessage={messageModal}
                actionSubmit={handleSendMessage}
                isLoading={isLoading}
              />
            </>
          )}
      </ModalComponent>
    </>
  );
};

export default ActionsDocumentsClientDemande;

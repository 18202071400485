import React, {useState} from 'react';
import {Button} from "@mui/material";
import ModalComponent from "../../Common/Modal/ModalComponent";
import AddMediaProgramme from "../Medias/AddMediaProgramme";

const ModalAjouterMediaProgramme = ({programmeId, callback}) => {
    const [openModal, setOpenModal] = useState(false);

    const callbackModal = (media) => {
        setOpenModal(false);
        if(typeof callback === 'function') {
            callback(media);
        }
    }

    return (
        <>
            <Button variant="outlined"
                    color="secondary"
                    onClick={() => setOpenModal(true)}>
                Ajouter un média
            </Button>

            <ModalComponent openModal={openModal}
                            handleClose={() => setOpenModal(false)}>

                <AddMediaProgramme programmeId={programmeId}
                                   callback={callbackModal} />
            </ModalComponent>
        </>
    );
};

export default ModalAjouterMediaProgramme;

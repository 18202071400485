import React, {useEffect, useState} from 'react';
import {Button, Menu, MenuItem} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import {useAuth} from "../../../Contexts/useAuth";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import ModalComponent from "../../Common/Modal/ModalComponent";
import FormEvenementProgramme from "./FormEvenementProgramme";

const ActionsCalendrier = ({evenement, programme, callback}) => {
    const {state: stateAuth} = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState();
    const [messageModal, setMessageModal] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

    const openMenu = Boolean(anchorEl);

    useEffect(() => {
        if (!action) {
            return;
        }

        const {type} = action;

        switch (type) {
            case 'delete-evenement':
                setMessageModal(`Supprimer l'événement ?`);
                break;
            default:
                setMessageModal('');
        }
    }, [action])

    const handleMenuItem = (action) => {
        setAction(action);
        setAnchorEl(null);

        if(action.modal === "modal-confirmation") {
            setOpenModalConfirmation(true);
        }
        else {
            setOpenModal(true);
        }
    }

    const handleConfirmationModal = async () => {
        const {type} = action;

        switch (type){
            case 'delete-evenement':
                deleteEvenement();
                break;
            default:
                return;
        }
    }

    const handleCloseModalComponent = () => {
        const {type} = action;

        switch (type) {
            default:
                setOpenModal(false)
        }
    }

    const deleteEvenement = async () => {
        setIsLoading(true);
        await fetchApiImmodesk(`/private/programmes/actualites/${evenement.id}`,
            {
                method: 'DELETE',
            },
            stateAuth.token
        );

        setIsLoading(false);
        setOpenModalConfirmation(false);

        if(typeof callback === 'function') {
            callback("delete", evenement);
        }
    }

    const updateEvenement = (value) => {
        setOpenModal(false);

        if(typeof callback === 'function') {
            callback("update", value);
        }
    }

    const sx = {
        menuItem: {
            fontSize: '0.8rem',
            color: "#000",
            "a": {
                textDecoration: "none",
                fontSize: '0.8rem',
                color: "#000",
            }
        }
    }

    return (
        <>
            <Button
                id="actions-evenement-button"
                aria-controls={openMenu ? 'actions-evenement-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <MoreHorizIcon sx={{transform: 'rotate(90deg)'}}/>
            </Button>
            <Menu
                id="actions-evenement-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleMenuItem({type: 'edit-evenement', modal: 'modal-edit-evenement'})}
                          sx={sx.menuItem}
                          disabled={evenement.readonly}>Modifer
                </MenuItem>
                <MenuItem onClick={() => handleMenuItem({type: 'delete-evenement', modal: 'modal-confirmation'})}
                          sx={sx.menuItem}
                          disabled={evenement.readonly}>Supprimer
                </MenuItem>
            </Menu>

            <ModalConfirmation openModal={openModalConfirmation}
                               handleConfirm={handleConfirmationModal}
                               handleClose={() => setOpenModalConfirmation(false)}
                               loading={isLoading}
                               message={messageModal}/>

            <ModalComponent openModal={openModal}
                            handleClose={handleCloseModalComponent}>
                {action && action.modal && action.modal === "modal-edit-evenement" &&
                    <FormEvenementProgramme programme={programme}
                                            initEvenement={evenement}
                                            callback={updateEvenement}/>
                }
            </ModalComponent>
        </>
    );
};

export default ActionsCalendrier;

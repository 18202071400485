export const fetchApiConfigurateur = async (url, init = {}, token = false) => {

    let headers = new Headers();

    if (token) {
        headers = {Authorization:`Bearer ${token}`};
    }

    if (process.env.REACT_APP_ENV_LOCAL && process.env.REACT_APP_ENV_LOCAL === "true" && process.env.REACT_APP_X_ARKA_PROMOTEUR_ID && process.env.REACT_APP_X_ARKA_PROMOTEUR_ID !== "") {
        headers = {...headers, 'x-arka-promoteur-id': process.env.REACT_APP_X_ARKA_PROMOTEUR_ID};
    }

    if(init.body && init.type !== 'file') {
        headers = {...headers, 'Content-Length': init.body.length, 'Content-Type': 'application/json'};
    }

    init = {...init, headers}

    const response = await fetch(process.env.REACT_APP_API_CONFIGURATEUR + url, init)

    if (!response.ok) {
        console.error(`fetchApi error ${url}: `, response);
        return false;
    }

    if(response.status === 204) {
        return true;
    }

    return await response.json();
}





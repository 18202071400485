import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button, Menu, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../Contexts/useAuth';
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import {fetchApiImmodesk} from "../../../Api/Api_immodesk";
import ModalComponent from "../../Common/Modal/ModalComponent";
import DeposerDocumentProgramme from "./DeposerDocumentProgramme";

const ActionsDocumentsProgramme = ({document, callback}) => {
  const {state: stateAuth} = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState();
  const [messageModal, setMessageModal] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    if (!action) {
        return;
    }

    const {type} = action;

    switch (type) {
        case 'delete-document':
            setMessageModal(`Supprimer le document ?`);
            break;
        default:
            setMessageModal('');
    }
  }, [action])

  const handleMenuItem = (action) => {
    setAction(action);
    setAnchorEl(null);

    if (action.modal === "modal-confirmation") {
        setOpenModalConfirmation(true);
    }
    else if(action.modal) {
        setOpenModal(true);
    }
  }

  const handleConfirmationModal = async () => {
    const {type} = action;

    switch (type) {
        case 'delete-document':
          deleteDocument();
          break;
        default:
            return;
    }
  }

  const deleteDocument = async() => {
    setIsLoading(true);
    const dataDocument = await fetchApiImmodesk(`/private/documents/${document.id}`, {
      method: 'DELETE'
    }, stateAuth.token);

    setIsLoading(false);

    if (dataDocument.error) {
        // TODO error
        return;
    }

    setOpenModalConfirmation(false);

    if(typeof callback === "function") {
      callback({type: "delete", document});
    }
  }

  const handleCloseModalComponent = () => {
    const {type} = action;

    switch (type) {
        default:
            setOpenModal(false)
    }
  }

  const handleClickDownloadDocument = () => {
    window.open(document.url);
  }

  const handleCallbackUpdateDocument = () => {
    setOpenModal(false);
    if(typeof callback === 'function') {
      callback({type: "update", document: null});
    }
  }

  const sx = {
    menuItem: {
        fontSize: '0.8rem',
        color: "#000",
        "a": {
            textDecoration: "none",
            fontSize: '0.8rem',
            color: "#000",
        }
    }
  }

  return (
    <>
      <Button
          id="actions-documents-programme-button"
          aria-controls={openMenu ? 'actions-documents-documents-programme-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreHorizIcon sx={{transform: 'rotate(90deg)'}}/>
      </Button>

      <Menu
          id="actions-documents-client-demande-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
              'aria-labelledby': 'basic-button',
          }}
      >
          <MenuItem onClick={() => handleClickDownloadDocument()}
                    disabled={!document.url}
                    sx={sx.menuItem}>Télécharger</MenuItem>
          <MenuItem onClick={() => handleMenuItem({type: "update-document", modal: "modal-update-document"})}
                    sx={sx.menuItem}>Modifier</MenuItem>
          <MenuItem onClick={() => handleMenuItem({type: "delete-document", modal: "modal-confirmation"})}
                    sx={sx.menuItem}>Supprimer</MenuItem>
      </Menu>

      <ModalConfirmation openModal={openModalConfirmation}
                          handleConfirm={handleConfirmationModal}
                          handleClose={() => setOpenModalConfirmation(false)}
                          loading={isLoading}
                          message={messageModal}/>

      <ModalComponent openModal={openModal}
                      handleClose={handleCloseModalComponent}>
        {action && action.modal && action.modal === "modal-update-document" &&
          <DeposerDocumentProgramme document={document}
                                    programmeId={document.programme_id}
                                    callback={handleCallbackUpdateDocument}/>
        }
      </ModalComponent>
    </>
  )
};

export default ActionsDocumentsProgramme;

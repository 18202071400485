import React from "react";
import NotificationMenuItem from "./NotificationMenuItem.js";

const NotificationSignatureRefused = ({
  notification,
  handleChangeCallback,
}) => {
  const date = new Date(parseInt(notification.creationDate) * 1000);
  const title = `${notification.params.authorNom} ${notification.params.authorPrenom}`;

  return (
    <NotificationMenuItem
      notification={notification}
      date={date}
      title={title}
      message={notification.params.messageSujet}
      handleChangeCallback={handleChangeCallback}
    />
  );
};

export default NotificationSignatureRefused;

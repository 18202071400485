import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModalConfirmation from "../../Common/Modal/ModalConfirmation";
import { useAuth } from "../../../Contexts/useAuth";
import { fetchApiImmodesk } from "../../../Api/Api_immodesk";
import { NavLink } from "react-router-dom";

const ActionsLot = ({ lot }) => {
  const { state: stateAuth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState();
  const [messageModal, setMessageModal] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    if (!action) {
      return;
    }

    const { type } = action;

    switch (type) {
      case "envoyer-codes":
        setMessageModal(`Renvoyer le lien d'activation par mail au client.`);
        break;
      default:
        setMessageModal("");
    }
  }, [action, lot]);

  const handleMenuItem = action => {
    setAction(action);
    setAnchorEl(null);

    if (action.modal === "modal-confirmation") {
      setOpenModalConfirmation(true);
    }
  };

  const handleConfirmationModal = async () => {
    const { type } = action;

    switch (type) {
      case "envoyer-codes":
        demandActivation();
        break;
      default:
        return;
    }
  };

  const demandActivation = async () => {
    if (!lot.transaction || !lot.transaction.client_id) {
      return;
    }

    const promoteurId = stateAuth.profil.rattachment_id
      ? stateAuth.profil.rattachment_id
      : stateAuth.profil.employeur_id;

    setIsLoading(true);

    const dataDemandActivation = await fetchApiImmodesk(
      "/public/contacts/demand-activation",
      {
        method: "POST",
        body: JSON.stringify({
          contact_id: lot.transaction.client_id,
          promoteur_id: promoteurId,
          programme_id: lot.programme_id,
          lot_id: lot.id,
        }),
      }
    );

    setIsLoading(false);

    if (dataDemandActivation.error) {
      // TODO error
      return;
    }

    setOpenModalConfirmation(false);
  };

  const sx = {
    menuItem: {
      fontSize: "0.8rem",
      color: "#000",
      a: {
        textDecoration: "none",
        fontSize: "0.8rem",
        color: "#000",
      },
    },
  };

  return (
    <>
      <Button
        id="actions-lot-button"
        aria-controls={openMenu ? "actions-lot-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <MoreHorizIcon sx={{ transform: "rotate(90deg)" }} />
      </Button>
      <Menu
        id="actions-lot-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            handleMenuItem({ type: "fiche-client", modal: "modal-client" })
          }
          sx={sx.menuItem}
          disabled={!lot.transaction?.client_id}
        >
          <NavLink to={`/clients/contacts/${lot.transaction?.client_id}`}>
            Fiche détaillée du client
          </NavLink>
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleMenuItem({
              type: "envoyer-codes",
              modal: "modal-confirmation",
            })
          }
          sx={sx.menuItem}
          disabled={
            !lot.transaction ||
            !lot.transaction.client_statut_activation ||
            lot.transaction.client_statut_activation === "DESACTIVER"
          }
        >
          Envoyer les codes
        </MenuItem>
      </Menu>

      <ModalConfirmation
        openModal={openModalConfirmation}
        handleConfirm={handleConfirmationModal}
        handleClose={() => setOpenModalConfirmation(false)}
        loading={isLoading}
        message={messageModal}
      />
    </>
  );
};

export default ActionsLot;
